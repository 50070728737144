<template>
    <vg-modal
        :title="!isAnUpdateForm?$t('title-create'):$t('title-update')"
        @close="$emit('close')">
        <template #body>
            <form id="bc-form"
                ref="bc-form"
                onSubmit="return false;">
                <vg-input :title="$t('entite-name')"
                    v-model="entite.name"
                    isRequiredValue />
                <vg-input :title="$t('entite-immatriculation')"
                    v-model="entite.immatriculation"
                    isRequiredValue />
                <vg-input :title="$t('entite-address')"
                    v-model="entite.address"
                    isRequiredValue 
                    inputType="textarea"/>
                <vg-input :title="$t('entite-address-livraison')"
                    v-model="entite.addressLivraison" 
                    inputType="textarea"/>
                <vg-input :title="$t('text-complementaire')"
                    v-model="entite.textComplementaire" 
                    inputType="textarea"/>
                <input
                    v-show="false"
                    ref="bc-submit"
                    type="submit"
                >
            </form>
        </template>
        <template #footer>
            <vg-button @click="$emit('close')">
                {{$t("btn-cancel")}}
            </vg-button>
            <vg-button v-if="isAnUpdateForm" :type="'danger'" @click="showConfirmDeleteModal=true;">
                {{$t("btn-delete")}}
            </vg-button>
            <vg-button :type="isAnUpdateForm?'danger':'success'" @click="onSaveEntite" :disabled="isSaveDisabled">
                {{isAnUpdateForm?$t("btn-modifier"):$t("btn-creer")}}
            </vg-button>
            <dialog-secured-action v-if="showConfirmDeleteModal"
                :validResponse="entite.name"
                @save="onDeleteEntite"
                @close="showConfirmDeleteModal=false">
                <template #header-title>
                    <h3>{{$t("confirm-delete-entité",{name:entite.name})}}</h3>
                </template>
            </dialog-secured-action>
        </template>
    </vg-modal>
</template>
<script>

import VgModal from "src/components/Vg/VgModal.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import DialogSecuredAction from "src/components/Vg/DialogSecuredAction.vue";

import BonsDeCommandeEntitesMixins from "src/mixins/BonsDeCommandeEntitesMixins.js";

import { mapGetters } from "vuex";

export default {
    name: "vg-bon-de-commande-form",
    components:{
        VgModal,
        VgButton,
        VgInput,
        DialogSecuredAction
    },
    mixins: [ BonsDeCommandeEntitesMixins ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title-create": "Créer bon de commande entité",
            "title-update": "Modifier bon de commande entité",
            "entite-name": "Nom de l'entité",
            "entite-address": "Adresse",
            "entite-address-livraison": "Adresse de livraison",
            "entite-immatriculation": "Immatriculation",
            "text-complementaire": "Text complémentaire",
            "btn-cancel": "Annuler",
            "btn-creer": "Créer",
            "btn-modifier": "Modifier",
            "btn-delete": "Supprimer",
            "confirm-delete-entité": "Voulez-vous supprimer l'entité {name} ?",
            "section-demandeur": "DEMANDEUR",
            "section-fournisseur": "FOURNISSEUR",
            "bc-entite": "Entité qui passe commande (par défaut le compte)",
            "bc-numero": "N°",
            "bc-service-demandeur": "Service",
            "bc-date-creation": "Date création",
            "bc-site-demandeur": "Site",
            "bc-fournisseur": "Fournisseur",
            "bc-commentaire": "Commentaire",
            "bc-total-ht": "Total HT",
            "bc-total-tva": "Total TVA",
            "bc-total-ttc": "Total TTC",
            "btn-saisir-un-site": "Saisir un autre site",
            "btn-annuler-saisir-un-site": "Afficher la liste des sites",
            "input-placeholder-demandeur": "Saisir un site"
        },
        "en": {
            "title-create": "Create entity purchase order",
            "title-update": "Modify entity purchase order",
            "entite-name": "Entity Name",
            "entite-address": "Address",
            "entite-address-livraison": "Delivery Address",
            "entite-immatriculation": "Registration",
            "text-complementaire": "Additionnal text",
            "btn-cancel": "Cancel",
            "btn-creer": "Create",
            "btn-modifier": "Modify",
            "btn-delete": "Delete",
            "confirm-delete-entité": "Do you want to delete entity {name}?",
            "section-demandeur": "REQUESTER",
            "section-fournisseur": "SUPPLIER",
            "bc-entite": "Entity Placing the Order (default account)",
            "bc-numero": "No.",
            "bc-service-demandeur": "Department",
            "bc-date-creation": "Creation Date",
            "bc-site-demandeur": "Site",
            "bc-fournisseur": "Supplier",
            "bc-commentaire": "Comment",
            "bc-total-ht": "Total (Excl. Tax)",
            "bc-total-tva": "Total VAT",
            "bc-total-ttc": "Total (Incl. Tax)",
            "btn-saisir-un-site": "Enter Another Site",
            "btn-annuler-saisir-un-site": "Show List of Sites",
            "input-placeholder-demandeur": "Enter a site"
        }
    }
},
    props: {
        value:{
            type: Object,
            default: function(){
                return null;
            }
        }
    },
    data: function() {
        return {
            entite: {
                name: null,
                immatriculation: null,
                address: null,
                addressLivraison: null,
                textComplementaire: null,
                userId: this.$app.appID
            },
            showConfirmDeleteModal: false
        };
    },
    computed:{
        ...mapGetters({
            bonsDeCommandeEntites: 'BonsDeCommandeEntitesStore/getCollection'
        }),
        isAnUpdateForm: function(){
            return this.value && this.value.id;
        },
        isSaveDisabled: function(){
            return !this.entite.name || !this.entite.address || !this.entite.immatriculation;
        }
    },
    created: function() {
        if(this.value && this.value.id) this.entite = Object.assign({}, this.entite, this.value);
    },
    methods: {
        onSaveEntite: function(){
            if(!this.isAnUpdateForm){
                this.BonsDeCommandeEntitesMixins_create([this.entite]).then((datas)=>{
                    console.log("AFTER SAVE BCE", datas);
                    this.$emit("save", datas[0]);
                });
            }else{
                this.BonsDeCommandeEntitesMixins_update(this.entite).then((data)=>{
                    console.log("AFTER UPDATE BCE", data);
                    this.$emit("save", data);
                });
            }
        },
        onDeleteEntite: function(){
            this.BonsDeCommandeEntitesMixins_delete(this.entite).then((datas)=>{
                this.$emit("save", datas);
            });
        },
    }
};
</script>
<style lang="scss" scoped>


</style>
