<template>
	<div class="vg-equipement-historique">
		<div class="historique-header" v-if="!$vgutils.isMobile()">
			<vg-tabs
				v-model="currentTabName"
				:tabs="tabs"
				:color="'gris'">
			</vg-tabs>
			<div class="more">
				<vg-text-filter v-model="searchQuery">
				</vg-text-filter>
				<action-menu-aggrid
					:idTableau="getIdCurrentTableau"
					disabledReport
					placement="bottom-right"/>
			</div>
		</div>
		<div class="historique-header" v-else>
			<span class="title">{{$t(currentTabName+"-title")}}</span>
		</div>
		<div class="historique-body">
            <vg-fichesav-table v-if="currentTabName=='maintenances'"
                :idTableau="'vg-maintenances-zoom-equipement'"
				:filters="currentTab.filters"
				counterName="filtered"
				:searchQuery="searchQuery"
                :hiddenColumns="['checkbox','urgence','path','affectation','origin','dateFermetureSAV','dateAffectation','libelEquipement','isRetourFait','photo1','photo2','photo3','libelleCatgorie','workingTime']"
                showColumnsFinances>
			</vg-fichesav-table>

			<vg-verifications-viewer v-if="currentTabName=='verifications'"
				:filters="currentTab.filters"
				:searchQuery="searchQuery" 
                @row-dbl-click="openVerificationDetails"/>

			<vg-interventions-table v-if="currentTabName=='interventions-periodiques'"
				:key="'vg-interventions-periodiques-table'"
				:agFilters="currentTab.filters"
				:searchQuery="searchQuery"
				:equipementId="value.id"
				:equipement="value"
				isPeriodiques
				:idTableau="'vg-interventions-periodiques-table'"
				@row-dbl-click="onOpenInterventionsPeriodique" />

			<vg-verification-modal-viewer v-if="showModalVerification"
				:verification="verification"
				@close="showModalVerification=false;verification=null;" />

		</div>
	</div>
</template>
<script>
    import VgTabs from 'src/components/Vg/VgTabs.vue';
    import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
    import ActionMenuAggrid from 'src/components/Vg/TagGrid/ActionMenuAggrid.vue';

	import VgVerificationsViewer from 'src/components/Vg/Verification/VgVerificationsViewer.vue';
	import VgFichesavTable from 'src/components/Vg/FicheSAV/VgFichesavTable.vue';
	import VgInterventionsTable from 'src/components/Vg/Interventions/VgInterventionsTable.vue';
	import VgVerificationModalViewer from 'src/components/Vg/Verification/VgVerificationModalViewer.vue';

    export default {
        name: 'vg-equipement-historique',
		components: {
			VgTabs,
			VgTextFilter,
			ActionMenuAggrid,
			VgVerificationsViewer,
			VgFichesavTable,
			VgInterventionsTable,
			VgVerificationModalViewer
		},
		mixins: [],
        props: {
			/**
			* @model objet equipement
			*/
            value: {
				type: Object
			},
			/**
			* current tab name
			*/
			activate: {
				type: String,
				default: "maintenances"
			},
			isEquipement: {
				type: Boolean,
				default: true
			}
        },
        data: function() {
            return {
                searchQuery: "",
				currentTabName: this.activate,
				showModalVerification: false,
				verification: null
            };
        },
	    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "tab-verifications": "Tâches récurrentes",
            "verifications-title": "Historique Préventif interne",
            "tab-maintenances": "Fiches curatives",
            "interventions-periodiques-title": "Historique interventions périodiques",
            "interventions-ponctuelles-title": "Historique interventions ponctuelles",
            "tab-interventions-ponctuelles": "Interventions ponctuelles",
            "tab-interventions-periodiques": "Interventions préventives tiers",
            "tab-relever-capteurs": "Relevés capteurs"
        },
        "en": {
            "tab-verifications": "Recurrent tasks",
            "verifications-title": "Internal preventive's history",
            "tab-maintenances": "Curative sheets",
            "tab-interventions-ponctuelles": "one off intervention",
            "tab-interventions-periodiques": "Third-party preventive interventions",
            "tab-relever-capteurs": "Sensor readings",
            "interventions-periodiques-title": "Periodic intervention history",
            "interventions-ponctuelles-title": "Occasional intervention history"
        }
    }
},
        watch: {
			activate: {
				handler: function(value){
					this.currentTabName = value;
				}
			}
		},
		computed: {
			/**
			 * @return object
			 */
			currentTab: function(){
				return this.tabs.find((tab)=>tab.name==this.currentTabName);
			},
			categorieId: function(){
				if(this.value && this.value.categorie_id) return this.value.categorie_id;
				else if(this.value && this.value.categorie) return this.value.categorie.id;
				else return null;
			},
			/**
			 * @return array tabs
			 */
			tabs: function(){
				if(this.isEquipement){
					return [
                        { label: this.$t("tab-maintenances"), name: "maintenances",
							img: "/static/assets/icone/fiche-de-maintenance.png",
							filters: [{attr:"materiel_id",value:this.value.id,action:"equals"}]
						},
						{ label: this.$t("tab-verifications"), name: "verifications",
							img: "/static/assets/icone/verification.png",
							filters: [{attr:"equipement_id",value:this.value.id,action:"equals"}]
						},
						{ label: this.$t("tab-interventions-periodiques"), name: "interventions-periodiques",
							img: "/static/assets/icone/intervention.png",
							filters: {
								equipement_id: {attr: "ie.equipement_id", value: this.value.id, action: "equals", openParenthesis: true},
								categorie_id: {attr: "cc.categorie_id", value: this.categorieId, action: "equals", closeParenthesis: true, logicalOperator: "OR"}
							}
						}
					];
				}else{
					return [
                        { label: this.$t("tab-maintenances"), name: "maintenances",
							img: "/static/assets/icone/fiche-de-maintenance.png",
							filters: [{attr:"materiel_id",value:this.value.id,action:"equals"}]
						},
						{ label: this.$t("tab-verifications"), name: "verifications",
							img: "/static/assets/icone/verification.png",
							filters: [{attr:"equipement_id",value:this.value.id,action:"equals"}]
						},
						{ label: this.$t("tab-interventions-periodiques"), name: "interventions-periodiques",
							img: "/static/assets/icone/intervention.png",
							filters: {
								categorie_id: {attr: "cc.categorie_id", value: this.categorieId, action: "equals"}
							}
						}
					];
				}	
			},
			/**
			 * @return string
			 */
			getIdCurrentTableau: function(){
				if(this.currentTabName=="verifications") return "vg-verifications-table";
				else if(this.currentTabName=="maintenances") return "vg-maintenances-zoom-equipement";
				else if(this.currentTabName=="interventions-ponctuelles") return "vg-interventions-periodiques-table";
				else if(this.currentTabName=="interventions-periodiques") return "vg-interventions-periodiques-table";
				else if(this.currentTabName=="relever-capteurs") return "vg-relever-capteurs";
			}
		},
		methods: {
			openVerificationDetails: function(verification){
				this.showModalVerification = true;
				this.verification = verification;
			},
			onOpenInterventionsPeriodique: function(intervention){
				this.$router.push({name: "_intervention", params: { id: intervention.id }});
			},
		}
    };

</script>
<style lang="scss" scoped>
.vg-equipement-historique{
	.historique-header{
		display: flex;
		justify-content: space-between;
		align-items: center;
		.vg-tabs{

		}
		.more{
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
		.title{
			width:100%;
			text-align:center;
			margin: 10px 0px;
		}
	}
	.historique-body{
		height:300px;
	}
}
</style>
