<template lang="html">
	<div class="vg-filter-service" v-if="hasManyServices">
		<vg-input
			:title="title?title:$t('title')">
			<vg-service-selector v-model="selectedService"
				restrictionSite
				:showAll="showAll"
				:clearable="clearable"
				@input="handleChange"
				@services-feeded="hasManyServices=!$storage.get('has-only-one-service')" />
		</vg-input>
	</div>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgServiceSelector from "src/components/Vg/Selectors/VgServiceSelector.vue";

    export default {
        name: 'vg-filter-service',
	    components: {
			VgInput,
			VgServiceSelector
        },
		mixins:[

        ],
        props: {
			/**
			 * @model
			 */
			value: {
				type: String
			},
			title: {
				type: String,
				default: null
			},
			showAll:{
				type: Boolean,
				default: false
			},
			clearable:{
				type: Boolean,
				default: true
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Filtrage par service"
        },
        "en": {
            "title": "Filtering by unit"
        }
    }
},
        data: function(){
            return {
				selectedService: this.value,
				hasManyServices: false
			};
        },
		watch: {
			value: {
				handler: function(val){
					this.selectedService = val;
				}
			}
		},
		created: function(){
			this.getHasManyServicesValue();
		},
        mounted: function(){

        },
        methods: {
			getHasManyServicesValue: function(){
				this.hasManyServices = !this.$storage.get("has-only-one-service");
			},
			/**
	         * emit les sites selectionnés
	         * @event input selectedSites
	         */
			handleChange: function(service){
				//console.log("HANDLE CHANGE", this.getSelectedSites);
				this.$emit("input", service);
			}
		},
		computed: {

		}
	};
</script>

<style lang="scss" scoped>

</style>
<docs>
    vg-filter-service
</docs>
