<template>
    <div class="vg-consommables-table">
        <tag-grid v-if="isTableau"
            v-model="gridOptions"
            :idTableau="id"
            :columnDefs="columnDefs"
            :rowData="consommables"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
            @ag-multiple-selection-row-changed="handleMultipleSelectionRowChanged"
            @ag-click="handleClick"
            @ag-dbl-click="handleDblClick"
        />
        <div v-else class="vg-consommables-liste">
            <consommable-details-cell-render v-for="consommable in consommables"
                :key="consommable.id"
                class="consommable"
                :params="{data:consommable}"
                @click="handleClick"/>
            <span v-if="!consommables || consommables.length==0" class="empty-composants-message">{{$t("aucun-consommables")}}</span>
        </div>
        <vg-pagination v-if="paginate"
            :totalItems="getTotalItems"
            :offset="offset"
            :limit="limit"
            @pagination-change="handlePaginationChange"
        />
    </div>
</template>
<script>
import ConsommablesMixins from "src/mixins/ConsommablesMixins";
import TagGrid from 'src/components/Grid/TagGrid.vue';

import VgPagination from "src/components/Vg/VgPagination.vue";

import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
import VgCostCellRender from "src/components/Vg/TagGrid/VgCostCellRender.vue";
import OuiNonCellRender from "src/components/Vg/TagGrid/OuiNonCellRender.vue";
import TVACellRender from "src/components/Vg/TagGrid/TVACellRender.vue";
import ConsommableDetailsCellRender from "src/components/Vg/TagGrid/ConsommableDetailsCellRender.vue";
import ConsommableQuantiteCellRender from "src/components/Vg/TagGrid/ConsommableQuantiteCellRender.vue";

import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-consommables-table',
    components:{
        TagGrid,
        VgPagination,
        ConsommableDetailsCellRender,
        ConsommableQuantiteCellRender
    },
    mixins:[ ConsommablesMixins ],
    props: {
        id:{
            type: String,
            default: "consommables-tableau"
        },
        /**
        * liste des columns à hide (column colId)
        */
        hiddenColumns: {
            type: Array,
            default: function(){
                return ["stock.quantite_non_disponible", "stock.quantite_demandee", "stock.quantite_livree"];
            }
        },
        /**
        * liste des columns pined left (column colId)
        */
        pinnedColumns: {
            type: Array,
            default: function(){
                return ["name"];
            }
        },
        /**
        * liste des columns non showable (column colId)
        */
        notShowableColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * affiche le tableau en mode impression
        * default false
        */
        showPrint : {
            type: Boolean,
            default: false
        },
        /**
        * recherche
        */
        searchQuery: {
            type: String,
            default: ""
        },
        /**
        * filtres externes appliqué sur le tableau au fetch
        */
        filters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * filtres externes appliqué sur le tableau en local
        */
        localFilters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * titre rapport impression
        */
        reportTitle: {
            type: String,
            default: null
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: false
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        },
        enStock: {
            type: Boolean,
            default: false
        },
        aCommander: {
            type: Boolean,
            default: false
        },
        nonDisponibles:{
            type: Boolean,
            default: false
        },
        enDemande:{
            type: Boolean,
            default: false
        },
        /**
        * affiche la lsite des consommables sous forme de tableau
        */
        isTableau: {
            type: Boolean,
            default: true
        },
        depotId: {
            type: String | Number,
            default: null
        },
        /**
        * au row-click mobile redirige vers le mobile
        */
        redirectToZoom: {
            type: Boolean,
            default: true
        },
        clearCollectionAfterDestroyed:{
            type: Boolean,
            default: false
        }
    },
    i18n:    { "locale":navigator.language,
        "messages": {
            "fr": {
                "overlay-loading-text": "Chargement des consommables...",
                "overlay-no-rows-text": "Aucun consommable correspondant au filtrage",
                "header-intitule": "Intitulé",
                "header-nature": "Etiquette consommable/pièce",
                "header-coutUnitaire": "Coût unitaire par défaut",
                "header-fournisseur": "Fournisseur",
                "header-refExterne": "Réf. constructeur",
                "header-marque": "Marque",
                "header-nEqsAssocies": "Nb. équipements associés",
                "header-stock": "Qté disponible en stock",
                "header-stockMin": "Qté minimale",
                "header-quantite-non-disponible": "Qté non disponible",
                "header-quantite-en-demande": "Qté en demande",
                "header-quantite-livree": "Qté déjà livrée",
                "header-dateDernierInventaire": "Date dernier inventaire",
                "header-services": "Services restreints",
                "header-code": "Code-barre / QRcode",
                "header-accept": "Accepté",
                "header-productGroup": "Groupe",
                "header-productSubGroup": "Sous-groupe",
                "header-category": "Catégorie",
                "header-saleRate": "Sale rate",
                "header-saleCost": "Sale cost",
                "header-saleDisc": "Sale disc",
                "header-saleAccount": "Sale account",
                "header-expDtAppl": "Exp_Dt_Appl",
                "header-suppRateMand": "Supp_Rate_Mand",
                "header-tva": "TVA",
                "header-commentaire": "Commentaire",
                "header-statut": "Statut",
                "header-unite": "Unité",
                "header-colisage": "Colisage",
                "aucun-consommables": "Aucuns consommables",
                "de": "de"
            },
            "en": {
                "overlay-loading-text": "Loading consumables...",
                "overlay-no-rows-text": "No consumable matching filtering",
                "header-intitule": "Name",
                "header-nature": "Consumable/part label",
                "header-coutUnitaire": "Default unit cost",
                "header-fournisseur": "Supplier",
                "header-refExterne": "Builder's ref.",
                "header-marque": "Brand",
                "header-nEqsAssocies": "No. Associated equipment",
                "header-stock": "Available qty in stock",
                "header-stockMin": "Minimum quantity",
                "header-quantite-non-disponible": "Qty not available",
                "header-quantite-en-demande": "Qty requested",
                "header-quantite-livree": "Qty already delivered",
                "header-dateDernierInventaire": "Last Inventory Date",
                "header-code": "Barcode / QRcode",
                "header-accept": "Accept",
                "header-productGroup": "Product group",
                "header-productSubGroup": "Product subgroup",
                "header-category": "Product category",
                "header-nature": "Product nature",
                "header-saleRate": "Sale rate",
                "header-saleCost": "Sale cost",
                "header-saleDisc": "Sale disc",
                "header-saleAccount": "Sale account",
                "header-expDtAppl": "Exp_Dt_Appl",
                "header-suppRateMand": "Supp_Rate_Mand",
                "header-tva": "VAT",
                "header-commentaire": "Comment",
                "header-statut": "Status",
                "header-unite": "Unit",
                "de": "of"
                }
            }
        },
    data: function() {
        return {
            showLoadingOverlay: false,
            gridOptions: {
                headerHeight: this.$vgutils.isMobile()?0:40,
                rowHeight: this.$vgutils.isMobile()?100:40
            },
            columnDefs: [
                {
                    headerName: '',
                    colId: 'checkbox',
                    width: 50,
                    maxWidth: 50,
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    suppressSorting: true,
                    cellClass: ["vg-cell-checkbox"],
                    pinned: 'left'
                },
                {
					headerName: this.$t("header-intitule"),
					field: 'name',
					colId: 'name',
                    cellRenderer: (params) => new IconNameCellRender({propsData: {dataColumn: params.data.name, isMainColumn: true}}).$mount().$el
				},
				{
                    headerName: this.$t("header-nature"),
                    field: 'nature',
                    colId: 'nature',
				},
                {
				  headerName: this.$t("header-stock"),
				  field: 'stock.quantite',
				  colId: 'stock.quantite',
				  width: 100,
                  cellRenderer: (params)=>new ConsommableQuantiteCellRender({propsData: {params: params, attribut: "stock.quantite", info: this.showTous || this.showDisponible || this.showMinimumAtteint, warning: this.showNonDisponible}}).$mount().$el
				},
				{
                    headerName: this.$t("header-stockMin"),
                    field: 'stock.quantiteMin',
                    colId: 'stock.quantiteMin',
                    width: 100,
                    hide: this.columnQuantiteIsHidden("stock.quantiteMin"),
                    cellRenderer: (params)=>new ConsommableQuantiteCellRender({propsData: {params: params, attribut: "stock.quantiteMin", info: false, warning: false}}).$mount().$el
				},
                {
				  headerName: this.$t("header-quantite-non-disponible"),
				  colId: 'stock.quantite_non_disponible',
				  width: 100,
                  hide: this.columnQuantiteIsHidden("stock.quantite_non_disponible"),
                  cellRenderer: (params)=>new ConsommableQuantiteCellRender({propsData: {params: params, attribut: "quantite_non_disponible", info: false, warning: true}}).$mount().$el
				},
                {
				  headerName: this.$t("header-quantite-en-demande"),
				  field: 'stock.quantite_demandee',
				  colId: 'stock.quantite_demandee',
				  width: 100,
                  hide: this.columnQuantiteIsHidden("stock.quantite_demandee"),
                  cellRenderer: (params)=>new ConsommableQuantiteCellRender({propsData: {params: params, attribut: "quantite_demandee", info: this.showEnDemande, warning: false}}).$mount().$el
				},
                {
				  headerName: this.$t("header-quantite-livree"),
				  field: 'stock.quantite_livree',
				  colId: 'stock.quantite_livree',
				  width: 100,
                  hide: this.columnQuantiteIsHidden("stock.quantite_livree"),
                  cellRenderer: (params)=>new ConsommableQuantiteCellRender({propsData: {params: params, attribut: "quantite_livree", info: false, warning: false}}).$mount().$el
				},
				{
                    headerName: this.$t("header-fournisseur"),
                    field: 'fournisseurDefault_id',
                    colId: 'fournisseurDefault_id',
                    cellRenderer: (params) => new IconNameCellRender({propsData: {icon: "static/assets/icone/circle/tiers-cercle.png", dataColumn: params.data.fournisseurDefault_id?params.data.fournisseurDefault.name:null, isMainColumn: false, route: { router: this.$router, name: '_tiers_id', params: {id: params.data.fournisseurDefault_id }}}}).$mount().$el,
                },
                {
                    headerName: this.$t("header-refExterne"),
                    field: 'refExterne',
                    colId: 'refExterne',
                    width: 132
                },
                {
				  headerName: this.$t("header-marque"),
				  field: 'marque',
				  colId: 'marque',
				  width: 132
				},
                {
                    headerName: this.$t("header-statut"),
                    field: 'statut',
	                colId: 'statut',
                    width: 132
				},
				{
                    headerName: this.$t("header-commentaire"),
                    field: 'commentaire',
	                colId: 'commentaire',
	                width: 132
				},
                {
                    headerName: this.$t("header-unite"),
                    field: 'unite',
                    colId: 'unite',
	                width: 132
				},
				{
                    headerName: this.$t("header-code"),
                    field: 'code',
                    colId: 'code',
                    width: 132
				},
                {
                    headerName: this.$t("header-tva"),
                    field: 'tva.name',
	                colId: 'tva.name',
                    cellRenderer: (params) => new TVACellRender({propsData: {tva: params.data.tva}}).$mount().$el,
                    width: 132
				},
                {
                    headerName: this.$t("header-colisage"),
                    field: 'quantiteColisage',
	                colId: 'quantiteColisage',
                    cellRenderer: (params) => params.data.conditionnementColisage && params.data.quantiteColisage? params.data.conditionnementColisage+" "+this.$t("de")+" "+params.data.quantiteColisage:null,
                    width: 132
				},
				{
                    headerName: this.$t("header-coutUnitaire"),
                    field: 'coutUnitaire',
                    colId: 'coutUnitaire',
    				hide:true
                },
				{
                    headerName: this.$t("header-services"),
                    field: 'services',
                    colId: 'services',
    				hide:true
				}
            ],
            metadatas: new Metadatas()
        };
    },
    watch: {
        id: {
            handler: function(newId){
                this.$forceUpdate();
            }
        },
        searchQuery: {
            handler: function(query){
                this.fetch();
            }
        },
        filters: {
            handler: function(newfilters){
                console.log("WATCH FILTERS", newfilters);
                this.fetch();
            },
            deep: true
        },
        localFilters: {
            handler: function(newfilters){
                // //console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
                //if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
            },
            deep: true
        },
        enStock: {
            handler: function(newvalue){
                this.fetch();
            }
        },
        aCommander: {
            handler: function(newvalue){
                this.fetch();
            }
        },
        nonDisponibles: {
            handler: function(newvalue){
                this.fetch();
            }
        },
        enDemande: {
            handler: function(newvalue){
                this.fetch();
            }
        },
        depotId: {
            handler: function(id){
                this.fetch();
            }
        },
        hiddenColumns: {
            handler: function(cols){
                this.$forceUpdate();
            }
        }
    },
    created:function(){
        if(this.$vgutils.isMobile()){
            this.columnDefs.map((columnDef)=>{
                columnDef.hide = true;
            });
            this.columnDefs.unshift({
                headerName: "",
                field:"",
                cellClass: ["vg-cell-mobile-card"],
                cellRenderer: (params) => new ConsommableDetailsCellRender({propsData: {params: params}}).$mount().$el
            });
        }
        if(this.paginate) this.metadatas.setLimit(this.offset, this.limit);
        this.fetch();
    },
    computed: {
        ...mapGetters({
            consommables: 'ConsommablesStore/getCollection',
            counters: 'ConsommablesStore/getCounters'
        }),
        getTotalItems: function(){
            if(this.enStock) return this.counters.enstock;
            else if(this.aCommander) return this.counters.acommander;
            else if(this.nonDisponibles) return this.counters.nondisponibles;
            else if(this.enDemande) return this.counters.endemande;
            else return this.counters.filtered;
        },
        showTous: function(){
            return !this.enStock && !this.aCommander && !this.nonDisponibles && !this.enDemande;
        },
        showDisponible: function(){
            return this.enStock;
        },
        showNonDisponible: function(){
            return this.nonDisponibles;
        },
        showMinimumAtteint: function(){
            return this.aCommander;
        },
        showEnDemande: function(){
            return this.enDemande;
        }
    },
    methods:{
        columnQuantiteIsHidden: function(colId){
            return this.columnIsHidden(colId);
        },
        /**
        * column is hidden
        * @param String colId
        * @return Boolean
        */
        columnIsHidden: function(colId){
            return this.hiddenColumns.indexOf(colId)!=-1;
        },
        fetch: function(){
            this.showLoadingOverlay = true;
            this.metadatas.setFilters(Object.assign({}, this.filters, {
                depot_id: {attr: "s.depot_id", value: this.depotId, action: "equals"},
                search_name: {attr: "c.name", value: this.searchQuery, action: "contains", openParenthesis: true},
                search_refExterne: {attr: "c.refExterne", value: this.searchQuery, action: "contains", logicalOperator: "OR"},
                search_numSerie: {attr: "c.numSerie", value: this.searchQuery, action: "contains", logicalOperator: "OR"},
                search_nature: {attr: "c.nature", value: this.searchQuery, action: "contains", logicalOperator: "OR", closeParenthesis: true}
            }));
            if(this.enStock){
                this.ConsommablesMixins_getConsommablesEnStock(this.metadatas).then((datas)=>{
                    console.log("GET CONSOMMABLES EN STOCK", datas);
                    this.showLoadingOverlay = false;
                });
            }else if(this.aCommander){
                this.ConsommablesMixins_getConsommablesACommander(this.metadatas).then((datas)=>{
                    console.log("GET CONSOMMABLES A COMMANDER", datas);
                    this.showLoadingOverlay = false;
                });
            }else if(this.nonDisponibles){
                this.ConsommablesMixins_getConsommablesNonDisponibles(this.metadatas).then((datas)=>{
                    console.log("GET CONSOMMABLES NON DISPONIBLES", datas);
                    this.showLoadingOverlay = false;
                });
            }else if(this.enDemande){
                this.ConsommablesMixins_getConsommablesEnDemande(this.metadatas).then((datas)=>{
                    console.log("GET CONSOMMABLES EN DEMANDE", datas);
                    this.showLoadingOverlay = false;
                });
            }else{
                this.ConsommablesMixins_getConsommables(this.metadatas).then((datas)=>{
                    console.log("GET CONSOMMABLES", datas);
                    this.showLoadingOverlay = false;
                });
            }
        },
        /**
        * Emit row-dbl-click on dblClick row.
        *
        * @param {Object} row
        * @event row-dbl-click
        */
        handleDblClick:function(row){
            this.$emit("row-dbl-click",row);
        },
        goToZoom: function(data){
            this.$router.push({ name: '_consommable_id', params: { id: data.id } });
        },
        /**
        * Emit.
        *
        * @param Object row
        * @event row-click
        */
        handleClick:function(row){
            this.$store.dispatch("ConsommablesStore/setSelectedItem", row.data);
            this.$emit("row-click",row);
            if(this.$vgutils.isMobile() && this.redirectToZoom) this.goToZoom(row.data);
        },
        handlePaginationChange: function(pagination){
            console.log("handlePaginationChange", pagination);
            this.metadatas.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
            this.fetch();
        },
        /**
         * handleMultipleSelectionRowChanged.
         *
         * @param  {type} gridOptions aggrid gridoptions
         * @return {type}             description
         */
        handleMultipleSelectionRowChanged: function(gridOptions){
            var selectedNodes = gridOptions.api.getSelectedNodes();
            let consommables = selectedNodes.map((node)=>{
                return node.data;
            });
            this.$store.dispatch("ConsommablesStore/setSelectedItems", consommables);
        },
    },
    destroyed: function(){
        if(this.clearCollectionAfterDestroyed) this.$store.dispatch("ConsommablesStore/clearCollection");
    }
};
</script>
<style lang="scss" scoped>
.vg-consommables-table{
    height: 90%;
}
.vg-consommables-liste{
    height: 300px;
    overflow: auto;
    .empty-consommables-message{
        color: rgb(57, 153, 255);
    }
    .consommable{
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom:1px solid #eee;
        cursor: pointer;
    }
    .consommable:hover{
        background-color: whitesmoke;
    }
}
</style>
