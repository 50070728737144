<template>
    <vg-app-layout
        :title="$t('page-title')"
        :icon="getHeaderIcon"
        :colorheader="getHeaderBackgroundColor"
        class="hidden-print"
        :activeFilters="activeFilters"
        :filtersTitle="$t('modal-filters-title')"
        @remove-filters="onRemoveFilters"
        @action-export="actionExport">
        <template #search>
            <vg-text-filter
                v-model="searchQuery" />
        </template>
        <template #header-bottom-left>
            <vg-tabs
                :color="'gris'"
                :tabs="tabs"
                v-model="focusedTab" />
        </template>
        <template #action-button-item>
            <li @click="openVerificationPrint">{{$t("menu-more-rapport")}}</li>
        </template>
        <template #primary>
            <vg-verifications-viewer v-show="focusedTab=='tous'"
                :idTableau="'tableau-historique-verifications'"
                :filters="agfilters"
                paginate
                @row-dbl-click="openVerificationDetails"
                @row-click="handleClickVerification"
                @fetch-success="onFetchSuccess" />
            <vg-points-non-conformes-table v-show="focusedTab=='nonconformites'"
                :idTableau="'tableau-historique-verifications-reponses-nonconformes'"
                :filters="agfilters"
                paginate 
                @fetch-success="onFetchSuccessReponses"/>
        </template>

        <template #secondary-filters>
            <vg-filter-date-intervalle :title="$t('filter-by-date')"
                :defaultStartDate="agfilters.dateVerif.value[0]"
                :defaultEndDate="agfilters.dateVerif.value[1]"
                @change="handleChangeDateIntervalle"/>
            <vg-filter-tiers v-if="$app.account_type=='prestataire'"
                :attributeValue="null"
                :filters="tiersFilters"
                @input="onInputTiersFilter"/>
            <vg-filter-site
                v-model="agfilters.field_path.value" />
            <vg-collapse :title="$t('collapse-filter')"
                :killContentOnCollapse="false">
                <template #content>
                    <vg-filter-tache
                        @input="handleFilteringTache"
                        verbose/>
                    <vg-filter-tache-type
                        v-model="agfilters.field_type_tache.value" />
                </template>
            </vg-collapse>
            <br>
        </template>
        <template #secondary-widget>
            <vg-tree-lieux
                v-model="agfilters.field_path.value" />
        </template>
        <template #panel-filters-localisation>
            <vg-filter-site
                v-model="agfilters.field_path.value" />
        </template>
        <template #panel-widget-lieux>
            <vg-tree-lieux
                v-model="agfilters.field_path.value" />
        </template>
        <vg-verification-modal-viewer
            v-if="showModalVerification"
            :verification="verification"
            @close="showModalVerification=false;" />
    </vg-app-layout>
</template>

<script>
import TagGrid from 'src/components/Grid/TagGrid.vue';

import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import TagGridMixins from 'src/mixins/TagGridMixins.js';
import LieuMixins from 'src/mixins/LieuMixins.js';
import VgSwitch from 'src/components/Vg/VgSwitch.vue';
import VgSelect from 'src/components/Vg/VgSelect.vue';
import VgInput from 'src/components/Vg/VgInput.vue';
import VgChips from 'src/components/Vg/VgChips.vue';
import VgButton from 'src/components/Vg/VgButton.vue';
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgDatePicker from 'src/components/Vg/VgDatePicker.vue';
import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
import VgFilterTache from "src/components/Vg/Filters/VgFilterTache.vue";
import VgFilterDateIntervalle from "src/components/Vg/Filters/VgFilterDateIntervalle.vue";
import VgFilterTacheType from "src/components/Vg/Filters/VgFilterTacheType.vue";
import VgFilterTiers from "src/components/Vg/Filters/VgFilterTiers.vue";
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgGroup from "src/components/Vg/VgGroup.vue";

import VgVerificationsViewer from 'src/components/Vg/Verification/VgVerificationsViewer.vue';
import VgPointsNonConformesTable from 'src/components/Vg/Verification/VgPointsNonConformesTable.vue';
import VgVerificationModalViewer from "src/components/Vg/Verification/VgVerificationModalViewer.vue";

import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
import FiltersMixins from 'src/mixins/FiltersMixins';
import VerificationMixins from 'src/mixins/VerificationMixins';
import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from 'vuex';

const ICON_DESKTOP = "verification.png";
const ICON_MOBILE = "mobile/verification.png";
const HEADER_BACKGROUND_COLOR_MOBILE = "rgb(66,159,255)";

export default {
    name: "vg-verifications",
    components: {
        TagGrid,
        VgTextFilter,
        VgSwitch,
        VgSelect,
        VgInput,
        VgTabs,
        VgChips,
        VgButton,
        VgDatePicker,
        VgFilterSite,
        VgFilterTache,
        VgFilterTiers,
        VgFilterDateIntervalle,
        VgFilterTacheType,
        VgVerificationModalViewer,
        VgGroup,
        VgCollapse,
        VgVerificationsViewer,
        VgPointsNonConformesTable,
        VgTreeLieux,
        VgFilterDateIntervalle
    },
    mixins: [
        TagGridMixins,
        LieuMixins,
        FiltersMixins,
        VerificationMixins
    ],
    i18n:    { "locale":navigator.language,
    "messages": {
    "fr": {
        "page-title": "Historique tâches récurrentes",
        "tab-synthese-verifications": "Vérifications effectuées",
        "tab-non-conformites": "Points non conformes",
        "menu-more-gerer-columns": "Gérer les colonnes",
        "menu-more-export-xls": "Export (.xls)",
        "menu-more-rapport": "Creer un rapport",
        "action-button": "Action",
        "export-file-name-verifications": "Verifgood_verifications",
        "export-file-name-reponses-nonconformes": "Verifgood_reponses_non_conformes",
        "filter-by-date": "Filtrage par date vérification",
        "column-header-statutFM": "Statut fiche curative",
        "secondary-filtre-bloc": "Filtre",
        "secondary-arborescence-bloc": "Arborescence lieu",
        "column-spentTime": "Temps passé ( minute )",
        "collapse-filter": "Filtres complémentaires",
        "collapse-filter-lieux": "Filtrage par lieux",
        "modal-filters-title": "Filtrer les vérifications"
    },
    "en": {
        "page-title": "Recurrent tasks log",
        "tab-synthese-verifications": "Verifications carried out",
        "tab-non-conformites": "Non-compliant points",
        "menu-more-gerer-columns": "Manage the columns",
        "menu-more-export-xls": "Export (.xls)",
        "menu-more-rapport": "Create a report",
        "export-file-name-verifications": "Verifgood_verifications",
        "export-file-name-reponses-nonconformes": "Verifgood_non_compliant_replies",
        "filter-by-date": "Filter by verification date",
        "column-header-statutFM": "Curative sheet Status",
        "collapse-filter-lieux": "Filtering by location",
        "column-header-photo": "Photo",
        "action-button": "Action",
        "secondary-filtre-bloc": "Filter",
        "secondary-arborescence-bloc": "Location tree view",
        "column-spentTime": "Time spent (minutes)",
        "collapse-filter": "Extra filters",
        "modal-filters-title": "Filter verifications"
    },
    "th": {
        "tab-synthese-verifications": "ดำเนินการตรวจสอบต่อไป",
        "tab-non-conformites": "ไม่มีจุดการร้องเรียน",
        "menu-more-gerer-columns": "จัดการคอลัมน์",
        "menu-more-export-xls": "ส่งออก (.xls)",
        "menu-more-rapport": "สร้างรายงาน",
        "export-file-name-verifications": "Verifgood_verifications",
        "export-file-name-reponses-nonconformes": "Verifgood_non_compliant_replies",
        "collapse-filter-lieux": "การกรองตามที่ตั้ง",
        "column-header-photo": "รูปภาพ",
        "action-button": "ปฏิบัติการ",
        "secondary-filtre-bloc": "ตัวกรอง",
        "secondary-arborescence-bloc": "มุมมองต้นไม้ที่ตั้ง",
        "column-spentTime": "เวลาที่ใช้ไป (นาที)",
        "collapse-filter": "ตัวกรองพิเศษ"
        }
    }
},
    data: function() {
        return {
            focusedTab: "tous",
            searchQuery: '',
            agfilters: {
                dateVerif: {attr: "et.dateVerif", colId: "dateVerif", value: [moment().subtract(3, 'months').format('YYYY-MM-DD'), moment().add(1,'day').format("YYYY-MM-DD")], action: "between"},
                libel_lieu: {attr:"l.libel_lieu", colId: "libel_lieu", value: null, action:"contains", openParenthesis: true},
                libel_equipement: {attr:"eq.libel_equipement", colId: "libel_equipement", value: null, action:"contains", logicalOperator: "OR"},
                libel_tache: {attr:"t.libel_tache", colId: "libel_tache", value: null, action:"contains", logicalOperator: "OR"},
                qrCode: {attr:"eq.qrCode", colId: "qrCode", value: null, action:"contains", logicalOperator: "OR"},
                username: {attr:"et.username", colId: "username", value: null, action:"contains", logicalOperator: "OR", closeParenthesis: true},
                field_path: {attr: "l.path", colId: "path", value: null, action:"contains"},
                field_libel_tache: {attr:"t.libel_tache", colId: "t.libel_tache", value: null, action:"contains"},
                field_type_tache: {attr:"t.type_tache", colId: "t.type_tache", value: null, action:"equals"}
            },
            showModalVerification: false,
            verification: null,
            columnDefVisible: null,
            columnDefVisibleNonConforme: null
        }
    },
    watch: {
        searchQuery: {
            handler: function(query){
                this.agfilters.libel_lieu.value = query;
                this.agfilters.libel_equipement.value = query;
                this.agfilters.libel_tache.value = query;
                this.agfilters.qrCode.value = query;
                this.agfilters.username.value = query;
                this.FiltersMixins_saveSearchQuery(query);

            }
        }
    },
    computed: {
        ...mapGetters({
            verifications: "VerificationsStore/getCollection",
            verificationsCounters: "VerificationsStore/getCounters",
            reponsesNonConformes: "ReponsesStore/getCollection",
            reponsesCounters: "ReponsesStore/getCounters"
        }),
        pageFilters: function(){
            return ["field_path", "field_libel_tache", "field_type_tache"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
            let nFilters = 0;
            if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
            return activeFilters.length + nFilters;
        },
        tabs: function(){
            return [
                {
                    label: this.$t("tab-synthese-verifications"),
                    name: "tous",
                    counter: this.verificationsCounters? this.verificationsCounters.filtered+" / "+this.verificationsCounters.all: "-",
                    filters: [{colId: "statut", value: null}]
                },
                {
                    label: this.$t("tab-non-conformites"),
                    name: "nonconformites",
                    counter: this.reponsesCounters? this.reponsesCounters.filtered+" / "+this.reponsesCounters.all: "-",
                    filters: [{colId: "statut", value: "Cloturer"}]
                }
            ];
        },
        getHeaderBackgroundColor: function(){
            return this.$vgutils.isMobile() ? HEADER_BACKGROUND_COLOR_MOBILE : "";
        },
        getHeaderIcon: function(){
            return this.$vgutils.isMobile() ? ICON_MOBILE : ICON_DESKTOP;
        },
        tiersFilters: function(){
            return {
                field_type: {attr: "t.type", value: ["Client", "Customer"], action: "equals"}
            };
        }
    },
    methods: {
        handleChangeDateIntervalle: function(data){
            this.agfilters.dateVerif.value = [data.startDate, data.endDate];
        },
        onFetchSuccess: function(datas){
            this.columnDefVisible = datas.columnDefs;
        },
        onFetchSuccessReponses: function(datas){
            this.columnDefVisibleNonConforme = datas.columnDefs;
        },
        onInputTiersFilter: function(tiers){
            if(tiers && tiers.path) this.agfilters.field_path.value = tiers.path;
            else this.agfilters.field_path.value = null;
        },
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.agfilters[filterName].value = null;
            });
            /*this.agfilters.dateOuvertureSAV.value = [this.getIntervalStartDate, this.getIntervalEndDate];
            this.agfilters.dateFermetureSAV.value = [this.getIntervalStartDate, this.getIntervalEndDate];*/
            this.searchQuery = null;
        },
        actionExport: function(e){       
            // @TODO gérer l'onglet des nons conformités   
            let metadatas = new Metadatas();
            metadatas.setFilters(this.agfilters);
            switch (e.name) {
                case "askForXLS":
                this.VerificationMixins_exportHistoriqueTacheRecurrentes(metadatas)
                    break;
                case "askForCSV":
                this.VerificationMixins_exportHistoriqueTacheRecurrentes(metadatas,"csv")
                    break;
                case "gestionColonnesEventId":
                    window.dispatchEvent(new CustomEvent("gestionColonnesEventId", {
                        "detail": {"idTableau":"tableau-historique-verifications"}
                    }));
                    break;
                default:
            }
        },
        /**
         * @param object tache
         */
        handleFilteringTache: function(tache){
            if(!tache && this.agfilters.field_libel_tache.value){
                this.agfilters.field_libel_tache.value = null;
                return;
            }
            this.agfilters.field_libel_tache.value = tache.libel_tache;
        },
        handleClickVerification: function(verification){
            if(this.$vgutils.isMobile()) this.openVerificationDetails(verification);
        },
        openVerificationDetails: function(verification){
            this.showModalVerification = true;
            this.verification = verification;
        },
        openVerificationPrint:function(){
            let propsData = {
                "idTableau":"verification",
                "data": this.verifications,
                "columnDefs":this.columnDefVisible,
                "search":this.searchQuery,
                "titlehead":"préventive interne"
            }
            if(this.focusedTab=='nonconformites'){
                propsData = {
                    "idTableau":"verification",
                    "data": this.reponsesNonConformes,
                    "columnDefs":this.columnDefVisibleNonConforme,
                    "search":this.searchQuery,
                    "titlehead":"préventive interne"
                }
            }
            console.log("_verification_print propsData", propsData);
            this.$router.push({ name: '_verification_print', params: propsData});
        }
    },
    created: function() {
        this.FiltersMixins_page_name = "historiqueVerification";
        this.searchQuery = this.FiltersMixins_getStoredSearchQuery();
    }
}
</script>

<style lang="scss">

</style>
