<template>
    <vg-select
        v-model="selectedSites"
        :options="sites"
        :attributeValue="attributeValue"
        :attributeLabel="attributeLabel"
        :showAllOptions="showAllOptions"
        :multiple="multiple"
        :placeholder="placeholder?placeholder:$t('select-site')"
        @input="handleInputChange" />
</template>
<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import LieuMixins from "src/mixins/LieuMixins.js";
import Metadatas from "src/services/Metadatas";
export default {
    name: 'vg-sites-selector',
    mixins:[LieuMixins],
	components: {
		VgSelect
	},
    props: {
		/**
        * @model
        */
        value: {
            type: Array,
            default: ()=>{
                return null;
            }
        },
		/**
        * Filters used for query {"attr":"type_lieu","value":"Site","action":"equals"}.
        */
        filters: {
            type: Object,
            default: ()=>{
                return {};
            }
        },
        attributeValue: {
            type:String,
            default:"id"
        },
        attributeLabel: {
            type:String,
            default:"libel_lieu"
        },
        multiple:{
            type:Boolean,
            default:false
        },
        disabled:{
            type:Boolean,
            default:false
        },
        defaultValue:{
            type: Array,
            default: function(){
                return null;
            }
        },
        placeholder: {
            type: String,
            default: null
        },
        showAllOptions: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            selectedSites: this.multiple?[]:null,
            sites: []
        };
    },
    i18n:    { "locale":navigator.language,
        "messages": {
            "fr": {
                "select-site": "Sélectionner un site"
            },
            "en": {
                "select-site": "Select a site"
            }
        }
    },
    watch:{
        value: {
            handler: function(val){
                this.selectedSites = val;
                //this.selectedSites = this.getDefaultSelectedSites;
            }
        }
    },
	created: function(){
        this.getHasManySitesValue();
        this.fetch();
	},
    methods:{
        getHasManySitesValue: function(){
            this.hasManySites = !this.$storage.get("has-only-one-site");
        },
        /**
        * Emit change with array of lieu_s id .
        * @event change
        * @param Array lieux_value
        */
        handleInputChange:function(lieux_value){
            if (!this.multiple) {
                this.$emit("input",lieux_value);
            }
            this.$emit("change",lieux_value);
            if(!Array.isArray(lieux_value)) this.$emit("selected", this.sites.find((site)=>site.id==lieux_value));
        },
        /**
        * fetch sites
        */
        fetch: function(){
            let metadatas = new Metadatas();
            this.LieuMixins_getSites(metadatas).then((datas)=>{
                this.$store.dispatch("LieuxStore/setSites", datas.lieux);
                this.sites = [...datas.lieux];
                this.$storage.set("has-only-one-site", this.sites.length && this.sites.length==1);
                this.getHasManySitesValue();
                this.setDefaultValue();
                //this.selectedSites = this.getDefaultSelectedSites;
                this.$emit("after-feeded",this.sites);
            });
        },
        /**
         * cas d'utilisation : formulaire contrat en update
         */
        setDefaultValue: function(){
            if(this.multiple && this.defaultValue && this.defaultValue.length!=0) this.selectedSites = [...this.defaultValue];
            else if(!this.multiple && this.defaultValue) this.selectedSites = this.defaultValue;
        }
    },
    computed:{
        getDefaultSelectedSites: function(){
            if(this.defaultValue && this.defaultValue.length!=0) return this.defaultValue.map((site)=>this.attributeValue? site[this.attributeValue]: site);
            else if(this.value && this.multiple) return this.sites.filter((site)=>this.value.indexOf(site[this.attributeValue] || site.path)!=-1);
            else if(this.value && !this.multiple) return this.sites.filter((site)=>this.value.indexOf(site[this.attributeValue] || site.path)!=-1)[0];
            else return this.multiple?[]: null;
        }
    }
};

</script>
<style lang="scss" scoped>

</style>
