<template>
    <vg-app-layout
        :title="$t('page-title')"
        :showSecondary="false"
        :isActionButtonDisplay="false"
        :icon="getHeaderIcon"
        :colorheader="getHeaderBackgroundColor"
        @action-export="actionExport">
        <template #body>
            <vg-reporting-prints
                :data="data"
                :columnDefs="columnDefs"
                :search="search"
                :titlehead="titlehead">
            </vg-reporting-prints>
        </template>
    </vg-app-layout>
</template>

<script>

import VgButton from 'src/components/Vg/VgButton.vue';
import VgReportingPrints from 'src/components/Vg/TagGrid/VgReportingPrints.vue';

export default {
    name: "vg-verifications",
    components: {
        VgButton,
        VgReportingPrints,
    },
    mixins: [
    ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "page-title": "Rapport activité préventive interne"
        },
        "en": {
            "page-title": "Internal preventive activity report"
        }
    }
},
    props:{
        data: {
            type: Array,
            default: function(){
                return null;
            },
        },
        titlehead:String,
        allTitle:String,
        idTableau:{
            type:String,
            default:""
        },
        columnDefs:{
            type: Array,
            default: function(){
                return null;
            }
        },
        search:{
            type: String,
        }
    },
    data: function() {
        return {}
    },
    watch: {},
    computed: {},
    methods: {},
    created: function() {}
}
</script>

<style lang="scss">

    #tableau-historique-verifications{

    }
</style>
