<template>
<div class="vg-fichesav-table vg-print-hide">
	<tag-grid v-if="!showCards"
		:idTableau="getIdTableau"

		v-model="gridOptions"
		:columnDefs="columnDefs"
		:rowData="getDatas"
		:pathSplitKey="'equipement.path'"
		:showLoadingOverlay="showLoadingOverlay"
		:overlayLoadingText="$t(isReserves?'overlay-loading-text-reserves':'overlay-loading-text')"
		:overlayNoRowsText="$t(isReserves?'overlay-no-rows-text-reserves':'overlay-no-rows-text')"

		@grid-feeded="setDefaultFilter()"
        @ag-multiple-selection-row-changed="handleMultipleSelectionRowChanged"
		@on-grid-ready="setDefaultFilter()"
		@ag-dbl-click="handleDoubleClickRow"
		@ag-click="handleClickRow"
		@ag-long-click="handleLongClick" />
	<div v-else class="cards-container">
		<fm-cell-render v-for="(fm, index) in getDatas" :key="index"
			:class="['card-maintenance']"
			:fm="fm"
			:showRelance="showRelance"
			:affectable="affectable"
			:canTakePhotos="canTakePhotos"
			@clicked="handleClickRow"
			@relance="relance"
			@open-affectation="onOpenAffectation"
			@upload-done="onUploadPhotoDone"/>
	</div>
	<vg-pagination v-if="paginate"
		:totalItems="getCounters[counterName]"
		:offset="pagination.offset"
		:limit="pagination.limit"
		:currentPageNumber="pagination.currentPage"
		@pagination-change="handlePaginationChange"
	/>
	<vg-operation-create v-if="showModalAddOperation"
        :idMaintenance="focusedMaintenance"
		@close="showModalAddOperation = false"
		@save="saveOperationTextuelle" />
	<vg-fichesav-management v-if="showModalMaintenanceActions"
		@close="onCloseModalMaintenanceActions"/>
	<!-- IMPRESSION -->
	<vg-modal v-if="showMaintenancesRapport"
		:width="'100vw'"
		@close="showMaintenancesRapport=false">
		<template #body>
			<vg-reporting-prints
				class="vg-print-show"
				:idTableau="'vg-fiche-sav'"
				:data="getDatas"
				:columnDefs="columnDefs"
				:search="searchQuery"
				:allTitle="reportTitle?reportTitle:$t('report-title')">
			</vg-reporting-prints>
		</template>
		<template #footer>
			<vg-button
				@click="showMaintenancesRapport=false">
				{{$t("close")}}
			</vg-button>
		</template>
	</vg-modal>
	<vg-fichesav-relance-modal v-if="showRelanceModal"
		@close="showRelanceModal=false;"
		@save="showRelanceModal=false;"/>
	<vg-fichesav-affectation v-if="showAffectationModal"
		@close="showAffectationModal=false;"
		@save="fetch();showAffectationModal=false;"/>
</div>
</template>
<script>
	import TagGrid from "src/components/Grid/TagGrid.vue";
	import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
	import DescriptionFmCellRender from "src/components/Vg/TagGrid/DescriptionFmCellRender.vue";
	import PathCellRender from "src/components/Vg/TagGrid/PathCellRender.vue";
	import VgCostCellRender from "src/components/Vg/TagGrid/VgCostCellRender.vue";
	import BloquantFmCellRender from "src/components/Vg/TagGrid/BloquantFmCellRender.vue";

	import TagGridMixins from 'src/mixins/TagGridMixins.js';
	import StatutFmCellRender from "src/components/Vg/TagGrid/StatutFmCellRender.vue";
	import AffectationFmCellRender from "src/components/Vg/TagGrid/AffectationFmCellRender.vue";
	import UrgenceFmCellRender from "src/components/Vg/TagGrid/UrgenceFmCellRender.vue";
	import WorkingTimeCellRender from "src/components/Vg/TagGrid/WorkingTimeCellRender.vue";
	import OrigineFmCellRender from "src/components/Vg/TagGrid/OrigineFmCellRender.vue";
	import DatetimeCellRender from "src/components/Vg/TagGrid/DatetimeCellRender.vue";
	import DureeCellRender from "src/components/Vg/TagGrid/DureeCellRender.vue";
	import EquipementFmCellRender from "src/components/Vg/TagGrid/EquipementFmCellRender.vue";
	import FromDateCellRender from "src/components/Vg/TagGrid/FromDateCellRender.vue";
	import OperationTextuelleCellRender from "src/components/Vg/TagGrid/OperationTextuelleCellRender.vue";
	import ThumbnailCellRender from "src/components/Vg/TagGrid/ThumbnailCellRender.vue";
    import TagCellRender from "src/components/Vg/TagGrid/TagCellRender.vue";

    import VgFichesavManagement from "src/components/Vg/FicheSAV/VgFichesavManagement.vue";
    import VgFichesavRelanceModal from "src/components/Vg/FicheSAV/VgFichesavRelanceModal.vue";
    import VgFichesavAffectation from "src/components/Vg/FicheSAV/VgFichesavAffectation.vue";

    import VgOperationCreate from "src/components/Vg/Operation/VgOperationCreate.vue";
    import VgReportingPrints from "src/components/Vg/TagGrid/VgReportingPrints.vue";

	import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
	import FiltersMixins from "src/mixins/FiltersMixins.js";
    import Metadatas from "src/services/Metadatas.js";
    import FmCellRender from "src/components/Vg/TagGrid/FmCellRender.vue";

    import VgButton from "src/components/Vg/VgButton.vue";
	import VgPagination from "src/components/Vg/VgPagination.vue";

    import { mapGetters } from 'vuex';
    export default {
        name: 'vg-fichesav-table',
    	props: {
			/**
			* affiche les maintenances réserves ou pas
			* default false
			*/
			isReserves:{
				type: Boolean,
				default: false
			},
			/**
			* affiche les maintenances réserves ou pas
			* default false
			*/
			idTableau:{
				type: String,
				default: null
			},
			/**
			* liste des columns à hide (column colId)
			*/
			hiddenColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* liste des columns pined left (column colId)
			*/
			pinnedColumns: {
				type: Array,
				default: function(){
					return ["id", "commentaireClient", "piece"];
				}
			},
			/**
			* liste des columns non showable (column colId)
			*/
			notShowableColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
            /**
            * Affiche les colonnes liées aux coût de la maintenance.
            */
            showColumnsFinances:{
                type: Boolean,
                default:function(){
                    return false;
                }
            },
			/**
			* affiche le tableau en mode impression
			* default false
			*/
			showPrint : {
				type: Boolean,
				default: false
			},
			/**
			* recherche
			*/
			searchQuery: {
				type: String,
				default: ""
			},
			/**
			* filtres externes appliqué sur le tableau au fetch
			*/
			filters: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* filtres externes appliqué sur le tableau en local
			* @deprecated
			*/
			localFilters: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* titre rapport impression
			*/
			reportTitle: {
				type: String,
				default: null
			},
			paginate: {
				type: Boolean,
				default: true
			},
			offset: {
	            type: Number,
	            default: 0
	        },
	        limit: {
	            type: Number,
	            default: 25
	        },
			counterName:{
				type: String,
				default: "all"
			},
			showCards:{
				type: Boolean,
				default: false
			},
			showRelance:{
				type: Boolean,
				default: true
			},
			affectable:{
				type: Boolean,
				default: false
			},
			canTakePhotos:{
				type: Boolean,
				default: false
			},
			disabledRowClick:{
				type: Boolean,
				default: false
			},
			stored:{
				type: Boolean,
				default: true
			},
			idUserAffecte: {
				type: String | Number,
				default: null
			},
			idTiersAffecte: {
				type: String | Number,
				default: null
			},
			onlyEncours: {
				type: Boolean,
				default: true
			},
			page: {
				type: String,
				default: null
			}
        },
		watch: {
			searchQuery: {
				handler: function(query){
					this.TagGridMixins_setQuickFilter(this.gridOptions, query);
				}
			},
			filters: {
				handler: function(newfilters){
                    //console.log("FILTERS WATCH", newfilters);
					this.pagination.offset = 0;
					this.pagination.currentPage = 1;
					this.metadatasMaintenances.setLimit(this.pagination.offset, this.pagination.limit);
					this.FiltersMixins_savePagination(this.pagination.offset, this.pagination.limit, this.pagination.currentPage);
					this.fetch();
				},
				deep: true
			},
			localFilters: {
				handler: function(newfilters){
					//console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
					if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
				},
				deep: true
			},
            showColumnsFinances:{
                handler:function(isVisible){
                    this.setFinancesColumns(isVisible);
                },
                deep:true
            },
			idUserAffecte:{
				handler: function(id){
					this.fetch();
				}
			},
			idTiersAffecte:{
				handler: function(id){
					this.fetch();
				}
			}
		},
		mixins: [
			TagGridMixins,
			MaintenanceMixins,
			FiltersMixins
		],
		components: {
			TagGrid,
			DescriptionFmCellRender,
			IconNameCellRender,
			PathCellRender,
			VgCostCellRender,
			BloquantFmCellRender,
			StatutFmCellRender,
			AffectationFmCellRender,
			UrgenceFmCellRender,
			WorkingTimeCellRender,
			OrigineFmCellRender,
			DatetimeCellRender,
			DureeCellRender,
			EquipementFmCellRender,
			FromDateCellRender,
			OperationTextuelleCellRender,
			VgFichesavManagement,
			VgFichesavAffectation,
			VgOperationCreate,
			ThumbnailCellRender,
			VgReportingPrints,
			VgFichesavRelanceModal,
			VgButton,
			VgPagination,
			FmCellRender
		},
		i18n:    { "locale":navigator.language,
    	"messages": {
			"fr": {
				"overlay-loading-text": "Chargement des fiches curatives...",
				"overlay-no-rows-text": "Aucune fiche curative correspondant au filtrage",
				"overlay-loading-text-reserves": "Chargement des réserves / observations...",
				"overlay-no-rows-text-reserves": "Aucune réserve / observation correspondant au filtrage",
				"AgGrid-Id": "N° fiche",
				"AgGrid-Description": "Description",
				"AgGrid-bloquant": "Bloquant",
				"AgGrid-prestataire": "Prestataire",
				"AgGrid-contrat": "Contrat",
				"AgGrid-Localisation": "Localisation",
				"AgGrid-Affectation": "Affectations",
				"AgGrid-Urgence": "Urg.",
				"AgGrid-Origine": "Origine",
				"AgGrid-workingTime": "Durée intervention",
				"AgGrid-DateOuverture": "Date ouverture",
				"AgGrid-DateFermeture": "Fermeture",
				"AgGrid-dateAffectation": "Date affectation",
				"AgGrid-Traitement": "Traitement",
				"AgGrid-DateDiferee": "Diferee",
				"AgGrid-Equipement": "Composant/Equip",
				"AgGrid-Affecte": "Affecte a",
				"AgGrid-Status": "Statut",
				"AgGrid-Relance": "Relance",
				"AgGrid-RetourAFaire": "Retour à faire",
				"AgGrid-RetourFait": "Retour Fait",
				"AgGrid-operation-last-manuelle": "Dernière opération",
				"AgGrid-operation-validation": "Validation",
				"AgGrid-operation-fermeture": "Rapport de clôture",
				"AgGrid-Age": "Age",
				"AgGrid-corpsdetat": "Corps d'état",
				"AgGrid-Categorie": "Categorie",
				"AgGrid-Photo-1": "Photo 1",
				"AgGrid-Photo-2": "Photo 2",
				"AgGrid-Photo-3": "Photo 3",
				"AgGrid-coutInterne": "Coût interne",
				"AgGrid-coutExterne": "Coût externe",
				"AgGrid-coutConsommations": "Coût consommations",
				"AgGrid-cout-bons-de-commande": "Coût bons de commande TTC",
				"AgGrid-coutTotal": "Coût total",
				"aucune-affectation": "Aucune affectation",
				"report-title": "Verifgood_rapport_fiches_curatives",
				"AgGrid-typologie": "Typologie",
				"close": "Fermer",
				"duree-traitement": "Intervalle ouverture fermeture fiche",
				"duree-nette-traitement": "Durée nette traitement",
				"duree-mise-en-attente": "Durée mise en attente"
			},
			"en": {
				"overlay-loading-text": "Loading curative sheets...",
				"overlay-no-rows-text": "No curative sheet matching filtering",
				"overlay-loading-text-reserves": "Loading reservations / observations...",
				"overlay-no-rows-text-reserves": "No reservation / observation matching filtering",
				"AgGrid-Fiche": "Sheet no.",
				"AgGrid-Description": "Description",
				"AgGrid-bloquant": "Blocking",
				"AgGrid-Localisation": "Location",
				"AgGrid-Affectation": "Assignments",
				"AgGrid-Urgence": "Emergency ",
				"AgGrid-Origine": "Origin",
				"AgGrid-DateOuverture": "Opening date",
				"AgGrid-DateFermeture": "Closing date",
				"AgGrid-dateAffectation": "Date of assignment",
				"AgGrid-Traitement": "Treatment",
				"AgGrid-DateDiferee": "Date postponed",
				"AgGrid-Equipement": "Equipment",
				"AgGrid-Affecte": "Assigned to",
				"AgGrid-Status": "Status",
				"AgGrid-Relance": "Reminder",
				"AgGrid-Age": "Age ms",
				"AgGrid-corpsdetat": "Technical field",
				"AgGrid-Categorie": "Category",
				"AgGrid-workingTime": "Duration of work",
				"AgGrid-coutInterne": "Internal cost",
				"AgGrid-coutExterne": "External cost",
				"AgGrid-coutConsommations": "Consumption cost",
				"AgGrid-cout-bons-de-commande": "Purchase orders cost incl. VAT",
				"AgGrid-coutTotal": "Total Cost",
				"AgGrid-Id": "Sheet n°",
				"AgGrid-RetourAFaire": "Feedback pending",
				"AgGrid-RetourFait": "Feedback done",
				"AgGrid-operation-last-manuelle": "Last operation",
				"AgGrid-operation-validation": "Validate operation",
				"AgGrid-operation-fermeture": "Closing report",
				"AgGrid-Photo-1": "Photo 1",
				"AgGrid-Photo-2": "Photo 2",
				"AgGrid-Photo-3": "Photo 3",
				"aucune-affectation": "No assignment",
				"report-title": "Verifgood_curative_sheets_reports",
				"AgGrid-prestataire": "Service provider",
				"AgGrid-contrat": "Contract",
				"AgGrid-typologie": "Type",
				"close": "Close",
				"dureeTraitement": "Treatment duration",
				"duree-nette-traitement": "Net treatment duration",
				"duree-mise-en-attente": "Waiting time"
			}
		}
	},
        data: function(){
            return {
				localDatas: [], // utilisé uniquement si !stored
				localCounters: {},
				showLoadingOverlay: false,
				showModalAddOperation: false,
				showModalMaintenanceActions: false,
				showMaintenancesRapport: false,
				focusedMaintenance: null,
				gridOptions: {
					isExternalFilterPresent: ()=>this.TagGridMixins_isExternalFilterPresent(),
                    doesExternalFilterPass: (node)=>this.TagGridMixins_doesExternalFilterPass(node)
				},
				columnDefs: [
                    {
						headerName: '',
                        colId: 'checkbox',
						width: 50,
						maxWidth: 50,
						checkboxSelection: true,
						suppressSorting: true,
						cellClass: ["vg-cell-checkbox"],
						pinned: 'left',
                        hide: this.columnIsHidden("checkbox"),
					},
					{
						headerName: this.$t('AgGrid-Id'),
						field: 'id',
						colId: 'id',
						cellClass: ["vg-cell-ballam-theme"],
						width: 90,
						//pinned: 'left',
						hide: this.columnIsHidden("id"),
						isVisible: this.columnIsNotShowable("id")
					},
					{
						headerName: this.$t('AgGrid-DateOuverture'),
						name: 'dateOuvertureSAV',
						field: 'dateOuvertureSAV',
						colId: 'dateOuvertureSAV',
						getQuickFilterText: (params) => this.$vgutils.getDate(params.data.dateOuvertureSAV),
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.dateOuvertureSAV}}).$mount().$el,
						width: 140,
						hide: this.columnIsHidden("dateOuvertureSAV"),
						isVisible: this.columnIsNotShowable("dateOuvertureSAV")
					},
					{
						headerName: this.$t('AgGrid-prestataire'),
						field: 'intervention.tiers.name',
						colId: 'intervention.tiers.name',
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new IconNameCellRender({propsData: {icon: "static/assets/icone/circle/tiers-cercle.png", dataColumn: params.data.intervention.tiers.name, isMainColumn: false}}).$mount().$el,
						width: 230,
						hide: !this.isReserves,
						isVisible: this.isReserves
					},
					{
						headerName: this.$t('AgGrid-contrat'),
						field: 'intervention.contrat.name',
						colId: 'intervention.contrat.name',
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new IconNameCellRender({propsData: {icon: "static/assets/icone/circle/contrat-cercle.png", dataColumn: params.data.intervention.contrat.name, isMainColumn: false, route: { router: this.$router, name: '_contrat_id', params: {id: params.data.intervention.contrat.id } }}}).$mount().$el,
						width: 230,
						hide: !this.isReserves,
						isVisible: this.isReserves
					},
					{
						headerName: this.$t('AgGrid-typologie'),
						field: 'typologie',
						colId: 'typologie',
						cellClass: ["vg-cell-ballam-theme"]
					},
					{
						headerName: this.$t('AgGrid-Description'),
						field: 'commentaireClient',
						colId: 'commentaireClient',
						getQuickFilterText: (params) => {
							var result = "";
							if(params.data.composant && params.data.composant.libelComposant) result += params.data.composant.libelComposant+" ";
							else result += params.data.equipement.libel_equipement+" ";
							result += params.data.commentaireClient;
							return result;
						},
						cellClass: ["vg-cell-ballam-theme vg-important-cell vg-font-weight-bold"],
						cellRenderer: (params) => new DescriptionFmCellRender({propsData: {params: params}}).$mount().$el,
						width: 400,
						//pinned: 'left',
						hide: this.columnIsHidden("commentaireClient"),
						isVisible: this.columnIsNotShowable("commentaireClient")
					},{
						headerName: this.$t('AgGrid-bloquant'),
						field: 'isBloquant',
						colId: 'isBloquant',
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new BloquantFmCellRender({propsData: {params: params}}).$mount().$el,
						width: 100,
						hide: this.showPrint || this.columnIsHidden("isBloquant"),
						isVisible: this.columnIsNotShowable("isBloquant")
					},
					{
						headerName: this.$t('AgGrid-Localisation'),
						field: 'equipement.path',
						colId: 'path',
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new PathCellRender({propsData: {path: params.data.equipement.path}}).$mount().$el,
						width: 230,
						hide: this.columnIsHidden("path"),
						isVisible: this.columnIsNotShowable("path")
					},
					{
						headerName: this.$t('AgGrid-Status'),
						field: 'statut',
						colId: 'statut',
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new StatutFmCellRender({propsData: {params: params}}).$mount().$el,
						width: 100,
						hide: this.columnIsHidden("statut"),
						isVisible: this.columnIsNotShowable("statut")
					},
					{
						headerName: this.$t('AgGrid-Affectation'),
						name: 'affectation',
						field: 'affectation',
						colId: 'affectation',
						getQuickFilterText: (params)=>{
							var result = '';
							if(params.data.affectation){
								params.data.affectation.affectes.forEach((affecte)=>affecte.user_id?result+=' '+affecte.user_name:result+=' '+affecte.name);
							}
							return result;
						},
						tooltip: (params)=>{
							if(params.data.affectation && params.data.affectation.affectes){
								return ;
							}else{
								return this.$t("aucune-affectation");
							}
						},
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new AffectationFmCellRender({propsData: {params: params}}).$mount().$el,
						width: 130,
						hide: this.showPrint || this.columnIsHidden("affectation"),
						isVisible: this.columnIsNotShowable("affectation")
					},
					{
						headerName: 'isMeAffectation',
						colId: 'isMeAffectation',
						getQuickFilterText: (params)=>{
							return params.data.affectation && params.data.affectation.affectes.forEach((affecte)=>affecte.user_id==this.$app.idUser);
						},
						cellClass: ["vg-cell-ballam-theme"],
						hide: true,
						isVisible: false // not showable
					},
                    {
						headerName: this.$t('AgGrid-workingTime'),
						colId: 'workingTime',
						field: 'workingTime',
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new WorkingTimeCellRender({propsData: {workingtime: params.data.workingTime}}).$mount().$el,
						width: 120,
						hide: this.showPrint || this.columnIsHidden("workingTime"),
						isVisible: this.columnIsNotShowable("workingTime")
					},
                    {
						headerName: this.$t('AgGrid-corpsdetat'),
						colId: 'corpsdetat',
						field: 'corpsdetat',
						cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => {
                            if(params.data.corpsDetat){
                                return new TagCellRender({propsData: {tags: params.data.corpsDetat.name || null, icon:"tag.png",color:"red"}}).$mount().$el

                            }else{
                                return null;
                            }
                        },
						width: 120,
						hide: false,
						isVisible: this.columnIsNotShowable("corpsdetat")
					},
					{
						headerName: this.$t('AgGrid-Urgence'),
						field: 'urgence',
						colId: 'urgence',
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new UrgenceFmCellRender({propsData: {params: params}}).$mount().$el,
						width: 100,
						hide: this.showPrint || this.columnIsHidden("urgence"),
						isVisible: this.columnIsNotShowable("urgence")
					},
					{
						headerName: this.$t('AgGrid-Origine'),
						colId: 'origin',
						field: 'origin',
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new OrigineFmCellRender({propsData: {params: params}}).$mount().$el,
						width: 160,
						hide: this.columnIsHidden("origin"),
						isVisible: this.columnIsNotShowable("origin")
					},
					{
						headerName: this.$t('AgGrid-DateFermeture'),
						field: 'dateFermetureSAV',
						colId: 'dateFermetureSAV',
						name: 'dateFermetureSAV',
						getQuickFilterText: (params) => this.$vgutils.getDate(params.data.dateFermetureSAV),
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.dateFermetureSAV}}).$mount().$el,
						width: 170,
						hide: this.columnIsHidden("dateFermetureSAV"),
						isVisible: this.columnIsNotShowable("dateFermetureSAV")
					},
					{
						headerName: this.$t('duree-traitement'),
						field: 'dureeTraitement',
						colId: 'dureeTraitement',
						name: 'dureeTraitement',
						cellClass: ["vg-cell-ballam-theme"],
						hide: this.columnIsHidden("dureeTraitement"),
						isVisible: this.columnIsNotShowable("dureeTraitement"),
						cellRenderer: (params) => new DureeCellRender({propsData: {duree: params.data.dureeTraitement}}).$mount().$el
					},
					{
						headerName: this.$t('duree-nette-traitement'),
						field: 'dureeNetteTraitement',
						colId: 'dureeNetteTraitement',
						name: 'dureeNetteTraitement',
						cellClass: ["vg-cell-ballam-theme"],
						hide: this.columnIsHidden("dureeNetteTraitement"),
						isVisible: this.columnIsNotShowable("dureeNetteTraitement"),
						cellRenderer: (params) => new DureeCellRender({propsData: {duree: params.data.dureeNetteTraitement}}).$mount().$el
					},
					{
						headerName: this.$t('duree-mise-en-attente'),
						field: 'dureeMiseEnAttente',
						colId: 'dureeMiseEnAttente',
						name: 'dureeMiseEnAttente',
						cellClass: ["vg-cell-ballam-theme"],
						hide: this.columnIsHidden("dureeMiseEnAttente"),
						isVisible: this.columnIsNotShowable("dureeMiseEnAttente"),
						cellRenderer: (params) => new DureeCellRender({propsData: {duree: params.data.dureeMiseEnAttente}}).$mount().$el
					},
					{
						headerName: this.$t('AgGrid-operation-fermeture'),
						field: 'operations.operation_fermeture',
						colId: 'operation_fermeture',
						cellClass: ["vg-cell-ballam-theme"],
						width: 160,
						hide: this.showPrint || this.columnIsHidden("operation_fermeture"),
						isVisible: this.columnIsNotShowable("operation_fermeture")
					},
					{
						headerName: this.$t('AgGrid-dateAffectation'),
						field: 'affectation.start',
						colId: 'dateAffectation',
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.affectation?params.data.affectation.start:null}}).$mount().$el,
						width: 170,
						hide: this.showPrint || this.columnIsHidden("dateAffectation"),
						isVisible: this.columnIsNotShowable("dateAffectation")
					},
					{
						headerName: this.$t('AgGrid-Equipement'),
						colId: 'libelEquipement',
						cellClass: ["vg-cell-ballam-theme"],
						getQuickFilterText: (params) => params.data.composant && params.data.composant.libelComposant?params.data.composant.libelComposant:params.data.equipement.libel_equipement,
						cellRenderer: (params) => new EquipementFmCellRender({propsData: {params: params}}).$mount().$el,
						width: 180,
						hide: this.showPrint || this.columnIsHidden("libelEquipement"),
						isVisible: this.columnIsNotShowable("libelEquipement")
					},
					{
						headerName: this.$t('AgGrid-Relance'),
						field: 'operations.isRelance',
						colId: 'isRelance',
						cellClass: ["vg-cell-ballam-theme"],
						width: 160,
						hide: true,
						isVisible: false
					},
					{
						headerName: this.$t('AgGrid-RetourAFaire'),
						field: 'operations.isRetourAFaire',
						colId: 'isRetourAFaire',
						cellClass: ["vg-cell-ballam-theme"],
						width: 160,
						hide: true,
						isVisible: false
					},
					{
						headerName: this.$t('AgGrid-RetourFait'),
						field: 'operations.isRetourFait',
						colId: 'isRetourFait',
						cellClass: ["vg-cell-ballam-theme"],
						width: 160,
						hide: true,
						isVisible: false
					},
					{
						headerName: this.$t('AgGrid-operation-last-manuelle'),
						field: 'operations.operation_last_manuelle',
						colId: 'operation_last_manuelle',
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new OperationTextuelleCellRender(
							{propsData: {params: params}}
						)
							.$on("open-operation-create", this.openOperationCreate)
							.$mount().$el,
						width: 160,
						hide: this.showPrint || this.columnIsHidden("operation_last_manuelle"),
						isVisible: this.columnIsNotShowable("operation_last_manuelle")
					},
					{
						headerName: this.$t('AgGrid-operation-validation'),
						field: 'operations.operation_validation',
						colId: 'operation_validation',
						cellClass: ["vg-cell-ballam-theme"],
						width: 160,
						hide: this.showPrint || this.columnIsHidden("operation_validation"),
						isVisible: this.columnIsNotShowable("operation_validation")
					},
					{
						headerName: this.$t('AgGrid-Age'),
						colId: 'age',
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new FromDateCellRender({propsData: {value: params.data.dateOuvertureSAV}}).$mount().$el,
						width: 104,
						hide: this.showPrint || this.columnIsHidden("age"),
						isVisible: this.columnIsNotShowable("age")
					},
					{
						headerName: this.$t('AgGrid-Categorie'),
						width: 200,
						field: 'equipement.libelleCatgorie',
						colId: 'libelleCatgorie',
						cellClass: ["vg-cell-ballam-theme"],
						hide: this.columnIsHidden("libelleCatgorie"),
						isVisible: this.columnIsNotShowable("libelleCatgorie")
					},
					{
						headerName: this.$t('AgGrid-Photo-1'),
						width: 100,
						field: 'photos',
						colId: 'photos',
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new ThumbnailCellRender({
							propsData: {
								params: {
									data: params.data.photos.length>0?params.data.photos[0]:{}
								},
								attributeName: "name",
								attributeUid: "uploadedTo"
							}
						}).$mount().$el,
						hide: this.columnIsHidden("photos"),
						isVisible: this.columnIsNotShowable("photos")
					},
					{
						headerName: this.$t('AgGrid-Photo-2'),
						width: 100,
						field: 'photos',
						colId: 'photos1',
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new ThumbnailCellRender({
							propsData: {
								params: {
									data: params.data.photos.length>1?params.data.photos[1]:{}
								},
								attributeName: "name",
								attributeUid: "uploadedTo"
							}
						}).$mount().$el,
						hide: this.columnIsHidden("photos"),
						isVisible: this.columnIsNotShowable("photos")
					},
					{
						headerName: this.$t('AgGrid-Photo-3'),
						width: 100,
						field: 'photos',
						colId: 'photos2',
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new ThumbnailCellRender({
							propsData: {
								params: {
									data: params.data.photos.length>2?params.data.photos[2]:{}
								},
								attributeName: "name",
								attributeUid: "uploadedTo"
							}
						}).$mount().$el,
						hide: this.columnIsHidden("photos"),
						isVisible: this.columnIsNotShowable("photos")
					},
					{
						headerName: this.$t('AgGrid-reserve'),
						colId: 'isReserve',
						cellClass: ["vg-cell-ballam-theme"],
                        hide: true,
						isVisible: true
					},
                    {
						headerName: this.$t('AgGrid-coutInterne'),
						colId: 'coutInterne',
						cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => new VgCostCellRender({propsData: {value: params.data.coutInterne}}).$mount().$el,
						width: 104,
						hide: true
					},
                    {
						headerName: this.$t('AgGrid-coutExterne'),
						field: 'coutExterne',
						name: 'coutExterne',
						colId: 'coutExterne',
						cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => new VgCostCellRender({propsData: {value: params.data.coutExterne}}).$mount().$el,
						width: 104,
						hide: true
					},
                    {
						headerName: this.$t('AgGrid-coutConsommations'),
						field: 'coutConsommations',
						name: 'coutConsommations',
						colId: 'coutConsommations',
						cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => new VgCostCellRender({propsData: {
                            value: params.data.coutConsommations
                        }}).$mount().$el,
						width: 104,
						hide: true
					},
                    {
						headerName: this.$t('AgGrid-cout-bons-de-commande'),
						field: 'bonsDeCommandeMontantTTC',
						name: 'bonsDeCommandeMontantTTC',
						colId: 'bonsDeCommandeMontantTTC',
						cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => new VgCostCellRender({propsData: {
                            value: params.data.bonsDeCommandeMontantTTC
                        }}).$mount().$el,
						width: 104,
						hide: true
					},
                    {
						headerName: this.$t('AgGrid-coutTotal'),
						field: 'coutTotal',
						name: 'coutTotal',
						colId: 'coutTotal',
						cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => new VgCostCellRender({propsData: {
                            value: params.data.coutTotal
                        }}).$mount().$el,
						width: 104,
						hide: true
					}
				],
                metadatasMaintenances: new Metadatas(),
				showRelanceModal: false,
				showAffectationModal: false,
				pagination: {
					offset: this.offset, 
					limit: this.limit, 
					currentPage: this.offset+1
				}
            };
        },
		created: function(){
			if(this.showCards && this.canTakePhotos) this.VgFilesMixins_init(null, "fichemaintenance");
            if(this.$vgutils.isMobile()){
                this.columnDefs.map((columnDef)=>{
                    columnDef.hide = true;
                });
                this.columnDefs.unshift({
					headerName: "",
					field:"",
					cellClass: ["vg-cell-mobile-card"],
                    cellRenderer: (params) => new FmCellRender({propsData: {fm: params.data}}).$mount().$el
				});
            }
		},
		mounted: function(){
			window.addEventListener("askGenerateReport", this.generateReport);
			this.initPagination().then(()=>{
				this.fetch();
			});
		},
		methods: {
			initPagination: function(){
				return new Promise((resolve, reject)=>{
					if(this.page && this.paginate){ 
						this.FiltersMixins_page_name = this.page;
						this.pagination = {
							offset: this.getDefaultPaginationOffset,
							limit: this.getDefaultPaginationLimit,
							currentPage: this.getDefaultPaginationCurrentPage
						};
						this.metadatasMaintenances.setLimit(this.pagination.offset, this.pagination.limit);
						resolve();
					}else{ 
						if(this.paginate) this.metadatasMaintenances.setLimit(this.pagination.offset, this.pagination.limit);
						resolve();
					}
				});
			},
			relance: function(maintenance){
				this.showRelanceModal = true;
			},
			generateReport: function(datas){
				if(datas.detail.idTableau=="vg-fichesav-table"){
					this.showMaintenancesRapport = true;
				}
			},
			openOperationCreate: function(data){
				this.focusedMaintenance = data.idMaintenance;
				this.showModalAddOperation = true;
			},
			saveOperationTextuelle: function(){
                this.showModalAddOperation = false;
				this.fetch();
            },
			/**
			* column is hidden
			* @param String colId
			* @return Boolean
			*/
			columnIsHidden: function(colId){
				return this.hiddenColumns.indexOf(colId)!=-1;
			},
			/**
			* column is pinned to left
			* @param String colId
			* @return Boolean
			*/
			columnIsPinnedToLeft: function(colId){
				return this.pinnedColumns.indexOf(colId)!=-1;
			},
			/**
			* column is not showable
			* @param String colId
			* @return Boolean
			*/
			columnIsNotShowable: function(colId){
				return !(this.notShowableColumns.indexOf(colId)!=-1);
			},
            /**
            * Switch to display or hide financial column.
            * @param Boolean isVisible
            */
            setFinancesColumns: function(isVisible){
				if(this.showCards) return;
                /*this.gridOptions.columnApi.setColumnVisible('urgence', !isVisible);
                this.gridOptions.columnApi.setColumnVisible('origin', !isVisible);
                this.gridOptions.columnApi.setColumnVisible('dateOuvertureSAV', !isVisible);
                this.gridOptions.columnApi.setColumnVisible('libelEquipement', !isVisible);
                this.gridOptions.columnApi.setColumnVisible('age', !isVisible);
                this.gridOptions.columnApi.setColumnVisible('affectation', !isVisible);*/
                this.gridOptions.columnApi.setColumnVisible('photos', !isVisible);
                this.gridOptions.columnApi.setColumnVisible('photos1', !isVisible);
                this.gridOptions.columnApi.setColumnVisible('photos2', !isVisible);
                this.gridOptions.columnApi.setColumnVisible('operation_last_manuelle', !isVisible);
                this.gridOptions.columnApi.setColumnVisible('operation_validation', !isVisible);
                /*this.gridOptions.columnApi.setColumnVisible('dateAffectation', !isVisible);
                this.gridOptions.columnApi.setColumnVisible('dateFermetureSAV', !isVisible);
				*/
                this.gridOptions.columnApi.setColumnVisible('coutInterne', isVisible);
                this.gridOptions.columnApi.setColumnVisible('coutExterne', isVisible);
                this.gridOptions.columnApi.setColumnVisible('coutConsommations', isVisible);
                this.gridOptions.columnApi.setColumnVisible('bonsDeCommandeMontantTTC', isVisible);
                this.gridOptions.columnApi.setColumnVisible('coutTotal', isVisible);
            },
			fetch: function(){
				this.showLoadingOverlay = true;
				let filters = this.filters.filter((filter)=> filter.attr!="fm.isReserve");
				filters.push({ attr:"fm.isReserve",colId: "isReserve", value: "1", action:this.isReserves?"equals":"not_equals" });
				this.metadatasMaintenances.setFiltersFromArray(filters);
				this.MaintenanceMixins_getMaintenances(this.metadatasMaintenances, {_stored: this.stored, idUserAffecte: this.idUserAffecte, idTiersAffecte: this.idTiersAffecte, onlyEncours: this.onlyEncours}).then((datas)=>{
					if(!this.stored){ 
						console.log("GET MAINTENANCES---------", this.isReserves, datas.datas);
						this.localDatas = datas.datas;
						this.localCounters = datas.metadatas.counters;
					}
                    if(!this.$vgutils.isMobile()) this.setFinancesColumns(this.showColumnsFinances);
					this.showLoadingOverlay = false;
					this.$emit("fetch-success", {maintenances: datas});
				});
			},
			setDefaultFilter: function(){
				if(this.showPrint) this.gridOptions.domLayout= "forPrint";
				this.$emit("grid-feeded", this.gridOptions);
			},
			goToZoom: function(data){
				// sauvegarde la pagination offset limit lorsqu'on zoom sur une fm
				this.savePagination();
                if(this.can("MAINTENANCE.PAGE")) this.$router.push({ name: this.isReserves?'_reserve':'_maintenance', params: { id: data.id, datas: data } });
            },
			handleClickRow: function(e){
				this.$store.dispatch("MaintenancesStore/setMaintenance", e.data);
				if(!this.disabledRowClick) {
					this.$emit("row-click", e.data);
					if(this.$vgutils.isMobile()) this.goToZoom(e.data);
				}
			},
			onOpenAffectation: function(fm){
				this.$store.dispatch("MaintenancesStore/setMaintenance", fm);
				this.showAffectationModal = true;
			},
			onUploadPhotoDone: function(fm){
				this.fetch();
			},
			handleDoubleClickRow: function(e){
				console.log("DOUBLE CLICK ROW---------------------", e.data);
				this.$emit("row-dbl-click", e.data);
				this.goToZoom(e.data);
			},
			handleLongClick: function(e){
				if(this.$vgutils.isMobile()){
					this.$store.dispatch("MaintenancesStore/setMaintenance", e.data);
					this.showModalMaintenanceActions = true;
				}
			},
			onCloseModalMaintenanceActions: function(){
				this.$store.dispatch("MaintenancesStore/updateMaintenance", this.maintenance);
				this.showModalMaintenanceActions = false;
				this.$store.dispatch("MaintenancesStore/deleteSelectedMaintenance");
			},
            handleMultipleSelectionRowChanged: function(gridOptions){
				var selectedNodes = gridOptions.api.getSelectedNodes();
                let maintenances = selectedNodes.map((node)=>{
                    return node.data;
                });
                this.$store.dispatch("MaintenancesStore/selectMaintenances", maintenances);
			},
	        handlePaginationChange: function(pagination){
				this.pagination = {offset: pagination.offset, limit: pagination.numberOfRowsToDisplay, currentPage: pagination.currentPage};
	            this.metadatasMaintenances.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
	            this.fetch();
	        },
			savePagination: function(){
				if(this.page && this.pagination) this.FiltersMixins_savePagination(this.pagination.offset, this.pagination.limit, this.pagination.currentPage);
			}
		},
		beforeDestroy:function(){
			this.savePagination();
	        window.removeEventListener("askGenerateReport", this.generateReport);
	    },
        computed:{
            ...mapGetters({
                  maintenances: 'MaintenancesStore/getMaintenances',
                  maintenance: 'MaintenancesStore/getSelectedMaintenance',
                  reserves: 'MaintenancesStore/getReserves',
				  counters: "MaintenancesStore/getMaintenancesCounters"
            }),
			getDatas: function(){
				if(this.stored && this.isReserves) return this.reserves;
				else if(this.stored && !this.isReserves) return this.maintenances;
				else if(!this.stored) return this.localDatas;
				else return this.maintenances;
			},
			getCounters: function(){
				if(this.stored) return this.counters;
				else return this.localCounters;
			},
            getIdTableau:function(){
                let idTableau = 'vg-fichesav-table';
                if(this.idTableau) idTableau = this.idTableau;
                else if(this.isReserves) idTableau = 'vg-reserves-table';
                return idTableau;
            }
        }
    };
</script>
<style lang="scss" scoped>
.vg-fichesav-table{
	height: 90%;
	overflow-y: auto;
}
.cards-container{
	width: 100%;
	height: 90%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	>div{
		width: 100%;
		border: 1px solid whitesmoke;
		padding: 5px;
		cursor: pointer;
	}
	.card-maintenance:hover{
		border-left: 4px solid whitesmoke;
	}
	.card-maintenance-selected{
		border: 1px solid #059cff;
	}
	.card-maintenance-selected:hover{
		border-left: 4px solid #059cff;
	}
}
</style>
