<template>
    <div style="display: flex;justify-content: flex-start;align-items:center;gap: 5px;width:100%;">
        <vg-intervention-statut-viewer :intervention="params.data">
        </vg-intervention-statut-viewer>
        <!-- btn de validaiton de l'intervention si l'intervention n'a pas été encore réalisée -->
        <vg-button v-if="params.data.status=='intervention-prevue'"
            :type="getValidateInterventionButtonType"
            :size="'sm'"
            @click="onClickValidateIntervention"
            @click-unauthorized="showMessageUnauthorize">
            {{$t("valider")}}
        </vg-button>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgInterventionStatutViewer from "src/components/Vg/Interventions/VgInterventionStatutViewer.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        components: {
            VgButton,
            VgInterventionStatutViewer
        },
        i18n:{
            messages: {
                "fr": {
                    "valider": "Valider",
                    "unauthorized-user-role": "Vous n'êtes pas authorisé à valider une intervention."
                }
            }
        },
        computed:{
            isDatePrevueDepassee: function(){
                return moment().format("YYYY-MM-DD HH:mm:ss")>moment(this.params.data.datePrevisionnelleDebut).format("YYYY-MM-DD HH:mm:ss");
            },
            isUserIsAuthorized: function(){
                return this.$app.role=="ROLE_ADMIN" || this.$app.role=="ROLE_SOUS_TRAITANT";
            },
            getValidateInterventionButtonType: function(){
                if(this.isUserIsAuthorized) return this.isDatePrevueDepassee?'default-danger':'default';
                else return "locked";
            }
        },
        methods:{
            onClickValidateIntervention: function(){
                this.$emit('valid-intervention',this.params.data);
            },
            showMessageUnauthorize: function(){
                alert(this.$t("unauthorized-user-role"));
            }
        }
    });
</script>

<style lang="scss" scoped>

</style>
