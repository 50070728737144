<template>
	<div class="vg-help" >
		<button v-if="menu"
			class="vg-help-button-menu"
			@click="handleOpenHelp">
			<vg-icon url="static/assets/icone/menu/menu-info.svg" color="#fff" size="18"/>
			<span>{{ $t("button-help") }}</span>
		</button>
		<i v-else-if="isIcon" class="far fa-question-circle fa-2x" @click="handleOpenHelp"></i>
		<vg-button v-else
			type="info"
			size="sm"
			class="btn-tutoriels"
			@click="handleOpenHelp">
			{{ $t("tutoriels") }}
		</vg-button>
		<vg-modal v-if="showModal"
			:title="$t('title')"
			:theme="'info'"
			@close="showModal=false;" >
			<template #body>
				<div class="section-title">
					<img src="static/assets/icone/video.png"/>
					<span>{{ $t("videos-support") }}</span>
				</div>
				<div class="section-content">
					<span @click="openLink('https://share.synthesia.io/dd02774d-6bc9-4d79-a041-d66e3d7c7378')">{{ $t("comment-creer-une-di") }}</span>
					<span @click="openLink('https://share.synthesia.io/566e2777-1c11-4c06-a51a-173eb2dbea06')">{{ $t("comment-verifier-un-lieu") }}</span>
					<!--span @click="openLink('')">{{ $t("comment-verifier-un-equipement") }}</span-->
					<span @click="openLink('https://share.synthesia.io/87020cf7-1f3d-4c95-8fe5-abc49690563a')">{{ $t("comment-recenser-un-equipement") }}</span>
					<span @click="openLink('https://share.synthesia.io/ae6b5c7f-e17a-4912-bb5d-d19b2e659fc1')">{{ $t("comment-cloturer-une-maintenance") }}</span>
				</div>
				<div class="section-title">
					<img src="static/assets/icone/icone-telechargement.jpg"/>
					<span>{{ $t("guides-prise-en-main") }}</span>
				</div>
				<div class="section-content">
					<span @click="openMethodeDeploiement">{{ $t("methode-deploiement") }}</span>
					<span @click="openTourDhorizonFonctionnalite">{{ $t("tour-dhorizon-principales-fonctionnalites") }}</span>
					<span @click="openListeDescriptiveFonctionnalites">{{ $t("liste-descriptive-fonctionnalites") }}</span>
				</div>
			</template>
			<template #footer>
				<vg-button
					type="info"
					@click="handleClickKnoledgesDB" >
					{{$t("show-hubspot-base-connaissance")}}
				</vg-button>
				<vg-button
					type="info"
					@click="handleClickNousContacter" >
					{{$t("show-hubspot-form-create")}}
				</vg-button>
				<vg-button type="default-info" @click="showModal=false;">
					{{$t("close")}}
				</vg-button>
			</template>
		</vg-modal>
		<vg-hubspot-form-create v-if="showHubspotFormCreate"
			@close="showHubspotFormCreate=false" />
	</div>
</template>
<script>

	import VgButton from "src/components/Vg/VgButton.vue";
	import VgIcon from "src/components/Vg/VgIcon.vue";
	import VgHubspotFormCreate from "src/components/Vg/Utils/VgHubspotFormCreate.vue";
    export default {
        name: 'vg-helper',
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Verifgood ACADEMIE",
            "close": "Fermer",
            "show-file": "Voir le document procédure",
            "show-video": "Voir la vidéo",
            "show-hubspot-form-create": "Contacter le support",
            "show-hubspot-base-connaissance": "Base de connaissance",
			"tutoriels": "Tutoriels",
			"videos-support": "Vidéos support",
			"guides-prise-en-main": "Guides de prise en main",
			"comment-creer-une-di": "Comment créer une demande d'intervention",
			"comment-verifier-un-lieu": "Comment vérifier un lieu",
			"comment-verifier-un-equipement": "Comment vérifier un équipement",
			"comment-recenser-un-equipement": "Comment recenser un équipement",
			"comment-cloturer-une-maintenance": "Comment clôturer une fiche curative",
			"methode-deploiement": "Méthode de déploiement",
			"tour-dhorizon-principales-fonctionnalites": "Tour d'horizon principales fonctionnalités",
			"liste-descriptive-fonctionnalites": "Liste descriptive des fonctionnalités",
			"button-help": "Aide"
        },
        "en": {
            "title": "Verifgood ACADEMY",
            "close": "Close",
            "show-file": "See procedural document",
            "show-video": "See the video",
            "show-hubspot-base-connaissance": "knowledges database",
            "show-hubspot-form-create": "Contact support",
			"videos-support": "Support videos",
			"guides-prise-en-main": "Getting Started Guides",
			"comment-creer-une-di": "How to create an intervention request",
			"comment-verifier-un-lieu": "How to check a room",
			"comment-verifier-un-equipement": "How to check an equipment",
			"comment-recenser-un-equipement": "How to register an equipment",
			"comment-cloturer-une-maintenance": "How to close a curative sheet",
			"methode-deploiement": "Deployment method",
			"tour-dhorizon-principales-fonctionnalites": "Main features overview",
			"liste-descriptive-fonctionnalites": "Features descriptive list",
			"button-help": "Help"
        },
        "th": {
            "close": "ปิด",
            "show-file": "ดูเอกสารตามขั้นตอน",
            "show-video": "ดูวิดีโอ",
            "show-hubspot-base-connaissance": "ฐานข้อมูลความรู้"
        }
    }
},
		components: {
			VgButton,
			VgIcon,
			VgHubspotFormCreate
		},
        props: {
			file: {
				type: String,
				default: null
			},
			video: {
				type: String,
				default: null
			},
			isIcon: {
				type: Boolean,
				default: true
			},
			menu: {
				type: Boolean,
				default: false
			}
        },
        data: function() {
            return {
				showModal: false,
				showHubspotFormCreate: false
            };
        },
		methods: {
			openLink: function(link){
				window.open(link,'_blank');
			},
			openMethodeDeploiement: function(){
				this.openLink(
					this.$i18n.locale=="fr"?
					"https://4502775.fs1.hubspotusercontent-na1.net/hubfs/4502775/Verifgood%20academy/FR-VERIFGOOD-METHODE-DEPLOIEMENT.pdf"
					:"https://4502775.fs1.hubspotusercontent-na1.net/hubfs/4502775/Verifgood%20academy/EN-methode-deploiement.pdf"
				);
			},
			openTourDhorizonFonctionnalite: function(){
				this.openLink(
					this.$i18n.locale=="fr"?
					"https://4502775.fs1.hubspotusercontent-na1.net/hubfs/4502775/Verifgood%20academy/FR-VERIFGOOD-TOUR-HORIZON.pdf"
					:"https://4502775.fs1.hubspotusercontent-na1.net/hubfs/4502775/Verifgood%20academy/EN-horizon-principales-fonctionnalite.pdf"
				);
			},
			openListeDescriptiveFonctionnalites: function(){
				this.openLink(
					this.$i18n.locale=="fr"?
					"https://4502775.fs1.hubspotusercontent-na1.net/hubfs/4502775/fichier%202024/FR-Verifgood-Principales-fonctionnalit%C3%A9s%20.pdf"
					:"https://4502775.fs1.hubspotusercontent-na1.net/hubfs/4502775/fichier%202024/1024-I-EN-Verifgood-Verifgood-Key-characteristics.pdf"
				);
			},
			handleOpenHelp: function(){
				this.showModal = true;
			},
			openInNewTab: function(url){
				let win = window.open(url, '_blank');
  				win.focus();
			},
			handleClickShowFile: function(){
				this.openInNewTab(this.file);
			},
			handleClickShowVideo: function(){
				this.openInNewTab(this.video);
			},
			handleClickNousContacter: function(){
				this.showHubspotFormCreate = true;
			},
            handleClickKnoledgesDB:function(){
                window.open("https://verifgood.crunch.help/fr");
            }
		}
    };

</script>
<style lang="scss" scoped>
.vg-help>i{
	cursor: pointer;
}
.vg-help>i:hover{
	color: #40a9ff;
}
.vg-help-button-menu{
	background-color: #2563EB !important;
	border: none !important;
	color:white;
	border-radius: 6px;
	height:30px;
	padding: 0 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap:8px;
	font-size:13px;
	span{
		line-height: 13px;
		font-weight: bold;
	}
}
.vg-help-button-menu:hover{
	box-shadow: 4px 4px 10px rgba(37,99,235,0.3);
}
button>i{
	color: white;
}
.btn-tutoriels{
	padding-left: 10px !important;
	padding-right: 10px !important;
	background-color: #3999ff;
	border: 1px solid #3999ff;
	box-shadow: 0 1px #3999ff;
	border-radius: 4px;
	text-transform: uppercase;
	color: white !important;
}
.header-content{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
}
.section-title{
	font-size: 16px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	padding-bottom: 10px;
	border-bottom: 2px solid #3999ff;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #333;
	img{
		height: 25px;
		width: 25px;
	}
}
.section-content{
	font-size: 12px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 20px;
	color: #333;
	margin-bottom: 40px;
	span{
		cursor: pointer;
	}
	span:hover{
		text-decoration: underline;
		color:#3999ff;
	}
}
</style>
