<template>
    <vg-app-layout
       :title="getTitle"
       :icon="'lieu-blanc.png'"
       :isZoomLayout="true"
       :reducedBody="$vgutils.isMobile() ? false : true"
       :isActionButtonDisplay="false"
       :colorheader="getHeaderColor"
       @backpage="handleRetour"
    >
        <template #body>
            <div v-if="step == 'selectLieu'">
                <vg-tree-lieux v-if="showTreeLieu"
                    :readOnly="true"
                    @select-piece="handleClickLieu" />
                <div v-else>
                    <vg-text-filter @input="onInputSearchPiece" />
                    <vg-filter-site v-model="agfiltersPieces.path.value"
                        style="margin: 10px 0;" />
                    <vg-filter-service v-model="agfiltersPieces.service.value"
                        style="margin: 10px 0;" />
                    <vg-lieux-table
                        :id="'vg-lieux-recensement-table'"
                        :filters="agfiltersPieces"
                        paginate
                        isDemandeIntervention
                        @select-piece="handleClickLieu" />
                </div>
            </div>
            <div v-if="step == 'scanCode'" >
                <div style="display:flex;justify-content:center;width:100%;padding:8px;">
                    <div style="color: #d6d1ce;">
                        {{ $t("recensement-scan-dans") }}: &nbsp;
                    </div>
                    <vg-lieu-path-viewer
                        v-model="lieu.path"
                        onlyPiece
                        :showTooltip="false"
                        style="font-weight:bold;">
                    </vg-lieu-path-viewer>
                </div>
                <zxing-scanner
                    @scanSuccess="handlScanSuccess"
                    :isDemo="true"
                    :code="'qrcode'">
                </zxing-scanner>
            </div>

            <div v-if="step == 'createEntityEquipement' ">
                <div style="display:flex;justify-content:center;width:100%;padding:8px;">
                    <div style="color: #d6d1ce;">
                        {{ $t("recensement-scan-dans") }}: &nbsp;
                    </div>
                    <vg-lieu-path-viewer
                        v-model="lieu.path"
                        onlyPiece
                        :showTooltip="false"
                        style="font-weight:bold;">
                    </vg-lieu-path-viewer>
                </div>
                <div v-if="selectedCategorie" style="display:flex;justify-content:center;width:100%;font-size:12px;">
                    <span style="color: #d6d1ce;" >{{$t("qrcode-nouvel-quipement")}}: &nbsp;</span>
                    <span>{{equipement.qrCode}}</span>
                    
                </div>
                <form id="form_recensement_equipement" onSubmit="return false">
                    
                    <vg-input v-if="!selectedCategorie"
                        :isRequiredValue="true"
                        class="marginUnderInput"
                        style="margin-top: 30px;">
                        <vg-categorie-selector v-model="selectedCategorie"
                            :placeholder="$t('recensement-form-categorie')"
                            :clearable="false"
                            :attributeValue="null"
                            :isGe="false"
                            @input="onSelectCategorie" />
                    </vg-input>
                    <vg-button type="success-link"
                        v-if="!selectedCategorie"
                        class="marginUnderInput"
                        style="width:100%;"
                        @click="showCategorieCreate = true">
                        {{$t("create-categorie")}}
                    </vg-button>
                    <div v-if="!selectedCategorie" style="margin-bottom:25px;color: #d6d1ce;font-size: 12px;">
                        {{$t("reprise-cinq-categorie")}}
                    </div>
                    <div id="last-five-categories-bloc" v-if="!selectedCategorie" >
                        <div style="width: 100%;margin-bottom:15px;" v-for="categorie in lastFiveCategoriesCreated" :key="categorie.id">
                            <vg-button
                                type="info"
                                @click="handleSetCategorieShortcut(categorie)"
                                style="width: 100%;"
                                > {{categorie.libelleCatgorie}}
                            </vg-button>
                        </div>
                    </div>

                    <div v-if="equipement.idCategorie" style="margin-top: 15px;">
                        <!-- LIBEL EQUIPEMENT -->
                        <div style="display:flex;align-items: baseline;justify-content: space-between;" >
                            <span id="libelle-categorie-selectionne">
                                {{selectedCategorie.libelleCatgorie}}
                            </span>
                            <vg-input
                                class="marginUnderInput"
                                v-model="libelEquipement"
                                :placeholder="$t('complement-libeller-equipement')"
                                style="width:50%;">
                            </vg-input>
                        </div>
                        <!-- NUMERO SERIE -->
                        <div style="display:flex;align-items: baseline;" class="marginUnderInput">
                            <div style="width: 100%;margin-right: 10px;">
                                <vg-input
                                    v-model="equipement.numSerie"
                                    :placeholder="$t('recensement-form-numSerie')"
                                >
                                </vg-input>
                            </div>
                            <div>
                                <vg-button
                                    style="margin-right: 0px !important;"
                                    type="grey"
                                    @click="isScannerNumSerieDisplayed=true">
                                    <i class="fas fa-qrcode"></i>
                                </vg-button>
                            </div>
                        </div>
                        <!-- REFERENCE CONSTRUCTEUR -->
                        <div style="display:flex;align-items: baseline;" class="marginUnderInput">
                            <div style="width: 100%;margin-right: 10px;">
                                <vg-input
                                    v-model="equipement.refConstructeur"
                                    :placeholder="$t('recensement-form-refConst')"
                                >
                                </vg-input>
                            </div>
                            <div>
                                <vg-button
                                    style="margin-right: 0px !important;"
                                    type="grey"
                                    @click="isScannerRefConstructeurDisplayed=true">
                                    <i class="fas fa-qrcode"></i>
                                </vg-button>
                            </div>
                        </div>
                        <!-- REFERENCE DOCUMENT EXTERNE -->
                        <div style="display:flex;align-items: baseline;" class="marginUnderInput">
                            <div style="width: 100%;margin-right: 10px;">
                                <vg-input
                                    v-model="equipement.ReferenceDocumentExterne"
                                    :placeholder="$t('reference-document-externe')"
                                >
                                </vg-input>
                            </div>
                            <div>
                                <vg-button
                                    style="margin-right: 0px !important;"
                                    type="grey"
                                    @click="isScannerRefConstructeurDisplayed=true">
                                    <i class="fas fa-qrcode"></i>
                                </vg-button>
                            </div>
                        </div>
                        
                        <!-- ETAT EQUIPEMENT -->
                        <vg-input class="marginUnderInput">
                            <vg-select
                                v-model="equipement.etat"
                                :attributeLabel="'label'"
                                :attributeValue="'value'"
                                :options="allEtat"
                                :placeholder="$t('recensement-etat')"
                            >
                            </vg-select>
                        </vg-input>
                        <!-- POSITION ETIQUETTE -->
                        <div class="posEtiquette"  v-if="selectedCategorie.positionEtiquette" style="margin:10px 0;">
                            <span style="color: #d6d1ce;font-size: 11px;">{{$t("position-etiquette")}} :</span>
                            <span>{{selectedCategorie.positionEtiquette}}</span>
                        </div>
                        <vg-input
                            v-if="$vgutils.isMobile()"
                            :title="$t('etiquetter')"
                            >
                            <template>
                                <vg-equipement-etiquetage-selector
                                    v-model.number="equipement.etiquetter"
                                >
                                </vg-equipement-etiquetage-selector>
                            </template>
                        </vg-input>

                        <vg-collapse :title="$t('recensement-form-plusDetails')" style="margin-top:20px;">
                            <template v-slot:content>
                                <!-- NUMERO COMPTABLE -->
                                <vg-input
                                    v-model="equipement.numeroImmobilisation"
                                    class="marginUnderInput"
                                    :placeholder="$t('recensement-form-numeroImmobilisation')"
                                >

                                </vg-input>
                                <!-- date remplacements et garanties -->
                                <vg-input
                                    v-model="equipement.dureeDeViePrevisionnelle"
                                    inputType="number"
                                    class="marginUnderInput"
                                    :placeholder="$t('duree-de-vie-previsionnelle')"
                                >

                                </vg-input>

                                <vg-input
                                    v-model="equipement.dateExpirationGarantie"
                                    inputType="date"
                                    class="marginUnderInput"
                                    :title="$t('date-expiration-garantie')"
                                >

                                </vg-input>

                                <vg-input
                                    v-model="equipement.dateRemplacementPrevisionelle"
                                    inputType="date"
                                    class="marginUnderInput"
                                    :title="$t('date-remplacement-previsionnelle')"
                                >

                                </vg-input>
                                <!-- MARQUE -->
                                <vg-input
                                    class="marginUnderInput"
                                    style="display: flex; align-items: baseline;"
                                >
                                    <vg-categorie-marque-selector v-model="equipement.marque"
                                        :placeholder="$t('recensement-marque')"
                                        style="margin-right:10px;"
                                        />
                                    <vg-button type="info"
                                        style="margin-right: 0px !important;"
                                        @click="showAddMarque=true;">
                                        <i class="fas fa-plus"></i>
                                    </vg-button>
                                    <vg-categorie-marque-form v-if="showAddMarque"
                                        v-model="selectedCategorie"
                                        @close="showAddMarque=false;"
                                        @save="onAddCategorieMarque"/>

                                </vg-input>
                                <!-- FOURNISSEUR -->
                                <vg-input
                                    class="marginUnderInput"
                                    :isRequiredValue="false"
                                >
                                    <vg-tiers-selector
                                        v-model="equipement.fournisseur_id"
                                        :placeholder="$t('recensement-form-fournisseur')"
                                        :isClearable="true">
                                    </vg-tiers-selector>
                                </vg-input>
                                <!-- DESCRIPTION -->
                                <vg-input
                                    v-model="equipement.caracTechniques"
                                    class="marginUnderInput"
                                    :placeholder="$t('recensement-form-caracteristiques')"
                                    inputType="textarea"
                                >

                                </vg-input>
                                <!-- DATE MISE EN SERVICE -->
                                <vg-input
                                    v-model="equipement.miseEnService"
                                    class="marginUnderInput"
                                    :title="$t('recensement-form-dateMiseEnService')"
                                    inputType="datetime-local"
                                >

                                </vg-input>
                                <!-- quantité à créer -->
                                <div v-if="!$vgutils.isMobile()" style="display:flex;align-items: baseline;" class="marginUnderInput">
                                    <div style="width: 100%;margin-right: 10px;">
                                        <vg-input
                                            v-model="equipement.qty"
                                            inputType="number"
                                            :placeholder="$t('quantite-a-creer')"
                                        >
                                        </vg-input>
                                    </div>
                                </div>
                                <!-- VALEUR ACHAT -->
                                <vg-input
                                    v-model="equipement.valeurAchat"
                                    :title="$t('recensement-form-valAchat')"
                                    class="marginUnderInput"
                                    :placeholder="$t('recensement-form-valAchat')"
                                    :inputType="'float'"
                                >

                                </vg-input>
                                <!-- DUREE AMORTISSEMENT -->
                                <vg-input
                                    v-model="equipement.dureeAmortissement"
                                    :title="$t('recensement-form-dureeAmortissement')"
                                    class="marginUnderInput"
                                    :placeholder="$t('recensement-form-dureeAmortissement')"
                                    inputType="number"
                                >

                                </vg-input>
                                <!-- DATE ACHAT -->
                                <!--vg-input
                                    v-model="equipement.dateFabrication"
                                    class="marginUnderInput"
                                    :placeholder="$t('recensement-form-dateAchat')"
                                    inputType="date"
                                >

                                </vg-input-->

                                <!--vg-input
                                    v-model="equipement.caracTechniques"
                                    class="marginUnderInput"
                                    :placeholder="$t('recensement-form-caracteristiques')"
                                >

                                </vg-input-->
                                <!-- SELECTEUR ASSET -->
                                <vg-input
                                    class="marginUnderInput"
                                    :placeholder="$t('recensement-asset')"
                                    :title="$t('recensement-asset')"
                                >
                                    <vg-select
                                        v-model="equipement.type"
                                        :attributeLabel="'label'"
                                        :attributeValue="'value'"
                                        :options="allAsset"
                                        :placeholder="$t('recensement-asset')"
                                    >
                                    </vg-select>
                                </vg-input>

                                <!-- HACK le footer passe au dessus des inputs sinon -->
                                <div style="height:50px;">
                                </div>

                            </template>
                        </vg-collapse>

                    </div>


                </form>
            </div>

            <vg-categorie-form v-if="showCategorieCreate"
                @close="showCategorieCreate = false"
                @save="handleSavedCategorie">
            </vg-categorie-form>
            
            <div id="form_feedback_equipement" v-if="step == 'feedbackSuccess' ">
                <div style="display:flex;justify-content:space-around;align-items:center;width:100%;margin:20px 0;">
                    <div style="display:flex;flex-direction:column;justify-content:flex-start;margin-right:20px;">
                        <span style="font-size:16px;"><b>{{equipement.libel_equipement}}</b></span>
                        <span style="color: #666666;font-size:12px;font-weight:bold;">{{equipement.qrCode}}</span>
                    </div>
                    <img src="static/assets/icone/verif-conforme.png" style="width:30px;height:30px;"/>
                </div>
                <div style="display:flex;justify-content:center;align-items:center;width:100%;">
                    <span style="color: #d6d1ce;">{{$t('recensement-scan-dans')}}: &nbsp;</span>
                    <vg-lieu-path-viewer
                        v-model="lieu.path"
                        onlyPiece
                        :showTooltip="false"
                        style="font-weight:bold;">
                    </vg-lieu-path-viewer>
                </div>
                <br>
                <vg-files-viewer-photo :files="VgFilesMixins_filesOnlyPhoto">
                </vg-files-viewer-photo>
            </div>
        </template>

        <template #footer >
            <div style="display: flex;width: 100%;justify-content: space-evenly;align-items:center;background-color:white;padding-bottom: 10px;">
                <!-- boutons form create eq -->
                <vg-button v-if="step=='scanCode'"
                    :type="'grey'"
                    style="width: 100%;"
                    @click="skipScanQrCode">
                    {{ $t('je-ne-gere-pas-code') }}
                </vg-button>
                <vg-button
                    v-if="step == 'createEntityEquipement' && selectedCategorie"
                    :type="'grey'"
                    style="width:30vw;"
                    @click="handleRetour" >
                    {{ $t("recensement-form-annuler") }}
                </vg-button>
                <vg-button
                    v-if="step == 'createEntityEquipement' && selectedCategorie"
                    :type="'grey'"
                    style="width:30vw;"
                    @click="onChangeCategorie" >
                    {{ $t("recensement-form-changer-categorie") }}
                </vg-button>
                <vg-button
                    v-if="step == 'createEntityEquipement' && selectedCategorie"
                    @click="createEquipment"
                    type="success"
                    style="width:30vw;"
                    :disabled="!equipement.idCategorie">
                    {{ $t("recensement-form-recenser") }}
                </vg-button>

                <!-- boutons confirmation -->
                <!--vg-button v-if="step == 'feedbackSuccess' "
                    @click="handleRecenserNewEquipement"
                    > {{$t("continuer")}}
                </vg-button-->
                <vg-button v-if="step == 'feedbackSuccess' "
                    @click="handleRecenserAnotherOne"
                    :type="'grey'"
                    style="width:30vw;"
                    > {{$t("continuer")}}
                </vg-button>
                <vg-button v-if="step == 'feedbackSuccess' "
                    @click="isEquipementTableDiplayed = true"
                    :type="'grey'"
                    style="width:30vw;"
                    > {{$t("voir-equipement-de-cette-piece")}}
                </vg-button>
                <vg-button v-if="step == 'feedbackSuccess' "
                    @click="handleRecenserInAnotherPiece"
                    :type="'grey'"
                    style="width:30vw;"
                    > {{$t("changer-de-piece")}}
                </vg-button>
            </div>

        </template>

        <vg-modal
            v-show="showModalUploadPhoto"
            :title="$t('do-you-want-to-take-a-picture')"
            @close="showModalUploadPhoto = false"
        >
            <template #body></template>
            <template #footer>
                <vg-button
                    @click="showModalUploadPhoto=false"
                >
                    {{$t('non')}}
                </vg-button>
                <vg-files-uploader
                    :inputLabel="$t('oui')"
                    :isOnlyPhoto="true"
                    @upload-done="handleUploadDone">
                </vg-files-uploader>
            </template>
        </vg-modal>

        <vg-modal
            v-if="isScannerNumSerieDisplayed || isScannerRefConstructeurDisplayed"
            @close="isScannerNumSerieDisplayed=false;isScannerRefConstructeurDisplayed=false;"
        >
            <template #body>
                <zxing-scanner
                    @scanSuccess="handleScanCode"
                    :code="'barcode'"
                    key="scanner-num-serie"
                >
                </zxing-scanner>
            </template>
            <template #footer>
                <vg-button
                    @click="isScannerNumSerieDisplayed = false;isScannerRefConstructeurDisplayed=false;">
                    {{$t("close")}}
                </vg-button>
            </template>
        </vg-modal>
        <vg-modal
            v-if="isEquipementTableDiplayed && equipementsFilters.idLieu.value"
            :title="$t('voir-equipement-de-cette-piece')"
            @close="isEquipementTableDiplayed = false"
        >
            <template #body>
                <vg-equipements-table
                    :filters="equipementsFilters"
                    :showPrint="false"
                    style="height:60vh;"
                    @row-click="handleClickRow" />
            </template>
            <template #footer>
                <vg-button
                    @click="isEquipementTableDiplayed = false">
                    {{$t("close")}}
                </vg-button>
            </template>
        </vg-modal>



    </vg-app-layout>
</template>
<script>
    import equipementMixins from "src/mixins/equipementsMixins.js";
    import personnalParameters from "src/mixins/personnalParameters.js";
    import LieuMixins from "src/mixins/LieuMixins.js";

    import zxingScanner from 'src/components/QrCode/Zxing/zxingScanner.vue';

    import VgFilesViewerPhoto from "src/components/Vg/Files/VgFilesViewerPhoto.vue";
	import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";

    import VgSelect from "src/components/Vg/VgSelect.vue";
    import VgCollapse from 'src/components/Vg/VgCollapse.vue';

    import VgTiersSelector from "src/components/Vg/Selectors/VgTiersSelector.vue";
    import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";
    import VgCategorieMarqueSelector from "src/components/Vg/Selectors/VgCategorieMarqueSelector.vue";
    import VgEquipementEtiquetageSelector from "src/components/Vg/Selectors/VgEquipementEtiquetageSelector.vue";

    import VgCategorieMarqueForm from "src/components/Vg/Forms/VgCategorieMarqueForm.vue";

    import VgEquipementsTable from "src/components/Vg/Equipements/VgEquipementsTable.vue";
    import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
    import VgCategorieForm from "src/components/Vg/Forms/VgCategorieForm.vue";

    import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
    import VgFilterService from "src/components/Vg/Filters/VgFilterService.vue";
    import VgTextFilter from "src/components/Vg/VgTextFilter.vue";
    import VgLieuxTable from "src/components/Vg/Lieu/VgLieuxTable.vue";

    import VgInput from "src/components/Vg/VgInput.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
    import Metadatas from "src/services/Metadatas.js";
    import { mapGetters, mapActions } from 'vuex';
    export default {
        name: 'recensement-equipement',
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "duree-de-vie-previsionnelle": "Durée de vie ( en Mois )",
            "date-expiration-garantie": "Date expiration garantie ( prévisionnelle )",
            "date-remplacement-previsionnelle": "Date remplacement ( prévisionnelle )",
            "recensement-scan-titre": "Recensement",
            "step-1-quel-qrcode": "1/4: quel QRCODE?",
            "step-2-quelle-categorie": "2/4: quelle catégorie?",
            "step-3-quelles-proprietes": "3/4: quelles propriétés?",
            "step-4-confirmation": "4/4: confirmation",
            "recensement-scan-dans": "dans",
            "recensement-scan-messScanInt": "Je possède un scanner intégré au clavier",
            "recensement-form-categorie": "Définir une catégorie",
            "recensement-form-changer-categorie": "Changer catégorie",
            "recensement-form-refConst": "Référence constructeur",
            "recensement-form-numeroImmobilisation": "Numéro comptable",
            "recensement-form-marque": "Choisir marque",
            "recensement-form-numSerie": "Numéro de série",
            "recensement-form-plusDetails": "Plus de détails",
            "recensement-form-moinsDetails": "Moins de détails",
            "recensement-form-caracteristiques": "Carctéristiques techniques",
            "recensement-form-valAchat": "Valeur achat",
            "recensement-form-fournisseur": "Fournisseur",
            "recensement-form-dateAchat": "Date achat",
            "recensement-form-dateMiseEnService": "Date mise en service",
            "recensement-form-dureeAmortissement": "Durée amortissement",
            "recensement-form-description": "Description",
            "recensement-form-annuler": "Annuler",
            "recensement-form-recenser": "Recenser",
            "recensement-equipementExist": "code équipement déjà en service, changer d étiquette",
            "recensement-qrCodeWrong": "qr code non valide ",
            "recensement-photoSuccess": "Photo ajouté avec succès",
            "numeroImmobilisation": "Référence du logiciel comptable",
            "feedback-canvas": "Affichage de l'image ...",
            "recensement-qty-recenser": "quantité équipment",
            "recenser-marker": "Recenser le marker",
            "position-etiquette": "Charte collage",
            "recensement-etat": "Etat",
            "recensement-good": "Etat : Bon",
            "recensement-middle": "Etat : Moyen",
            "recensement-bad": "Etat : Mauvais",
            "recensement-asset": "Asset",
            "recensement-building": "Batimentaire",
            "recensement-marque": "Marque",
            "complement-libeller-equipement": "complement libellé",
            "continuer": "Continuer",
            "code-already-taken": "Le code est déjà utilisé pour un autre équipement",
            "code": "code",
            "changer-de-piece": "Changer de pièce",
            "feedback-create-equipement": "l'équipement",
            "qrcode-nouvel-quipement": "Qrcode nouvel équipement",
            "oui": "Oui",
            "non": "Non",
            "voir-equipement-de-cette-piece": "Liste équipements",
            "reprise-cinq-categorie": "Ou reprendre parmi les 5 dernères",
            "do-you-want-to-take-a-picture": "Voulez-vous prendre une photo ?",
            "close": "Fermer",
            "quantite-a-creer": "Quantité à créer",
            "reference-document-externe": "Référence document externe",
            "etiquetter": "Etiquette collée ?",
            "create-categorie": "Ajouter catégorie",
            "je-ne-gere-pas-code": "Je ne colle pas d'étiquette QRCODE pour l'instant"
        },
        "en": {
            "duree-de-vie-previsionnelle": "Expected lifespan ( months )",
            "date-expiration-garantie": "Warranty end date",
            "date-remplacement-previsionnelle": "Provisional replacement date",
            "recensement-scan-titre": "Inventory",
            "recensement-scan-dans": "Inside",
            "recensement-scan-scanner": "Scan new equipment sticker",
            "recensement-scan-messScanInt": "My keyboard has an integrated scanner",
            "recensement-form-categorie": "Set category",
            "recensement-form-refConst": "Manufacturer reference",
            "recensement-form-numeroImmobilisation": "Accounting number",
            "recensement-form-marque": "Choose brand",
            "recensement-form-numSerie": "Serial number",
            "recensement-form-plusDetails": "More details",
            "recensement-form-moinsDetails": "Less details",
            "recensement-form-caracteristiques": "technical characteristics",
            "recensement-form-valAchat": "Purchase value",
            "recensement-form-fournisseur": "Provider",
            "recensement-form-dateAchat": "Purchase date",
            "recensement-form-description": "Description",
            "recensement-form-annuler": "Cancel",
            "recensement-form-recenser": "Inventory",
            "numeroImmobilisation": "Accounting software reference",
            "feedback-canvas": "loading...",
            "recensement-qty-recenser": "Equipment's quantity",
            "recenser-marker": "Set marker",
            "position-etiquette": "Sticker positionning location",
            "recensement-etat": "State",
            "recensement-good": "Good",
            "recensement-middle": "Middling",
            "recensement-bad": "Bad",
            "recensement-marque": "Brand",
            "complement-libeller-equipement": "name complement",
            "continuer": "Continue",
            "etiquetter": "Sticker attached ?",
            "qrcode-nouvel-quipement":"equipement's Qrcode",
            "recensement-equipementExist": "Equipment code already used, please change the tag",
            "recensement-qrCodeWrong": "qr code not valid ",
            "recensement-photoSuccess": "Photo added",
            "recensement-asset": "Asset",
            "recensement-building": "Building",
            "code-already-taken": "This code is already in use",
            "changer-de-piece": "Change room",
            "feedback-create-equipement": "Equipment",
            "oui": "Yes",
            "non": "No",
            "voir-equipement-de-cette-piece": "Show equipments",
            "reprise-cinq-categorie": "Or pick one of the last five ",
            "do-you-want-to-take-a-picture": "Do you want to take a photo ?",
            "reference-document-externe": "External document reference",
            "recensement-form-changer-categorie":"Change category",
            "quantite-a-creer": "Quantity to create",
            "create-categorie": "Add category",
            "je-ne-gere-pas-code": "I'm not sticking a QRCODE label for now"
        },
        "th": {
            "recensement-scan-dans": "ด้านใน",
            "recensement-scan-scanner": "สแกนสติกเกอร์อุปกรณ์ใหม่",
            "recensement-scan-messScanInt": "แป้นพิมพ์ของฉันรวมอยู่กับสแกนเนอร์",
            "recensement-form-refConst": "การอ้างอิงผู้ผลิต",
            "recensement-form-numeroImmobilisation": "หมายเลขทางบัญชี",
            "recensement-form-marque": "เลือกยี่ห้อ",
            "recensement-form-numSerie": "หมายเลขชุด",
            "recensement-form-plusDetails": "รายละเอียดเพิ่มเติม",
            "recensement-form-moinsDetails": "รายละเอียดน้อยลง",
            "recensement-form-caracteristiques": "ลักษณะ",
            "recensement-form-valAchat": "มูลค่าการสั่งซื้อ",
            "recensement-form-fournisseur": "ผู้ให้บริการ",
            "recensement-form-dateAchat": "วันที่สั่งซื้อ",
            "recensement-form-description": "คำอธิบาย",
            "recensement-form-annuler": "ยกเลิก",
            "recensement-form-recenser": "สินค้าคงคลัง",
            "numeroImmobilisation": "การอ้างอิงซอฟต์แวร์การทำบัญชี",
            "feedback-canvas": "กำลังโหลด...",
            "recensement-qty-recenser": "จำนวนอุปกรณ์",
            "recenser-marker": "ตั้งตัวทำเครื่องหมาย",
            "position-etiquette": "ตำแหน่งสติกเกอร์",
            "recensement-etat": "สถานะ",
            "recensement-good": "ดี",
            "recensement-middle": "ปานกลาง",
            "recensement-bad": "แย่",
            "recensement-marque": "ยี่ห้อ",
            "complement-libeller-equipement": "ชื่ออุปกรณ์เสริม",
            "continuer": "ดำเนินการต่อ",
            "recensement-equipementExist": "รหัสอุปกรณ์นี้ถูกใช้งานแล้ว โปรดเปลี่ยนแท็ก",
            "recensement-qrCodeWrong": "รหัสคิวอาร์ไม่ถูกต้อง ",
            "recensement-photoSuccess": "เพิ่มรูปภาพแล้ว",
            "recensement-asset": "สินทรัพย์",
            "recensement-building": "สิ่งปลูกสร้าง"
        }
    }
},
        components: {
            VgFilesViewerPhoto,
            VgFilesUploader,
            zxingScanner,
            VgSelect,
            VgTiersSelector,
            VgCategorieSelector,
            VgCategorieMarqueSelector,
            VgInput,
            VgLieuPathViewer,
            VgCollapse,
            VgButton,
            VgEquipementsTable,
            VgCategorieMarqueForm,
            VgTreeLieux,
            VgCategorieForm,
            VgEquipementEtiquetageSelector,
            VgFilterSite,
            VgFilterService,
            VgTextFilter,
            VgLieuxTable
		},
        mixins: [
            equipementMixins,
            personnalParameters,
            LieuMixins
		],
        data:function() {
            return {
                showTreeLieu: false,
                libelEquipement:"",
                fullscreenLoading:false,
                showMore:false,
                code:'',
                isIntegratedScannerInKeyboard : false,
                step:'scanCode',
				uid: null,
                localListEquipements:this.$store.getters.getEquipementsInLastPlaceVisited,
                persistedEquipment:{},
				headerstate: 1,
                selectedCategorie:null,
                allEtat:[
                    {label:this.$t("recensement-etat"),value:"null"},
                    {label:this.$t("recensement-good"),value:"Good"},
                    {label:this.$t("recensement-middle"),value:"Middling"},
                    {label:this.$t("recensement-bad"),value:"Bad"},
                ],
                allAsset:[
                    {label:this.$t("recensement-asset"),value:"Asset"},
                    {label:this.$t("recensement-building"),value:"Building"},
                ],
                completeForm:false,
                equipement:{
                    libelEquipement:'',
                    qrCode:'',
                    caracTechniques:null,
                    numSerie:null,
                    numeroImmobilisation:null,
                    //idLieu:null,
                    refConstructeur:null,
                    champOptionel:null,
                    idCategorie:null,
                    marque:null,
                    typeCategorie:null,
                    valeurAchat:null,
                    fournisseur_id:null,
                    dateAchat:null,
                    type:"Asset",
                    etat:"Good",
                    userId : this.$app.appID,
                    miseEnService: null,
                    etiquetter:0
                },
                showModalUploadPhoto:false,
                isScannerNumSerieDisplayed:false,
                isScannerRefConstructeurDisplayed:false,
                isEquipementTableDiplayed:false,
                equipementsFilters:{
                    isGEP:{attr:"e.isGroupEqp",value:"1",action:"not_equals"},
                    idLieu: { attr:"e.idLieu_id",colId: "e.idLieu_id", value: this.$route.params.idLieu, action:"equals" }
                },
                lastFiveCategoriesCreated:[],
                showAddMarque: false,
                showCategorieCreate: false,
                agfiltersPieces: {
                    type_lieu: {attr: "l.type_lieu", value: "Piece", action: "equals"},
                    path: {attr: "l.path", value: null, action: "contains"},
                    service: {attr: "l.service", value: null, action: "equals"},
                    libel_lieu: {attr: "l.libel_lieu", value: null, action: "contains", openParenthesis: true},
                    codeTrois: {attr: "l.codeTrois", value: null, action: "contains", logicalOperator: "OR"},
                    codeDeux: {attr: "l.codeDeux", value: null, action: "contains", logicalOperator: "OR"},
                    servicetext: {attr: "l.service", value: null, action: "contains", closeParenthesis: true, logicalOperator: "OR"}
                }
            }
        },
        created:function(){
            this.isIntegratedScannerInKeyboard = this.personnalParameters_getUseIntegratedScanerInKeyboard();
            this.lastFiveCategoriesCreated = this.fetchLastFiveEquipments();
            // fetchLieu and then fetch
            if(!this.isIntegratedScannerInKeyboard){
                this.equipement.etiquetter = 1;
            }
            let lieuId = this.$route.params.idLieu;
            if(!lieuId){
                // selectionne un lieu avant voir selecteur lieu DI ( si récupérable )
                this.step = 'selectLieu';
            }else{
                this.fetch().then(()=>{
                    if(!this.$vgutils.isMobile()){
                        this.skipScanQrCode();
                    }
                });
            }

        },
        computed:{
            ...mapGetters({
                lieu: 'LieuxStore/getSelectedItem',
                getEquipementsInLastPlaceVisited:'getEquipementsInLastPlaceVisited',
                categorieMarques: "CategoriesStore/getSelectedCategorieMarques"
            }),
            getTitle: function(){
                if(this.step == "scanCode") return this.$t("recensement-scan-titre")+" "+this.$t("step-1-quel-qrcode");
                else if(this.step == "createEntityEquipement" && !this.selectedCategorie) return this.$t("recensement-scan-titre")+" "+this.$t("step-2-quelle-categorie");
                else if(this.step == "createEntityEquipement" && this.selectedCategorie) return this.$t("recensement-scan-titre")+" "+this.$t("step-3-quelles-proprietes");
                else if(this.step == "feedbackSuccess") return this.$t("recensement-scan-titre")+" "+this.$t("step-4-confirmation");
                else return this.$t("recensement-scan-titre");
            },
            getHeaderColor:function(){
                return this.$vgutils.isMobile()? this.$root.vgPurple : "white";
            }
        },
        methods: {
            onInputSearchPiece: function(input){
                this.agfiltersPieces.libel_lieu.value = input;
                this.agfiltersPieces.codeTrois.value = input;
                this.agfiltersPieces.codeDeux.value = input;
                this.agfiltersPieces.servicetext.value = input;
            },
            onChangeCategorie: function(){
                this.selectedCategorie = null;
                this.equipement.idCategorie = null;
                this.$store.dispatch("CategoriesStore/deleteSelectedItem");
            },
            onAddCategorieMarque: function(marque){
                this.equipement.marque = marque;
                this.showAddMarque = false;
            },
            fetch:function(){
                return new Promise((resolve,reject)=>{
                    let lieuId = this.$route.params.idLieu;
                    let categorieId = this.$route.params.idCategorie;
                    this.LieuMixins_getLieu(lieuId).then((lieu)=>{
                        this.equipement.idLieu = lieu.id;
                        this.equipement.idCategorie = categorieId ? categorieId : null ;
                        resolve();
                    });
                })
            },
            skipScanQrCode:function(){
                let code = null;
                this.goToCreateEquipmentStep(code);
            },
            /**
             * Fetch from storage last five categories created
             * if the storage lastFiveCategoriesUsed doesnt exist
             * it initialized it to [].
             */
            fetchLastFiveEquipments:function(){
                let lastFiveCategoriesUsed = this.$storage.get('lastFiveCategoriesUsed');
                if(!lastFiveCategoriesUsed) {
                    lastFiveCategoriesUsed = [];
                    this.$storage.set('lastFiveCategoriesUsed',[])
                }
                return lastFiveCategoriesUsed.filter(categorie => categorie.userId == this.$app.appId);
            },

            /**
             * addCategorieRecenseInStorage
             *
             * @param  {object} categorie description
             * @return {array} same values as datasStorage  lastFiveCategoriesUsed
             */
            addCategorieRecenseInStorage:function(categorie){
                let lastFiveCategoriesUsed = this.fetchLastFiveEquipments();
                let foundCategorieIndex = lastFiveCategoriesUsed.findIndex((element) => element.id == categorie.id);
                const _MAX_CATEGORIES_ALLOWED = 5;
                if(foundCategorieIndex != -1){
                    lastFiveCategoriesUsed.splice(foundCategorieIndex,1);
                }
                if(lastFiveCategoriesUsed.length >= _MAX_CATEGORIES_ALLOWED ){
                    lastFiveCategoriesUsed.pop();
                }
                lastFiveCategoriesUsed.unshift(categorie);
                this.$storage.set('lastFiveCategoriesUsed',lastFiveCategoriesUsed);
                return lastFiveCategoriesUsed;
            },

            onSelectCategorie(categorie){
                this.updateEquipement(categorie);
                this.$store.dispatch("CategoriesStore/setSelectedItem", categorie);
                this.equipement.valeurAchat = categorie.prixDefault;
                this.completeForm = true;
            },
            /**
             * handleSetCategorieShortcut.
             *
             * @param  {object} categorie description
             */
            handleSetCategorieShortcut:function(categorie){
                this.selectedCategorie = categorie;
                this.updateEquipement(categorie);
                this.$store.dispatch("CategoriesStore/setSelectedItem", categorie);
            },
            /**
             * handleClickLieu -
             *
             * @param  {object} lieu lieu venant de vg-tree-lieu
             */
            handleClickLieu:function(lieu){
                this.$router.push({ name: '_recensement_equipement_lieu', params: { idLieu: lieu.id } });
            },
            handleRecenserNewEquipement:function(){
				this.uid = null;
                this.libelEquipement = "";
                this.equipement.idCategorie = null;
                this.selectedCategorie = null;
                this.step = 'scanCode';
            },
            /**
             * @param object categorie
             */
            handleSavedCategorie:function(categorie){
                this.selectedCategorie = categorie;
                this.updateEquipement(categorie);
                this.$store.dispatch("CategoriesStore/setSelectedItem", categorie);
                this.showCategorieCreate = false;
            },
			handleRecenserAnotherOne:function(){
                this.equipement = Object.assign(this.equipement, {
                    qrCode:'',
                    caracTechniques:null,
                    numSerie:null,
                    numeroImmobilisation:null,
                    refConstructeur:null,
                    champOptionel:null,
                    marque:null,
                    typeCategorie:null,
                    valeurAchat:null,
                    fournisseur_id:null,
                    dateAchat:null,
                    type:"Asset",
                    etat:"Good",
                    userId : this.$app.appID,
                    miseEnService: null
                });
                this.uid = null;
                this.libelEquipement = "";
                this.step = 'scanCode';
            },
			handleRecenserInAnotherPiece:function(){
                this.$router.push({ name: '_lieux' });
            },
            goBack: function() {
                this.persistedEquipment.length ? this.localListEquipements.unshift(this.persistedEquipment) : null;
                if (this.step == 'scanCode') {
                    this.$router.go(-1);
                } else if (this.step == 'createEntityEquipement') {
                    this.step = 'scanCode';
                } else if (this.step == 'feedbackSuccess') {
                    this.step = 'scanCode';
                }
            },
            updateEquipement:function(categorie){
                this.equipement.idCategorie = categorie.id;
                //this.equipement.libelEquipement = categorie.libelleCatgorie;
                this.equipement.marque = null;
            },
            createEquipment:function(){
                //var categorie = this.categories.filter(current => current.id == this.equipement.idCategorie)[0];
                this.equipement.libel_equipement = this.selectedCategorie.libelleCatgorie+ " " +this.libelEquipement;
                this.fullscreenLoading = true;
                // TODO attention ça sent le hack la dessous il va falloir gérer cette horreur bientôt
                this.equipement.idLieu_id = this.equipement.idLieu;
                this.equipement.idCategorie_id = this.equipement.idCategorie;
                this.equipement.carac_techniques = this.equipement.caracTechniques;
                this.equipement.marker_id = this.equipement.marker;
                this.equipement.num_serie = this.equipement.numSerie;
                this.equipement.dateFabrication = this.equipement.dateAchat;
                delete this.equipement.numSerie;
                delete this.equipement.marker;
                delete this.equipement.caracTechniques;
                delete this.equipement.libelEquipement;
                delete this.equipement.idLieu;
                delete this.equipement.idCategorie;
                delete this.equipement.dateAchat;
                delete this.equipement.fournisseur;
                this.equipementsMixins_createEquipements([this.equipement]).then((reponses)=>{
                    var reponse = reponses[0];
                    this.uid = reponse.uid;
                    this.step = 'feedbackSuccess';
                    this.equipement.idLieu = this.lieu.id;
                    this.equipement.idCategorie = reponse.idCategorie_id;
                    this.lastFiveCategoriesCreated = this.addCategorieRecenseInStorage(this.selectedCategorie);
                    reponse.libelEquipement = reponse.libel_equipement;
                    reponse.idLieu = reponse.idLieu_id;
                    reponse.idCategorie = reponse.idCategorie_id;
                    reponse.caracTechniques = reponse.carac_techniques;
                    reponse.marker =reponse.marker_id;
                    reponse.numSerie = reponse.num_serie;
                    delete reponse.numSerie;
                    delete reponse.marker;
                    delete reponse.caracTechniques;
                    delete reponse.libelEquipement;
                    delete reponse.idLieu;
                    delete reponse.idCategorie;

                    this.persistedEquipment = reponse;
                    this.VgFilesMixins_init(this.uid, "equipement");
                    this.fullscreenLoading = false;
                    this.showModalUploadPhoto = true;
                });
                return;
            },
            goToCreateEquipmentStep:function(code){
                var audio = new Audio('/static/assets/sounds/beep-07.wav');
                audio.play();
                this.code = code;
                this.equipement.qrCode = this.code;
                this.step = 'createEntityEquipement';
            },
            handlScanSuccess:function(code){
                let metadatas = new Metadatas();
                metadatas.setFilters({
                    qrCode:{"attr":"e.qrCode","colId":"e.qrCode","value":code,"action":"equals"}
                });
                this.equipementsMixins_getEquipements(metadatas).then((equipements)=>{
                    if(equipements.datas.length > 0){
                        alert(this.$t('code-already-taken'));
                    }else{
                        this.equipement.etiquetter = 1;
                        this.goToCreateEquipmentStep(code);
                    }
                });
            },
            /**
             * handleScanCode.
             *
             * @param  {string} scanResult
             */
            handleScanCode:function(scanResult){
                if(this.isScannerNumSerieDisplayed){
                    this.equipement.numSerie = scanResult;
                    this.isScannerNumSerieDisplayed = false;
                }else if(this.isScannerRefConstructeurDisplayed){
                    this.equipement.refConstructeur = scanResult;
                    this.isScannerRefConstructeurDisplayed = false;
                }
            },
            handleUploadDone:function(){
                this.showModalUploadPhoto = false;
                this.VgFilesMixins_fetchFilesByUid();
            },
            handleRetour: function() {
                if (this.step === "scanCode" /*|| this.step === "feedbackSuccess"*/) {
                    this.$router.go(-1);
                } else {
                    this.step = "scanCode";
                }
            }
        }
    };
</script>
<style lang="scss" scoped >
.marginUnderInput{
    margin-bottom:20px
}
#action-buttons > .vg-button{
    margin-bottom:15px;
}
#libelle-categorie-selectionne{
    width: 50%;
    background-color: #f2f2f2;
    border: 1px solid #d6d1ce;
    padding: 4px 10px;
    font-weight: bold;
}
#last-five-categories-bloc{
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
