<template>
    <vg-app-layout
        :title="$t('page-title')"
        :icon="'inventaire.png'"
        :colorheader="getHeaderBackgroundColor"
        :activeFilters="activeFilters"
        :filtersTitle="$t('modal-filters-title')"
        @remove-filters="onRemoveFilters"
        @action-export="actionExport">
        <!-- DEBUT DESKTOP -->
        <template #search>
            <vg-text-filter
                v-model="searchQuery" />
        </template>
        <template #header-bottom-left>
            <vg-tabs
                :color="'gris'"
                :tabs="tabs"
                @tabClicked="filterByStatut"/>
        </template>
        <!-- btn créer inventaire apparait uniquement si pas mobile  -->
        <template #create-button>
            <vg-button
                v-if="!$vgutils.isMobile()"
                type="success"
                :disabled="!isCreateInventaireDisabled"
                @click="isCreateInventaireModalOpened=true">
                {{$t("creer-inventaire")}}
            </vg-button>
        </template>
        <!-- FIN DESKTOP -->

        <!-- @TODO faire composant vg-inventaires-table -->
        <template #primary>
            <tag-grid
                :idTableau="'tableau-inventaires'"
                v-model="gridOptions"
                :columnDefs="columnDefs"
                :rowData="inventaires"
                @ag-click="selectInventaire">
            </tag-grid>
        </template>
        <template #secondary-filters>
            <vg-filter-site
               v-model="agfilters.path.value">
            </vg-filter-site>
        </template>
        <template #secondary-widget>
            <vg-tree-lieux
                v-model="agfilters.path.value" />
        </template>

        <template #panel-filters-localisation>
            <vg-filter-site
               v-model="agfilters.path.value">
           </vg-filter-site>
        </template>
        <template #panel-widget-lieux>
            <vg-tree-lieux
                v-model="agfilters.path.value" />
        </template>

        <vg-inventaire-create-update-form v-if="isCreateInventaireModalOpened"
            @close="isCreateInventaireModalOpened = false"
            @create="createInventaire" />
    </vg-app-layout>
</template>

<script>
import TagGrid from 'src/components/Grid/TagGrid.vue';

import VgInventaireCreateUpdateForm from "src/components/Vg/Inventaire/VgInventaireCreateUpdateForm.vue";
import InventaireLieuCellRender from "src/components/Vg/TagGrid/InventaireLieuCellRender.vue";
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import TagGridMixins from 'src/mixins/TagGridMixins.js';
import LieuMixins from 'src/mixins/LieuMixins.js';
import InventaireMixins from "src/mixins/InventaireMixins.js";
import VgButton from 'src/components/Vg/VgButton.vue';
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";

const ICON_DESKTOP = "fil.png";
const ICON_MOBILE = "fil_blanc.png";
const HEADER_BACKGROUND_COLOR_MOBILE = "rgb(66,159,255)";
const INVENTAIRES = [
        {
            "inventaire_id": "51",
            "numeroInventaire": "1",
            "dateDebut": "2019-04-15",
            "dateFin": "2019-08-15",
            "commentaire": "teste",
            "name": "teste Stock",
            "servicesLieu": "Administration,Restauration",
            "created_at": "2019-04-15 00:00:00",
            "updated_at": "2019-04-15 00:00:00",
            "statut": "Cloturer",
            "path": "Txtony_brasseur/Brasserie/Possession 1/RDC/Stock",
            "totalEquipement": "11",
            "totalInventorie": "0"
        },
        {
            "inventaire_id": "91",
            "numeroInventaire": "6",
            "dateDebut": "2019-04-16",
            "dateFin": "0000-00-00",
            "commentaire": "Bon",
            "name": "inventaire teste api",
            "servicesLieu": "",
            "created_at": "2019-04-16 00:00:00",
            "updated_at": "2019-04-16 00:00:00",
            "statut": "Cloturer",
            "path": "Txtony_brasseur/Brasserie",
            "totalEquipement": "0",
            "totalInventorie": "0"
        }
    ];

import { mapGetters } from 'vuex';
import Metadatas from "src/services/Metadatas.js";

export default {
    name: "vg-inventaires",
    components: {
        TagGrid,

        VgTextFilter,
        VgTabs,
        VgButton,
        VgInventaireCreateUpdateForm,
        InventaireLieuCellRender,
        VgTreeLieux,
        VgFilterSite
    },
    mixins: [
        TagGridMixins,
        LieuMixins,
        InventaireMixins
    ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "id": "id",
            "name": "Nom",
            "total-equipement": "Nombre total équipement",
            "total-inventorier": "Total inventorié",
            "date-debut": "Date de début",
            "date-fin": "Date de fin",
            "commentaire": "Commentaire",
            "emplacement": "Emplacement",
            "service": "Service",
            "statut": "Statut",
            "creer-inventaire": "Créer inventaire",
            "page-title": "Inventaires équipements",
            "numero": "Numéro",
            "modal-filters-title": "Filtrer les inventaires"
        },
        "en": {
            "id": "id",
            "name": "Nom",
            "total-equipement": "Nombre total équipement",
            "total-inventorier": "Total inventorié",
            "date-debut": "Date de début",
            "date-fin": "Date de fin",
            "commentaire": "Commentaire",
            "emplacement": "Emplacement",
            "service": "Service",
            "statut": "Statut",
            "creer-inventaire": "Create inventory",
            "page-title": "Assets tracking",
            "numero": "Number",
            "modal-filters-title": "Filter assets tracking"
        },
        "th": {
            "id": "id",
            "name": "จำนวน",
            "total-equipement": "จำนวนอุปกรณ์ทั้งหมด",
            "total-inventorier": "สินค้าคงคลังทั้งหมด",
            "date-debut": "วันที่เริ่มต้น",
            "date-fin": "วันที่สิ้นสุด",
            "commentaire": "ความคิดเห็น",
            "emplacement": "ดำแหน่ง",
            "service": "บริการ",
            "statut": "สถานะ",
            "creer-inventaire": "สร้างสินค้าคงคลัง"
        }
    }
},
    data: function() {
        return {
            isCreateInventaireModalOpened:false,
            isScannerOpened:false,
            columnDefs: [
                {
                    headerName: this.$t('id'),
                    field: "inventaire_id",
                    hide: false,
                },
                {
                    headerName: this.$t('numero'),
                    field: "numeroInventaire",
                    hide: false,
                },
                {
                    headerName: this.$t('name'),
                    field: "name",
                    cellClass: "vg-important-cell",
                    hide: false,
                },
                {
                    headerName: this.$t('total-equipement'),
                    field: "totalEquipement",
                    hide: false,
                },
                {
                    headerName: this.$t('total-inventorier'),
                    field: "totalInventorie",
                    hide: false,
                },
                {
                    headerName: this.$t('date-debut'),
                    field: "dateDebut",
                    hide: false,
                },
                {
                    headerName: this.$t('date-fin'),
                    field: "dateFin",
                    hide: false,
                },
                {
                    headerName: this.$t('commentaire'),
                    field: "commentaire",
                    hide: false,
                },
                {
                    headerName: this.$t('emplacement'),
                    field: "path",
                    hide: false,
                },
                {
                    headerName: this.$t('service'),
                    field: "servicesLieu",
                    hide: false,
                },
                {
                    headerName: this.$t('statut'),
                    field: "statut",
                    hide: false,
                },
            ],

            gridOptions: {
                enableColResize: false,
                enableSorting: false,
                enableFilter: false,
                suppressDragLeaveHidesColumns: true,
                suppressMovableColumns: true
            },

            searchQuery: '',
            tabs:[
                {
                    label: "Tous",
                    name:"tous",
                    //counter: 0,
                    filters:[{colId: "statut", value: null}]
                },
                {
                    label: "Cloturer",
                    name:"cloturer",
                    //counter: 0,
                    filters:[{colId: "statut", value: "Cloturer"}]
                },
                {
                    label: "En_cours",
                    name:"en_cours",
                    //counter: 0,
                    filters:[{colId: "statut", value: "En_cours"}]
                }
            ],
            metadatas: new Metadatas(),
            agfilters: {
                path: {attr: "path", value: null, action: "equals"}
            }
        }
    },
    watch: {
        searchQuery: {
            handler: function(query){
                this.TagGridMixins_setQuickFilter(this.gridOptions, query);
            }
        },
        agfilters: {
            handler: function(newfilters){
                // @WARNING fetch n'utilise pas de metadatas => filtrage en local
                this.TagGridMixins_setQuickFilter(this.gridOptions, newfilters.path.value);
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters({
            inventaires: 'InventairesStore/getCollection'
        }),
        pageFilters: function(){
            return ["path"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
            let nFilters = 0;
            if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
            return activeFilters.length + nFilters;
        },
        isCreateInventaireDisabled:function(){
            let hasOngoingInventaire = this.inventaires.filter(
                (inventaire) => {
                    return inventaire.statut == "En_cours";
                }
            ).length > 0;
            let hasNoInventaire = this.inventaires.length == 0;
            return hasOngoingInventaire || hasNoInventaire;
        },
        getHeaderBackgroundColor: function(){
            return this.$vgutils.isMobile() ? HEADER_BACKGROUND_COLOR_MOBILE : "";
        },
    },
    methods: {
        setColumnDefs:function(){
            if(this.$vgutils.isMobile()){
                this.columnDefs.map((columnDef)=>{
                    columnDef.hide = true;
                })
                this.columnDefs.unshift({
					cellClass: ["vg-cell-mobile-card"],
                    cellRenderer: (params) => new InventaireLieuCellRender({
                        propsData: {
                            inventaire: params.data
                        }
                    }).$mount().$el
				});
            }
        },
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.agfilters[filterName].value = false;
            });
            this.searchQuery = null;
        },
        actionExport: function(event){
            window.dispatchEvent(new CustomEvent(event.name, {
                "detail": {"idTableau":"tableau-inventaires"}
            }));
        },
        filterByStatut:function(e){
            // //console.log(e.filters);
            this.TagGridMixins_setFilters(this.gridOptions, e.filters);
        },
        createInventaire:function(inventaire){
            this.InventaireMixins_create( inventaire ).then( ( receivedInventaire ) => {
                this.fetch();
                this.isCreateInventaireModalOpened = false;
            }).catch((err) => {
                alert(err);
            });
        },
        selectInventaire: function(row){
            // this.InventaireMixins_fetchById(row.data.inventaire_id);
            this.$router.push({ name: '_vg_inventaire_id', params: { id: row.data.inventaire_id }});
        },
        fetch: function(){
            //this.metadatas.setFilters(this.agfilters);
            this.InventaireMixins_fetchAll(this.metadatas);
        },
    },
    created: function() {
        this.setColumnDefs();
        this.fetch();
    }
}
</script>

<style lang="scss">
</style>
