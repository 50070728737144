<template lang="html">
	<div v-if="thumbnail">
		<div v-if="clickable" >
			<img v-if="thumbnail" :src="thumbnail" :alt="file.name" :height="heightImg" class="img-clickable" @click="displayFile = true"/>
		</div>
		<img v-else :src="thumbnail" :alt="file.name" :height="heightImg" @click="displayFile = true" />
		<vg-file-viewer v-if="displayFile" :file="loadedFileInViewer" @close="displayFile = false"/>

	</div>
	<div v-else class="no-file">
		<i class="far fa-2x fa-image"></i>
	</div>
</template>
<script>
	import VgFileViewer from 'src/components/Vg/Files/VgFileViewer.vue';
    export default {
        name: 'vg-files-thumbnail',
	    components: {
			VgFileViewer
        },
		filters: {

		},
		mixins:[

        ],
        props: {
			/**
			* objet representant l'image
			* {uploadedTo: ..., name: ..., type:...}
			*/
			file: {
				type: Object,
				required: true
			},
			heightImg:{
				type:String,
				default:"auto"
			},
			clickable:{
				type: Boolean,
				default: true
			}
        },
        data: function() {
            return {
				thumbnail: null,
				original: null,
				loadedFileInViewer : null,
				displayFile: false
			};
        },
		watch: {
			/**
			 * utilisé pour le viewer photo > image precendente/suivante
			 */
			file: function(f){
				this.thumbnail = null;
				if (f.name || f.name != null) {
					this.callLinks(f);
				}
			}
		},
		filters:{

		},
		created: function(){
			if (this.file.name || this.file.name != null) {
				this.callLinks(this.file);
			}
		},
        mounted: function(){

        },
        methods: {
			getFile(fileLink){
				fetch(fileLink).then((response)=>{
					response.blob().then((blob) => {
						this.loadedFileInViewer = new File([blob], "photo-thumbnail", { type: blob.type });
					});
				})
			},
			callLinks: function(file){
				this.VgFilesMixins_getThumbnailLink(file, true).then((link)=> {
					this.thumbnail = link;
				}).catch(()=>{
					this.VgFilesMixins_getDownloadFileLink(file).then((link)=>{
						this.thumbnail = link;
					});
				});
				this.VgFilesMixins_getDownloadFileLink(file).then((link)=> {
					this.original = link
					this.getFile(link);
				});
			},
			handleClickImg: function(e){
				window.open(this.original, '_blank');
			}
		},
		computed: {

		}
	};
</script>

<style lang="scss" scoped>
.img-clickable{
	cursor: pointer;
}
.img-clickable:hover{
	border: 1px solid whitesmoke;
}
.no-file{
	color: #e0e0e1;
}
@media print {
	a{
		color: white;
	}
	a[href]:after{
		content: "" !important;
	}
}

</style>
