<template>
    <div class="vg-consommable-etiquette-selector">
        <vg-input v-if="filtreAsInput" 
            :title="$t('filtre-etiquette')">
            <div style="display: flex;justify-content: flex-start;align-items: center;gap: 5px;">
                <span v-if="selectedEtiquette">
                    {{ selectedEtiquette }}
                </span>
                <vg-button 
                    type="grey" size="sm" 
                    @click="showSelecteurEtiquette=true;">
                    {{ selectedEtiquette?$t("modifier-filtre-etiquette"):$t("filtrer-par-etiquette") }}
                </vg-button>
            </div>
        </vg-input>
        <span v-else-if="!filtreAsInput && selectedEtiquette">
            {{$t("filtre-etiquette")}}: {{ selectedEtiquette }}
        </span>
        <vg-button v-if="!filtreAsInput" 
            type="grey" size="sm" 
            @click="showSelecteurEtiquette=true;">
            {{ selectedEtiquette?$t("modifier-filtre-etiquette"):$t("filtrer-par-etiquette") }}
        </vg-button>
        <vg-modal v-if="showSelecteurEtiquette" 
            :title="$t('select-etiquette')"
            @close="close()">
            <template #body>
                <vg-tree-consommables @select-node="onSelectNodeEtiquette"/>
            </template>
            <template #footer>
                <vg-button type="default-success" @click="retirerFiltre()">{{ $t("retirer-filtre") }}</vg-button>
                <vg-button type="success" @click="close()">{{ $t("filtrer") }}</vg-button>
            </template>
        </vg-modal>
    </div>
</template>
<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgModal from "src/components/Vg/VgModal.vue";
import VgTreeConsommables from "src/components/Vg/Consommables/VgTreeConsommables.vue";
export default {
    name: 'vg-consommable-etiquette-selector',
    mixins:[],
	components: {
		VgSelect,
        VgInput,
        VgButton,
        VgModal,
        VgTreeConsommables
	},
    props: {
		/**
        * @model
        */
        value: {
            type: String,
            default: null
        },
		/**
        * Filters used for query {"attr":"type_lieu","value":"Site","action":"equals"}.
        */
        filters: {
            type: Object,
            default: ()=>{
                return {};
            }
        },
        filtreAsInput: {
            type: Boolean,
            default: false
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "filtre-etiquette": "Filtre étiquette",
            "filtrer-par-etiquette": "Filtrer par étiquette",
            "modifier-filtre-etiquette": "Modifier filtre étiquette",
            "select-etiquette": "Sélectionner une étiquette",
            "close": "Fermer",
            "filtrer": "Filtrer",
            "retirer-filtre": "Retirer le filtre"
        },
        "en": {
            "filtre-etiquette": "Filter by tag",
            "filtrer-par-etiquette": "Filter by tag",
            "modifier-filtre-etiquette": "Edit tag filter",
            "select-etiquette": "Select a tag",
            "close": "Close",
            "filtrer": "Filter",
            "retirer-filtre": "Remove filter"
        }
    }
},
    data: function() {
        return {
            selectedEtiquette: this.value || null,
            showSelecteurEtiquette: false
        };
    },
    watch:{
        
    },
	created: function(){
        
	},
    methods:{
        onSelectNodeEtiquette: function(node){
            this.selectedEtiquette = node.location;
            this.$emit("input", this.selectedEtiquette);
            this.$emit("change", this.selectedEtiquette);
        },
        retirerFiltre: function(){
            this.selectedEtiquette = null;
            this.$emit("input", this.selectedEtiquette);
            this.$emit("change", this.selectedEtiquette);
            this.showSelecteurEtiquette = false;
        },
        close: function() {
            this.showSelecteurEtiquette = false;
        }
    },
    computed:{
        
    }
};

</script>
<style lang="scss" scoped>
.vg-consommable-etiquette-selector{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:5px;
}
</style>