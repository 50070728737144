<template>
    <div class="vg-etages-table">
      <tag-grid v-if="!isDemandeIntervention"
        v-model="gridOptions"
        :idTableau="'vg-etages-tableau'"
        :columnDefs="columnDefs"
        :rowData="etages"
        :showLoadingOverlay="showLoadingOverlay"
        :overlayLoadingText="$t('overlay-loading-text')"
        :overlayNoRowsText="$t('overlay-no-rows-text')"
        @ag-click="handleClick"
        @ag-dbl-click="handleDblClick"
      />
      <div v-else class="vg-etages-demande-intervention">
          <div v-if="showLoadingOverlay" class="loading-overlay">
              <span class="text">{{$t("overlay-loading-text")}}</span>
          </div>
          <div v-if="!counters || counters.filtered==0" class="loading-overlay">
              <span class="text">{{$t("overlay-no-rows-text")}}</span>
          </div>
          <vg-collapse v-for="(site, index) in getEtagesClassesParSites"
                :title="site.label"
                type="section"
                :collapseByDefault="false"
                :killContentOnCollapse="false"
                :key="'site'+index"
                style="margin-bottom: 10px;">
                <template #content>
                    <etage-mobile-cell-render v-for="(etage, index) in site.etages" :key="etage.id"
                        :params="{data: etage}"/>
                </template>
          </vg-collapse>
      </div>
      <vg-files-plans v-if="showTiroirPlans"
          :defaultFilters="plansEtageFilters"
          :etage="focusedEtage"
          @close="onCloseTiroirPlans"
          @after-add-plan="onAfterAddPlan">
      </vg-files-plans>
      <vg-pagination v-if="paginate"
          :totalItems="counters?counters.etages:0"
          :offset="offset"
          :limit="limit"
          @pagination-change="handlePaginationChange"
      />
    </div>
</template>
<script>
import LieuMixins from "src/mixins/LieuMixins";
import TagGrid from 'src/components/Grid/TagGrid.vue';

import LibelLieuCellRender from "src/components/Vg/TagGrid/LibelLieuCellRender.vue";
import PathCellRender from "src/components/Vg/TagGrid/PathCellRender.vue";
import ButtonCellRender from "src/components/Vg/TagGrid/ButtonCellRender.vue";
import PlanInteractifCellRender from "src/components/Vg/TagGrid/PlanInteractifCellRender.vue";
import PlanInteractifGeoJsonPiecesCellRender from "src/components/Vg/TagGrid/PlanInteractifGeoJsonPiecesCellRender.vue";
import EtageMobileCellRender from "src/components/Vg/TagGrid/EtageMobileCellRender.vue";

import VgFilesPlans from "src/components/Vg/Files/VgFilesPlans.vue";
import VgPagination from "src/components/Vg/VgPagination.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";

import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-etages-table',
    components:{
        TagGrid,
        LibelLieuCellRender,
        PathCellRender,
        ButtonCellRender,
        PlanInteractifCellRender,
        PlanInteractifGeoJsonPiecesCellRender,
        EtageMobileCellRender,
        VgFilesPlans,
        VgPagination,
        VgCollapse
    },
    mixins:[LieuMixins],
    props: {
        /**
        * liste des columns à hide (column colId)
        */
        hiddenColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns pined left (column colId)
        */
        pinnedColumns: {
            type: Array,
            default: function(){
                return ["id", "commentaireClient", "piece"];
            }
        },
        /**
        * liste des columns non showable (column colId)
        */
        notShowableColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * affiche le tableau en mode impression
        * default false
        */
        showPrint : {
            type: Boolean,
            default: false
        },
        /**
        * recherche
        */
        searchQuery: {
            type: String,
            default: ""
        },
        /**
        * filtres externes appliqué sur le tableau au fetch
        */
        filters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * filtres externes appliqué sur le tableau en local
        */
        localFilters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * titre rapport impression
        */
        reportTitle: {
            type: String,
            default: null
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: true
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        },
        /**
        * applique le rendu du tableau pour la page DI
        */
        isDemandeIntervention: {
            type: Boolean,
            default: false
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
     "fr": {
        "overlay-loading-text": "Chargement des étages...",
		"overlay-no-rows-text": "Aucun étage correspondant au filtrage",
        "header-libelle": "Libellé",
        "header-localisation": "Localisation",
        "header-plans-pdf": "Plans PDF",
        "header-plan-interactif": "Plan interactif",
        "header-plan-interactif-markers-pieces-draft": "Brouillon enregistré",
        "header-plan-interactif-markers-pieces": "Pièces positionnées",
        "button-plans-pdf": "Voir plans PDF",
        "button-add-plans-pdf": "Ajouter un plan PDF"
    },
    "en": {
        "overlay-loading-text": "Loading floors...",
		"overlay-no-rows-text": "No floor matching filtering",
        "header-libelle": "Tag",
        "header-localisation": "Location",
        "header-plans-pdf": "PDF floor plans",
        "header-plan-interactif": "Interactive floor level plan",
        "header-plan-interactif-markers-pieces-draft": "Saved draft",
        "header-plan-interactif-markers-pieces": "Positioned parts",
        "button-plans-pdf": "See floor plans",
        "button-add-plans-pdf": "Add PDF floor plan"
        }
    }
},
    data: function() {
        return {
            showLoadingOverlay: false,
            gridOptions: {
                headerHeight: this.$vgutils.isMobile()?0:40,
                rowHeight: this.$vgutils.isMobile()?84:40
            },
            columnDefs: [
                {
                    headerName: this.$t("header-libelle"),
                    width: 360,
                    hide: this.hiddenColumns.includes("libel_lieu"),
                    field: 'libel_lieu',
                    colId: 'libel_lieu',
                    tooltipField: 'path',
                    cellClass: ["vg-cell-ballam-theme", "vg-important-cell"],
                    cellStyle: {
                        fontWeight: 'bold'
                    },
                    cellRenderer: (params) => new LibelLieuCellRender({
                        propsData: {
                            icon: "static/assets/icone/circle/etage-cercle.png",
                            params: params
                        }
                    }).$mount().$el
                },
                {
                    headerName: this.$t("header-localisation"),
                    width: 360,
                    field: 'path',
                    colId: 'path',
                    tooltipField: 'path',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new PathCellRender({propsData: {path: params.data.path,onlyPath:1}}).$mount().$el
                },
                {
                    headerName: this.$t("header-plan-interactif"),
                    width: 200,
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new PlanInteractifCellRender({
                        propsData: {
                            params: params,
                            exist: params.data.planInteractif,
                            tagFondDePlan: this.VgFilesMixins_getTagFondDePlan
                        }
                    })
                    .$on("click", this.onClickPlanInteractif)
                    .$on("upload-fond-de-plan-done", this.onUploadFondDePlanDone)
                    .$on("upload-fond-de-plan-begin", this.onUploadFondDePlanBegin)
                    .$mount().$el
                },
                {
                    headerName: this.$t("header-plan-interactif-markers-pieces-draft"),
                    width: 160,
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new PlanInteractifGeoJsonPiecesCellRender({
                        propsData: {
                            params: params,
                            draft: true
                        }
                    })
                    .$mount().$el
                },
                {
                    headerName: this.$t("header-plan-interactif-markers-pieces"),
                    width: 160,
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new PlanInteractifGeoJsonPiecesCellRender({
                        propsData: {
                            params: params,
                            draft: false
                        }
                    })
                    .$mount().$el
                },
                {
                    headerName: this.$t("header-plans-pdf"),
                    width: 160,
                    hide:this.hiddenColumns.includes("plan_pdf_button"),
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new ButtonCellRender({
                        propsData: {
                            label: params.data.nbPlans?this.$t("button-plans-pdf")+this.getNbPlans(params.data):this.$t("button-add-plans-pdf"),
                            params: params
                        }
                    })
                    .$on("click", this.onClickButtonShowPlan)
                    .$mount().$el
                },
            ],
            metadatas: new Metadatas(),
            showTiroirPlans: false,
            plansEtageFilters: null,
            focusedEtage: null,
            etages: []
        };
    },
    computed: {
        ...mapGetters({
            counters: "LieuxStore/getCounters"
        }),
        getEtagesClassesParSites: function(){
            let etagesAvecPlanInteractif = this.etages.filter((etage)=>etage.hasOwnProperty("planInteractif") && etage["planInteractif"]);
            let etagesClassesParSites = [];
            if(this.searchQuery && this.searchQuery.length!=0) etagesAvecPlanInteractif = etagesAvecPlanInteractif.filter((etage)=>etage.libel_lieu.toLowerCase().search(this.searchQuery.toLowerCase())!=-1);
            let site = null;
            let indexSite = -1;
            etagesAvecPlanInteractif.forEach((etage)=>{
                console.log("ETAGE", etage);
                site = etage.path.split("/")[1];
                indexSite = etagesClassesParSites.findIndex((s)=>s.label==site);
                if(indexSite==-1){
                    etagesClassesParSites.push({
                        label: site,
                        etages: [etage]
                    });
                }else{
                    etagesClassesParSites[indexSite].etages.push(etage);
                }
            });
            return etagesClassesParSites;
        }
    },
    watch: {
        searchQuery: {
            handler: function(query){
                if(!this.isDemandeIntervention) this.TagGridMixins_setQuickFilter(this.gridOptions, query);
            }
        },
        filters: {
            handler: function(newfilters){
                this.fetch();
            },
            deep: true
        },
        localFilters: {
            handler: function(newfilters){
                // //console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
                //if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
            },
            deep: true
        }
    },
    created:function(){
        if (this.$vgutils.isMobile()) {
            this.columnDefs = this.transformColumnDefsForMobile(this.columnDefs);
        }
        if(this.paginate) this.metadatas.setLimit(this.offset, this.limit);
        this.fetch();
        this.VgFilesMixins_init(null, "plan-interactif");
    },
    methods:{
        onUploadFondDePlanBegin: function(){
            this.showLoadingOverlay = true;
            this.$emit("show-loading-overlay", true);
        },
        onUploadFondDePlanDone: function(){
            this.$emit("show-loading-overlay", false);
            this.fetch();
        },
        onAfterAddPlan: function(){
            this.fetch();
        },
        onCloseTiroirPlans: function(){
            this.showTiroirPlans = false;
            this.plansEtageFilters = null;
            this.focusedEtage = null;
        },
        getNbPlans: function(etage){
            if(!etage.nbPlans) return "";
            else return " ( "+etage.nbPlans+" )";
        },
        onClickButtonShowPlan: function(row){
            this.plansEtageFilters = {Etage: row.data.path, lieu: row.data};
            this.focusedEtage = row.data;
            this.showTiroirPlans = true;
        },
        onClickPlanInteractif: function(row){
            this.$router.push({ name: "_plan_interactif_etage", params: { etage: row.data.id } });
        },
        /**
        *
        * @param {array} columnDefs before transformation
        * @return {array} columnDefs all column are hide except the first one with the cell render
        */
        transformColumnDefsForMobile:function(columnDefs){
            columnDefs.forEach((columnDef, i) => {
                columnDef.hide = true;
            });
            columnDefs.unshift({
                getQuickFilterText: (params) => params.data.path + " " + params.data.codeTrois + " " + params.data.codeDeux,
                cellRenderer: (params) => new LieuMobileCellRender({
                    propsData: {
                        params: params
                    }
                }).$mount().$el,
                cellClass: ["vg-cell-mobile-card"]
            });
            return columnDefs;
        },
        fetch: function(){
            this.showLoadingOverlay = true;
            let filters = Object.assign({}, this.filters, {
                type_lieu: {attr: "l.type_lieu", value: "Etage", action: "equals"}
            });
            this.metadatas.setFilters(filters);
            this.LieuMixins_getLieux(this.metadatas, {_stored_counters: true}).then((datas)=>{
                this.etages = datas.lieux;
                this.showLoadingOverlay = false;
            });
        },
        /**
        * Emit.
        *
        * @param Object row
        * @event row-click
        */
        handleClick:function(row){
            this.$emit("row-click",row);
        },
        /**
        * Emit row-dbl-click on dblClick row.
        *
        * @param {Object} row
        * @event row-dbl-click
        */
        handleDblClick:function(row){
            this.$emit("row-dbl-click",row);
        },
        handlePaginationChange: function(pagination){
            console.log("handlePaginationChange", pagination);
            this.metadatas.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
            this.fetch();
        }
    }
};
</script>
<style lang="scss" scoped>
.vg-etages-table{
    .vg-etages-demande-intervention{
        height: 85%;
        width: 100%;
        overflow: auto;
        min-height: 50px;
    }
}
.loading-overlay{
    display: flex;
    flex-direction: column;
    align-items: center;
    .text{
        background-color:#35b1ea;
        color:white;
        border:none;
        padding:10px;
        font-size:14px;
        display: block;
    }
}
</style>
