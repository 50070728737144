<template>
    <tag-grid
      class="vg-tableau-card"
      :idTableau="'tableau-inventaire-lieu-id-mobile'"
      v-model="gridOptions"
      :columnDefs="columnDefs"
      :rowData="operationsInventaire"
      :showLoadingOverlay="showLoadingOverlay"
      :overlayLoadingText="$t('overlay-loading-text')"
      :overlayNoRowsText="$t('overlay-no-rows-text')"
      @ag-click="selectEquipement" />
</template>

<script>
import TagGrid from 'src/components/Grid/TagGrid.vue';
import TagGridMixins from 'src/mixins/TagGridMixins.js';
import InventaireCellRender from "src/components/Vg/TagGrid/InventaireCellRender.vue";
import PathCellRender from "src/components/Vg/TagGrid/PathCellRender.vue";

import InventaireMixins from "src/mixins/InventaireMixins.js";

import { mapGetters } from 'vuex';

export default {
    name: "vg-inventaire-recapitulatif-lieu-id",
    components: {
        TagGrid,
        InventaireCellRender
    },
    mixins: [TagGridMixins, InventaireMixins],
    props:{
        searchQuery:String
    },
    i18n:    { "locale":navigator.language,
    "messages": {
     "fr": {
        "overlay-loading-text": "Chargement des équipements...",
		"overlay-no-rows-text": "Aucun équipement correspondant au filtrage",
        "path-inventaire": "Emplacement",
        "qty-inventaire": "Equipements inventoriés",
        "statut-inventaire": "Statut",
        "etat-inventaire": "Etat",
        "equipement-inventaire": "equipement",
        "carac-techniques": "Caractéristiques techniques",
        "statut-notFound": "Non trouvé",
        "statut-ok": "A été inventorié",
        "qtynot-inventaire": "Equipements non inventoriés"
    },
    "en": {
        "overlay-loading-text": "Loading equipment...",
		"overlay-no-rows-text": "No equipement matching filtering",
        "path-inventaire": "Location",
        "qty-inventaire": "Qty inventoried",
        "statut-inventaire": "Status",
        "etat-inventaire": "State",
        "equipement-inventaire": "equipment",
        "carac-techniques": "Technical caractheristics",
        "statut-notFound": "Not found",
        "statut-ok": "Has been inventoried",
        "qtynot-inventaire": "qty not inventoried"
    },
    "th": {
        "path-inventaire": "ที่ตั้ง",
        "qty-inventaire": "จำนวนที่ตรวจสินค้าคงคลังแล้ว",
        "statut-inventaire": "สถานะ",
        "etat-inventaire": "สถานะ",
        "equipement-inventaire": "อุปกรณ์",
        "qtynot-inventaire": "จำนวนที่ยังไม่ตรวจสินค้าคงคลัง"
        }
    }
},
    data: function() {
        return {
            showLoadingOverlay: false,
            columnDefs: [
                {
                    headerName: this.$t("path-inventaire"),
                    field: "path",
                    hide:false,
                    cellRenderer: (params) => new PathCellRender({propsData: {path: params.data.path}}).$mount().$el,
                },
                {
                    headerName: this.$t("equipement-inventaire"),
                    field: "libel_equipement",
                    hide:false
                },
                {
                    headerName: this.$t("qrCode"),
                    field: "qrCode",
                    hide:false

                },
                {
                    headerName: this.$t("carac-techniques"),
                    field: "carac_techniques",
                    hide:false,
                    cellRenderer: (params) =>{
                        return params.data.carac_techniques ? params.data.carac_techniques : "-";
                    }

                },
                {
                    headerName: this.$t("qty-inventaire"),
                    field: "qtyInventoried",
                    hide:true
                },
                {
                    headerName: this.$t("statut-inventaire"),
                    field: "statut",
                    hide:false,
                    cellRenderer: (params) =>{
                        let statut = params.data.statut;
                        if(statut == "Ok"){
                            statut = this.$t('statut-ok');
                        }else if(statut == "notfound"){
                            statut = this.$t('statut-notFound');
                        }else{
                            statut = "-";
                        }
                        return statut;
                    }
                },
                {
                    headerName: this.$t("etat-inventaire"),
                    field: "etat",
                    hide:false,
                    cellRenderer: (params) =>{
                        return params.data.etat ? params.data.etat : "-";
                    }
                },

                {
                    headerName: this.$t("qtynot-inventaire"),
                    field: "qtyNotInventoried",
                    hide:true
                }
            ],
            gridOptions: {
                enableColResize: true,
                enableSorting: false,
                enableFilter: false,
                suppressDragLeaveHidesColumns: false,
                suppressMovableColumns: false
            },
        }

    },
    watch: {
        searchQuery: {
            handler: function(query){
                this.TagGridMixins_setQuickFilter(this.gridOptions, query);
            }
        }
    },
    computed: {
        ...mapGetters({
            operationsInventaire: 'OperationsInventairesStore/getCollection'
        })
    },
    created:function(){
        this.fetch();
        if(this.$vgutils.isMobile()){
            this.columnDefs.map((columnDef)=>{
                columnDef.hide = true;
            })
            this.columnDefs.unshift({
                cellClass: ["vg-cell-mobile-card"],
                cellRenderer: (params) => new InventaireCellRender({
                    propsData: {
                        equipements: params.data
                    }
                }).$mount().$el
            });
        }
    },
    methods: {
        selectEquipement: function(row){
            // //console.log("select-equipement : ", row);
            this.$emit("select-equipement", row);
        },
        fetch: function(){
            this.showLoadingOverlay = true;
            // liste des équipements à inventorier dans la pièce courante pour cet inventaire
            this.InventaireMixins_fetchOperationsByInventaireIdOnLieu(this.$route.params.id, this.$route.params.lieuId).then(()=>{
                this.showLoadingOverlay = false;
            });
        },
    }
}
</script>

<style lang="scss">
</style>
