<template >
    <vg-app-layout
       :title="$t('zoom__header__titre')"
       :icon="'fiche-de-maintenance.png'"
       :isZoomLayout="true"
       @backpage="backPage()">
       <template #header-top-left-complement v-if="contrat">
           <div class="complement-title">
               <span class="vg-complement-header blue">{{contrat.name}}</span>
           </div>
       </template>
       <template #create-button>
            <vg-button
                type="default-danger"
                @click="showModalUpdateContrat = true;">
                {{$t('update')}}
            </vg-button>
       </template>
       <template #action-button-display-items>
            <li @click="showModalCreateCopieContrat=true;">{{$t("dupliquer-ce-contrat")}}</li>
       </template>

       <template #primary v-if="contrat">
           <vg-collapse :title="$t('caracteristiques')"
               :collapseByDefault="false"
               :type="'section'">
               <template v-slot:pretitle>
                   
               </template>

               <template v-slot:content>
                   <vg-libelle-editable :label="$t('intitule')" >
                       <template v-slot:read>
                           {{contrat.name}}
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('type')" >
                       <template v-slot:read>
                           {{contrat.type}}
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('categorie')" >
                       <template v-slot:read>
                           <span v-for="categorie in contrat.categories" :key="categorie.id">
                               {{categorie.libelleCatgorie}}
                               <small v-if="categorie.isGe==0 && categorie.nbEquipements>0" style="margin-left:10px;">{{ $t("categorie-nb-equipements", {nb: categorie.nbEquipements}) }}</small>
                               <small v-else-if="categorie.isGe==0 && categorie.nbEquipements==0" style="margin-left:10px;color:red;"><i class="fas fa-exclamation-triangle" ></i> {{ $t("categorie-aucun-equipement") }}</small>
                               <small v-else-if="categorie.isGe==1 && categorie.nbEquipements>0" style="margin-left:10px;">{{ $t("categorie-nb-pices", {nb: categorie.nbEquipements}) }}</small>
                               <small v-else-if="categorie.isGe==1 && categorie.nbEquipements==0" style="margin-left:10px;color:red;"><i class="fas fa-exclamation-triangle" ></i> {{ $t("categorie-aucune-piece") }}</small>
                           </span>
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('numero')" >
                       <template v-slot:read>
                           {{contrat.number}}
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('fournisseur')" >
                       <template v-slot:read>
                           {{contrat.tiers.name}}
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('astreinte')" >
                       <template v-slot:read>
                           {{convertBooleanToYesNo(contrat.astreinte)}}
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('cout')" >
                       <template v-slot:read>
                           <vg-input-cost v-if="contrat.annualCost" v-model="contrat.annualCost" />
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('periodicite')" >
                       <template v-slot:read>
                           {{contrat.renewalPeriodicity}}
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('perimetre')" >
                       <template v-slot:read>
                           <vg-lieu-path-viewer v-for="site in sites"
                               v-model="site.value"
                               :key="site.value"
                               >
                           </vg-lieu-path-viewer>
                       </template>
                   </vg-libelle-editable>

               </template>
           </vg-collapse>

           <vg-collapse :title="$t('gestion-calendaire')"
               :collapseByDefault="false"
               :type="'section'">
               <template v-slot:pretitle>
                   <vg-button
                       size="md"
                       type="default-round"
                   >
                       <i class="far fa-calendar-alt"></i>
                   </vg-button>
               </template>

               <template v-slot:content>
                   <vg-libelle-editable :label="$t('status')" >
                       <template v-slot:read>
                           {{contrat.status}}
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('periodiciteInterventions')" >
                       <template #read>
                           <vg-periodicite-viewer v-model="contrat.periodiciteIntervention" />
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('date-debut')" >
                       <template v-slot:read>
                           {{contrat.startDate}}
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('date-fin')" >
                       <template v-slot:read>
                           {{contrat.endDate}}
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('preavis-resiliation')" >
                       <template v-slot:read>
                           {{contrat.noticePeriod}}
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('tacite-reconduction')" >
                       <template v-slot:read>
                           {{convertBooleanToYesNo(contrat.isTacit)}}
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('resiliable-maintenant')" >
                       <template v-slot:read>
                           {{convertBooleanToYesNo(isResiliable)}}
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('age-contrat')" >
                       <template v-slot:read>
                           {{ageContrat}}
                       </template>
                   </vg-libelle-editable>
               </template>
           </vg-collapse>

           
           <!-- Gestion documentaire -->
           <vg-collapse :title="$t('zoom__body__left__section-documents-attaches__titre')"
               :collapseByDefault="false"
               :type="'section'">
               <template v-slot:pretitle>
                   <vg-button
                       size="md"
                       type="default-round"
                       @click="isUploaderVisible = !isUploaderVisible"
                   >
                    <i class="fas fa-plus-square"></i>
                   </vg-button>
               </template>
               <template v-slot:content>

                   <vg-files-uploader v-if="isUploaderVisible"
                       :tagsList="VgFilesMixins.tags"
                       @upload-done="VgFilesMixins_fetchFilesByUid(); isUploaderVisible=!isUploaderVisible;">
                   </vg-files-uploader>

                   <vg-files-viewer-list-section
                       :files="VgFilesMixins_filesListSection"
                       @delete="VgFilesMixins_fetchFilesByUid()">
                   </vg-files-viewer-list-section>
               </template>
           </vg-collapse>
       </template>

       <template #secondary v-if="contrat">
                <div>
                    <div style="margin-bottom:5px;">
                        <span style="color:#429fff;font-size:16px;">
                            {{$t("programmation-interventions-previsionnelles")}}
                        </span>
                    </div>
                    <div v-if="programmationIntervention" style="display: flex;justify-content: flex-start;align-items: center;gap:5px;">
                        <span>{{ getReadableRecurrence }} <small>{{ getReadableTime }}</small></span>
                        <small>({{ $t("generee-x-days-before", {days: programmationIntervention.minDaysBeforeGeneratedDate}) }})</small>
                        <vg-button
                           :type="'danger'"
                           size="sm"
                           style="margin-left:10px;"
                           @click="showProgrammationInterventions=true">
                           {{$t("modifier-programmation-interventions")}}
                       </vg-button>
                    </div>
                    <div v-else style="display: flex;justify-content: flex-start;align-items: center;gap:5px;">
                        <span>{{ $t("aucune-programmation") }}</span>
                        <vg-button
                           :type="'success'"
                           size="sm"
                           style="margin-left:10px;"
                           @click="showProgrammationInterventions=true">
                           {{$t("programmer-interventions")}}
                       </vg-button>
                    </div>
                </div>
                <br>
               <div style="width:100%;display:flex;justify-content:space-between;margin-bottom:5px;">
                    <span style="color:#429fff;font-size:16px;">
                       {{$t("interventions")}}
                   </span>
                   <div style="display: flex;justify-content: flex-end;align-items: center;gap:5px;">
                       <!-- btn création -->
                       
                       
                       <vg-button
                           :type="'success'"
                           @click="onOpenCreateIntervention">
                           {{$t("add-intervention-prevue")}}
                       </vg-button>
                       <vg-button :type="'success'" @click="showGenerateurInterventions=true;">{{$t("generer-dates-previsionnelles")}}</vg-button>
                    </div>
               </div>

               <div style="display: flex; justify-content: space-between;">
                   <!-- tabs -->
                   <vg-tabs v-model="currentTabHistorique"
                       :tabs="tabsIntervention"
                       :color="'bleu'"
                       @tabClicked="onTabInterventionsClicked">
                   </vg-tabs>
                   <!-- search field -->
                   <vg-text-filter v-model="searchQuery" style="max-width: 50%;">
                   </vg-text-filter>
                   <!-- menu more -->
                   <action-menu-aggrid
                       :idTableau="'vg-interventions-table'"
                        placement="bottom-right"/>
               </div>

               <div style="display:flex;flex-direction:column;">
                   <vg-interventions-table
                       style="height:300px;"
                       :searchQuery="searchQuery"
                       :agFilters="interventionsFilters"
                       :localFilters="localInterventionsFilters"
                       :hiddenColumns="['contrat.name','tiers.name','cout']"
                       :notShowableColumns="['cout']"
                       isPeriodiques
                       @row-dbl-click="onInterventionRowDblClick">
                   </vg-interventions-table>
               </div>
               <div style="width:100%;display:flex;justify-content:space-between;margin-bottom:10px;margin-top:30px;">
                   <span style="color:#429fff;font-size:16px;">
                       {{ $t('zoom__body__right__section-entites-attachees__header__title') }}
                   </span>
               </div>
               <div style="height:300px">
                   <vg-equipements-table
                        v-if="equipementsFilters"
                       id="show-lieu-equipements"
                       :filters="equipementsFilters"

                       :showPrint="false"
                       paginate
                       @row-click="handleClickRow"
                       >
                   </vg-equipements-table>
               </div>
       </template>

       

       <vg-contrat-form v-if="showModalUpdateContrat"
           v-model="contrat"
           key="update-contrat-form"
           @close="showModalUpdateContrat = false"
           @updated="handleUpdateContrat"
           >
       </vg-contrat-form>

        <vg-contrat-form v-if="showModalCreateCopieContrat"
            initCopie
            key="create-contrat-form"
            @close="showModalCreateCopieContrat = false"
            @created="onCreateContrat" />

       <vg-intervention-form v-if="showInterventionModal"
           :idContrat="contrat.id"
           :contrat="contrat"
           :idTiers="contrat.tiers_id"
           :isPeriodique="true"
           :defaultStatut="'intervention-prevue'"
           @close="onCloseInterventionForm"
           @save="onCloseInterventionForm">
       </vg-intervention-form>

        <vg-contrat-generateur-interventions v-if="showGenerateurInterventions"
            @close="showGenerateurInterventions=false;"
            @save="showGenerateurInterventions=false;" />

        <vg-intervention-previsionnelle-scheduling-form v-if="showProgrammationInterventions"
                :programmation="programmationIntervention"
                :contrat="contrat"
                @close="showProgrammationInterventions=false;"
                @save="handleInterventionPrevisionnelleSchedulingCreated"
                @update="handleInterventionPrevisionnelleSchedulingUpdated"
        />
    </vg-app-layout>
</template>

<script>


import TagGrid from 'src/components/Grid/TagGrid.vue';
import ContratsMixins from "src/mixins/ContratsMixins.js";
import TagGridMixins from 'src/mixins/TagGridMixins.js';
import VgContratForm from 'src/components/Vg/Forms/VgContratForm.vue';

import TiersMixins from "src/mixins/TiersMixins.js";
import LieuMixins from "src/mixins/LieuMixins.js";

import VgButton from 'src/components/Vg/VgButton.vue';

import VgChips from 'src/components/Vg/VgChips.vue';
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgInputCost from 'src/components/Vg/VgInputCost.vue';
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import VgPeriodiciteViewer from 'src/components/Vg/VgPeriodiciteViewer.vue';

import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";
import VgFilesViewerPhoto from "src/components/Vg/Files/VgFilesViewerPhoto.vue";
import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";

import VgInterventionsTable from "src/components/Vg/Interventions/VgInterventionsTable.vue";
import VgInterventionForm from "src/components/Vg/Forms/VgInterventionForm.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgLibelleEditable from 'src/components/Vg/Forms/VgLibelleEditable.vue';

import ActionMenuAggrid from "src/components/Vg/TagGrid/ActionMenuAggrid.vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgEquipementsTable from "src/components/Vg/Equipements/VgEquipementsTable.vue";
import VgContratGenerateurInterventions from "src/components/Vg/Contrat/VgContratGenerateurInterventions.vue";
import VgInterventionPrevisionnelleSchedulingForm from 'src/components/Vg/Forms/VgInterventionPrevisionnelleSchedulingForm.vue';
import AffectationsMixins from 'src/mixins/AffectationsMixins.js';
export default {
  name: 'contrat',
  mixins: [ContratsMixins, TagGridMixins, LieuMixins, TiersMixins, AffectationsMixins],
  components: {

    VgFilesUploader,
    VgFilesViewerListSection,
    VgFilesViewerPhoto,
    TagGrid,

    VgButton,
    VgChips,
    VgTabs,
    VgInputCost,
    VgInterventionsTable,
    VgInterventionForm,
    VgTextFilter,
    VgPeriodiciteViewer,
    ActionMenuAggrid,
    VgContratForm,
    VgCollapse,
    VgLibelleEditable,
    VgLieuPathViewer,
    VgEquipementsTable,
    VgContratGenerateurInterventions,
    VgInterventionPrevisionnelleSchedulingForm
  },
  i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "interventions-toutes": "Toutes",
            "interventions-prevues": "Prévisionnelles",
            "interventions-realisees": "Effectives",
            "interventions": "Interventions périodiques",
            "add-intervention-prevue": "Créer intervention prévisionnelle",
            "restriction-site": "Restriction Site",
            "zoom__header__titre": "Détails du contrat : ",
            "zoom__body__right__section-entites-attachees__header__title": "Equipements",
            "zoom__body__left__section-documents-attaches__titre": "Documents attachés",
            "age-contrat": "Age contrat",
            "resiliable-maintenant": "Résilisable maintenant ?",
            "tacite-reconduction": "Tacite reconduction ?",
            "preavis-resiliation": "Préavis résiliation en Jours",
            "date-fin": "Date de fin",
            "date-debut": "Date de début",
            "periodiciteInterventions": "Périodicité interventions",
            "status": "Statut",
            "periodicite": "Périodicité",
            "cout": "Coût",
            "astreinte": "Astreinte",
            "fournisseur": "Fournisseur",
            "numero": "Numéro",
            "categorie": "Catégorie",
            "type": "Type",
            "intitule": "Intitule",
            "gestion-calendaire": "Gestion calendaire",
            "caracteristiques": "Caractéristiques",
            "jours": "Jours",
            "perimetre": "Périmètre site",
            "yes": "Oui",
            "no": "Non",
            "update": "Modifier",
            "generer-dates-previsionnelles": "Générer dates prévisionnelles interventions",
            "zoom__body__left__section-programmation-interventions__titre": "Programmation interventions prévisionnelles",
            "programmer-interventions": "Programmer interventions prévisionnelles",
            "modifier-programmation-interventions": "Modifier programmation interventions prévisionnelles",
            "programmation-interventions-previsionnelles": "Programmation interventions prévisionnelles",
            "aucune-programmation": "Aucune programmation",
            "tous-les-1": "Tous les jours {weekDay}",
            "tous-les-7": "Toutes les semaines {weekDay}",
            "tous-les-30": "Toutes les mois {weekDay}",
            "tous-les-90": "Toutes les trimestre {weekDay}",
            "tous-les-180": "Toutes les semestre {weekDay}",
            "tous-les-365": "Tous les ans {weekDay}",
            "tous-les-x-jours": "Tous les {jours} jours {weekDay}",
            "week-day-1": "le lundi",
            "week-day-2": "le mardi",
            "week-day-3": "le mercredi",
            "week-day-4": "le jeudi",
            "week-day-5": "le vendredi",
            "week-day-6": "le samedi",
            "week-day-7": "le dimanche",
            "generee-x-days-before": "Intervention générée {days} jours avant la date",
            "dupliquer-ce-contrat": "Dupliquer ce contrat",
            "categorie-nb-equipements": "{nb} équipements",
            "categorie-aucun-equipement": "aucun équipement",
            "categorie-nb-pices": "{nb} pièces",
            "categorie-aucune-piece": "aucune pièce"
        },
        "en": {
            "zoom__header__titre": "Contract s details : ",
            "zoom__body__right__section-entites-attachees__header__title": "Equipments",
            "echeances-previsionnelle": "Estimate",
            "effectives": "Effective",
            "interventions": "Periodic intervention",
            "interventions-toutes": "All",
            "interventions-prevues": "Planned",
            "interventions-realisees": "Done",
            "add-intervention-prevue": "Create planned intervention",
            "zoom__body__left__section-documents-attaches__titre": "Attached documents",
            "age-contrat": "Contract's age",
            "resiliable-maintenant": "Actually cancellable ?",
            "tacite-reconduction": "Tacit aggreement ?",
            "preavis-resiliation": "Notice of termination ( days )",
            "date-fin": "End date",
            "date-debut": "Start date",
            "periodiciteInterventions": "Intervention periodicity",
            "status": "Status",
            "periodicite": "Periodicity",
            "cout": "Cost",
            "astreinte": "Night shift",
            "fournisseur": "Provider",
            "numero": "Number",
            "categorie": "Category",
            "type": "Type",
            "intitule": "Name",
            "gestion-calendaire": "Calendar management",
            "caracteristiques": "Characteristics",
            "jours": "Days",
            "perimetre": "Area perimeter",
            "yes": "Yes",
            "no": "No",
            "restriction-site": "Site restriction",
            "update": "Update",
            "actions": "Actions",
            "zoom__body__left__section-programmation-interventions__titre": "Scheduled interventions",
            "programmer-interventions": "Schedule interventions",
            "programmer-interventions": "Schedule Planned Interventions",
            "modifier-programmation-interventions": "Modify Scheduled Planned Interventions",
            "programmation-interventions-previsionnelles": "Scheduled Planned Interventions",
            "aucune-programmation": "No Schedule",
            "tous-les-1": "Every day {weekDay}",
            "tous-les-7": "Every week {weekDay}",
            "tous-les-30": "Every month {weekDay}",
            "tous-les-90": "Every quarter {weekDay}",
            "tous-les-180": "Every half-year {weekDay}",
            "tous-les-365": "Every year {weekDay}",
            "tous-les-x-jours": "Every {days} days {weekDay}",
            "week-day-1": "on Monday",
            "week-day-2": "on Tuesday",
            "week-day-3": "on Wednesday",
            "week-day-4": "on Thursday",
            "week-day-5": "on Friday",
            "week-day-6": "on Saturday",
            "week-day-7": "on Sunday",
            "generee-x-days-before": "Intervention generated {days} days before the date",
            "dupliquer-ce-contrat": "Duplicate this contract",
            "categorie-nb-equipements": "{nb} equipments",
            "categorie-aucun-equipement": "no equipment",
            "categorie-nb-pices": "{nb} pieces",
            "categorie-aucune-piece": "no piece"
        },
        "th": {
            "zoom__header__titre": "รายละเอียดของสัญญา : ",
            "zoom__body__right__section-entites-attachees__header__title": "สิ่งที่แนบมาด้วย",
            "echeances-previsionnelle": "ประมาณการ",
            "effectives": "มีผล",
            "interventions": "การแทรกแซงตามระยะเวลา",
            "interventions-toutes": "ทั้งหมด",
            "interventions-prevues": "วางแผนแล้ว",
            "interventions-realisees": "ทำ",
            "zoom__body__left__section-documents-attaches__titre": "เอกสารที่แนบแล้ว",
            "zoom__body__left__section-programmation-interventions__titre": "การแทรกแซงตามระยะเวลาที่กำหนดไว้ล่วงหน้า",
            "programmer-interventions": "วางแผนการแทรกแซงตามระยะเวลาที่กำหนดไว้ล่วงหน้า"
        }
    }
},

  data: function() {
    return {
      contrat: null,
      sites:[],
      showModalUpdateContrat:false,
      tableEntiteAttache: [],
      interventionsFilters: {
          contrat_id: { attr:"i.contrat_id", colId: "contrat_id", value: this.$route.params.id, action:"equals" }
      },
      localInterventionsFilters: [],
      searchQuery: "",
      showInterventionModal: false,
      currentTabHistorique: "toutes",
      isUploaderVisible:false,
      equipementsFilters:null,
      showGenerateurInterventions: false,
      showProgrammationInterventions:false,
      programmationIntervention:null,
      showModalCreateCopieContrat: false
    }
  },
    methods: {
        onCreateContrat: function(contrat){
            this.showModalCreateCopieContrat = false;
            this.$router.push({ name: '_contrat_id', params: { id: contrat.id } });
        },
        backPage: function(){
            this.$router.go(-1);
            this.$store.dispatch("ContratsStore/deleteSelectedItem");
        },
        handleInterventionPrevisionnelleSchedulingCreated(programmation){
            this.AffectationsMixin_createProgrammationContratIntervention(programmation, this.contrat.id).then((response)=>{
                this.showProgrammationInterventions = false;
                this.fetchProgrammationIntervention();
            });
        },
        handleInterventionPrevisionnelleSchedulingUpdated(programmation){
            this.AffectationMixin_updateProgrammationContratIntervention(programmation, this.contrat.id).then((response)=>{
                this.showProgrammationInterventions = false;
                this.fetchProgrammationIntervention();
            });
        },
        fetchProgrammationIntervention(){
            this.AffectationsMixin_fetchProgrammationContratIntervention(this.$route.params.id).then((response)=>{
                this.programmationIntervention = response && response!=""?response:null;
            });
        },
        fetch:function(){
            const contratId = this.$route.params.id;
            let restrictionsSite = this.$app.restrictionsite;
            let filters = {"directives":[],"filters":[{"attr":"c.id","value":contratId,"action":"equals"}]};
            this.ContratsMixins_fetchContrats(filters).then((reponse) => {        
                this.contrat = reponse[0];
                //this.contrat.lieux = reponse[0].lieux;
                this.contrat.categories = [this.contrat.categorie]; // hack
                // format for vg-chips
                //return;
                if(this.contrat.lieux){
                    this.contrat.lieux.forEach((lieu) => {
                        this.sites.push({
                            value: lieu.contratslieux_path
                        });
                    });
                }
                this.contrat.status = this.ContratsMixins_formatStatus(this.contrat);
                this.contrat.endDate = this.splitDatetime(this.contrat.endDate);
                this.contrat.startDate = this.splitDatetime(this.contrat.startDate);
                this.tableEntiteAttache = reponse.equipements;

                if(this.contrat.lieux.length){
                    let lieux = this.contrat.lieux.map((lieu)=>{
                        return lieu.contratslieux_path;
                    });
                    this.equipementsFilters = {
                        /*isGEP:{attr:"e.isGroupEqp",colId:"e.isGroupEqp",value:"1",action:"not_equals"},*/
                        idLieu:{attr:"l.path",colId:"l.path",value:lieux,action:"contains"},
                        idCategorie:{attr:"e.idCategorie_id",value:this.contrat.categorie?this.contrat.categorie.id:null,action:"equals"}
                    };
                }else{
                    this.equipementsFilters = {
                        /*isGEP:{attr:"e.isGroupEqp",colId:"e.isGroupEqp",value:"1",action:"not_equals"},*/
                        idCategorie:{attr:"e.idCategorie_id",value:this.contrat.categorie?this.contrat.categorie.id:null,action:"equals"}
                    };
                }
                this.VgFilesMixins_init(this.contrat.uid, "contrat");
                this.$store.dispatch("ContratsStore/setSelectedItem", this.contrat);
            });
        },
        onCloseInterventionForm: function() {
            this.showInterventionModal = false;
            this.$store.dispatch("InterventionsStore/deleteSelectedItem");
        },
        onOpenCreateIntervention: function() {
            this.showInterventionModal = true;
        },
        onInterventionRowDblClick: function(intervention) {
            this.$store.dispatch("InterventionsStore/setSelectedItem", intervention);
            this.showInterventionModal = true;
        },
        onTabInterventionsClicked: function(tab) {
            this.localInterventionsFilters = tab.filters;
        },
        /**
         * SplitDatetime - return the first part of datetime.
         *
         * @param  {string} datetime string representation yyyy-mm-dd hh:mm
         * @return {string}          return yyy-mm-dd
         */
        splitDatetime: function(datetime) {
            return datetime.split(' ')[0];
        },
        /**
         * Convert boolean to yes or no for human.
         *
         * @param  {boolean} boolean
         * @return {string} 1 = yes , 0 = no
         */
        convertBooleanToYesNo: function(boolean) {
            return !boolean ? this.$t("no") : this.$t("yes");
        },
        handleUpdateContrat:function(){
            location.reload();
        }
    },
    computed: {
        getReadableRecurrence: function(){
            let readableRecurrence = "";
            let weekDay = "";
            if(this.programmationIntervention.dayOfWeek) weekDay = this.$t("week-day-"+this.programmationIntervention.dayOfWeek);
            if([1,7,30,90,180,365].includes(this.programmationIntervention.recurrence)) readableRecurrence = this.$t("tous-les-"+this.programmationIntervention.recurrence, {weekDay: weekDay}); 
            else readableRecurrence = this.$t("tous-les-x-jours", {jours: this.programmationIntervention.recurrence, weekDay: weekDay});
            return readableRecurrence;
        },
        getReadableTime: function(){
            let start = moment(this.programmationIntervention.start);
            let end = moment(this.programmationIntervention.start).add(this.programmationIntervention.durationMinutes, "minutes");
            return start.format("HH:mm")+" - "+end.format("HH:mm");
        },
        tabsIntervention: function() {
            return [{
                    label: this.$t("interventions-toutes"),
                    name: "toutes",
                    filters: [{
                        attr: "i.status",
                        colId: "status",
                        value: null,
                        action: "equals"
                    }]
                },
                {
                    label: this.$t("interventions-prevues"),
                    name: "prevues",
                    filters: [{
                        attr: "i.status",
                        colId: "status",
                        value: "intervention-prevue",
                        action: "equals"
                    }]
                },
                {
                    label: this.$t("interventions-realisees"),
                    name: "realisees",
                    filters: [{
                        attr: "i.status",
                        colId: "status",
                        value: "intervention-realisee",
                        action: "equals"
                    }]
                }
            ];
        },
        /**
         * Return true if contrat is resiliable.
         *
         * @return boolean
         */
        isResiliable: function() {
            let finContrat = this.contrat.endDate;
            let preavis = this.contrat.noticePeriod;
            let today = moment();
            let lowerDate = moment(finContrat).subtract(preavis, 'days');
            let higherDate = moment(finContrat);
            return today.isBetween(lowerDate, lowerDate, higherDate);
        },
        ageContrat: function() {
            var start = moment(this.contrat.startDate);
            var now = moment();
            let difference = start.diff(now, 'days') * -1;
            return difference < 365 ? difference + " " + this.$t("jours") : parseInt(difference / 365) + " " + this.$t("an");
        }
    },
    mounted: function() {
        this.fetch();
        this.fetchProgrammationIntervention();
    }
}
</script>

<style lang="scss" scopped >

    #vg-contrat-title{
        /*background-color: rgba(105, 76, 167, 0.1);*/
        border: 1px solid #429fff;
        color: #429fff;
        border-radius: 3px;
        font-size: 28px;
        padding: 0px 6px;

    }


    .vg-contrat-section-title{
        color:#429fff;
        font-size:15px;
    }
    .vg-contrat-section-title-disabled{
        color:grey;
        font-size:15px;
        opacity:0.3;
    }
    .delete_action{
        color:#e53530;
        font-size:18px;
    }

</style>
