<template>
    <vg-app-layout
        :title="$t('page-title')"
        :icon="'fiche-de-maintenance.png'"
        :colorheader="getHeaderColor"
        :activeFilters="activeFilters"
        @save-filters="FiltersMixins_saveFilters(agfilters)"
        @action-export="handleActionExport">
        <template #search>
            <vg-text-filter v-model="searchQuery" />
        </template>
        <template #panel-filters>
            <vg-filter-site v-model="agfilters.field_path.value" />
        </template>
        <template #primary>
            <div id="maintenance-action-btn" v-show="selectedReserves.length && !$vgutils.isMobile()">
                <vg-button :type="'default'" @click="handleCloture">
                    {{$t("cloturer")}}
                </vg-button>

                <vg-button :type="'default'" @click="handlePriseEnCompte">
                    {{$t("prendre-en-compte")}}
                </vg-button>

                <vg-button :type="'default'" @click="handleMiseEnAttente">
                    {{$t("mettre-en-attente")}}
                </vg-button>

                <vg-button :type="'default-danger'" @click="openDialogBoxConfirmationSuppression"
                    v-show="selectedReserves.length == 1" >
                    {{$t("supprimer")}}
                </vg-button>
            </div>
            <vg-tabs class="hidden-print"
                :tabs="tabs"
                :color="'dark'"
                @tabClicked="handleTabClick" />
            <vg-maintenance-timeline v-if="$vgutils.isMobile() && focusedTab && focusedTab=='mesAffectations'"
                v-model="getMesAffectations"
                :attributeDate="['affectation','start']">
                <template #item="{item}">
                    <fm-cell-render :fm="item" :key="item.id" @click="goToReserve(item)" />
                </template>
            </vg-maintenance-timeline>
            <vg-fichesav-table v-show="!($vgutils.isMobile() && focusedTab && focusedTab=='mesAffectations')"
                :searchQuery="searchQuery"
                :filters="maintenanceFilters"
                :isReserves="true" 
                :page="'reserves'"/>
        </template>

        <template #secondary-filters>
            <vg-input :title="$t('filter-Date')" >
                <vg-filter-interval-date
                    :defaultStartDate="dateRangeFilter?dateRangeFilter.startDate:null"
                    :defaultEndDate="dateRangeFilter?dateRangeFilter.endDate:null"
                    @change="handleFilterIntervalDate" />
            </vg-input>
            <vg-filter-site v-model="agfilters.field_path.value" />
            <vg-filter-tiers v-model="agfilters.field_tiersName.value" 
                :title="$t('filter-tiers')"
                :attributeValue="'name'"/>
        </template>
        <template #secondary-widget>
            <vg-tree-lieux v-model="agfilters.field_path.value"/>
        </template>

        <dialog-secured-action v-if="isModalConfirmDeleteOpened"
            :valid-response="selectedReserves.length"
            @save="handleSupprimerMaintenance"
            @close="isModalConfirmDeleteOpened = false">
            <template #header-title>
                {{ $t("user-form-confirm-suppression") }}
            </template>
        </dialog-secured-action>

    </vg-app-layout>
</template>

<script>
    import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
    import LieuMixins from "src/mixins/LieuMixins.js";

    import VgFichesavTable from 'src/components/Vg/FicheSAV/VgFichesavTable.vue';
    import VgTabs from 'src/components/Vg/VgTabs.vue';
    import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
    import VgInput from "src/components/Vg/VgInput.vue";
    import VgFilterIntervalDate from "src/components/Vg/FicheSAV/VgFilterIntervalDate.vue";
    import VgSelect from "src/components/Vg/VgSelect.vue";
    import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
    import VgGroupFilters from "src/components/Vg/Utils/VgGroupFilters.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import Metadatas from "src/services/Metadatas.js";
    import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
    import VgMaintenanceTimeline from 'src/components/Vg/FicheSAV/VgMaintenanceTimeline.vue';
    import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
    import VgFilterTiers from "src/components/Vg/Filters/VgFilterTiers.vue";
    import FiltersMixins from 'src/mixins/FiltersMixins';

    import { mapGetters } from 'vuex';
    export default {
        name: 'reserves',
        components: {

            VgFichesavTable,
            VgTabs,
            VgTextFilter,
            VgFilterIntervalDate,
            VgInput,
            VgSelect,
            VgTreeLieux,
            VgGroupFilters,
            VgButton,
            DialogSecuredAction,
            VgMaintenanceTimeline,
            VgFilterSite,
            VgFilterTiers
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "toutes-reserves-en-cours": "Toutes les réserves en cours",
            "levees": "Levées",
            "supprimer": "Supprimer",
            "cloturer": "Clôturer",
            "filter-Date": "Intervalle dates",
            "page-title": "Réserves / Observations",
            "generer-rapport": "Imprimer rapport",
            "user-form-confirm-suppression": "Vous allez supprimer des réserves cette action est irréversible.",
            "prendre-en-compte": "Prendre en compte",
            "retour-a-faire": "Retour à faire",
            "liste-reserve": "Liste-reserves",
            "mettre-en-attente": "Mettre en attente",
            "filter-tiers": "Filtrage par prestataire"
        },
        "en": {
            "filter-Date": "Date interval",
            "generer-rapport": "Print report",
            "supprimer": "Delete",
            "cloturer": "Resolve",
            "prendre-en-compte": "Consider",
            "retour-a-faire": "Require a Feedback",
            "mettre-en-attente": "Put on hold",
            "toutes-reserves-en-cours": "All reservation in progress",
            "levees": "Lifted",
            "page-title": "Reservations / Observations",
            "liste-reserves": "Reservations-list",
            "user-form-confirm-suppression": "Delete reservation, this action is irreversible",
            "filter-tiers": "Filter by contractor"
        },
        "th": {
            "filter-Date": "ช่วงเวลาวันที่",
            "generer-rapport": "พิมพ์รายงาน",
            "user-form-confirm-suppression": "แน่ใจหรือว่าต้องการลบแผ่นงานเหล่านี้ถาวร",
            "prendre-en-compte": "พิจารณา",
            "retour-a-faire": "ต้องการข้อเสนอแนะ",
            "mettre-en-attente": "พักไว้",
            "supprimer": "ลบ",
            "cloturer": "แก้ไขปัญหาอีกครั้ง"
        }
    }
},
		mixins:[
            MaintenanceMixins,
            LieuMixins,
            FiltersMixins
        ],
        data: function() {
            return {
    			searchQuery: "",
                focusedTab:null,
                isModalConfirmDeleteOpened:false,
                agfilters: {
                    id: {attr:"fm.id", value: null, action:"contains", openParenthesis: true},
                    libelEquipement: {attr: "eq.libel_equipement", value: null, action: "contains", logicalOperator: "OR"},
                    libelComposant: {attr: "comp.libelComposant", value: null, action: "contains", logicalOperator: "OR"},
                    userNomAffecte: { attr:"fuaffecte.nom",value: null, action:"contains", logicalOperator: "OR" },
                    userPrenomAffecte: { attr:"fuaffecte.prenom",value: null, action:"contains", logicalOperator: "OR"},
                    tiersName: { attr:"ti.name", value: null, action:"contains", logicalOperator: "OR"},
                    contratName: { attr:"co.name", value: null, action:"contains", logicalOperator: "OR"},
                    commentaireClient: {attr: "fm.commentaireClient", value: null, action: "contains", logicalOperator: "OR", closeParenthesis: true},
                    field_path: {attr: "l.path", value: null, action: "contains"},
                    field_statut: {attr: "fm.statut", value: ["En_cours", "prise_en_compte", "en_attente"], action: "equals"},
                    field_dateOuvertureSAV: { attr:"fm.dateOuvertureSAV",colId: "dateOuvertureSAV", value: null, action:"between" }, // value = [start,end]
                    field_dateFermetureSAV: { attr:"fm.dateFermetureSAV",colId: "dateFermetureSAV", value: null, action:"between" },
                    field_urgence : {attr:"fm.urgence",colId: "urgence", value:false, action:"equals"},
                    field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:false, action:"equals"},
                    field_relance: {attr:"fm.isRelance",colId: "relance", value:false, action:"equals"},
                    field_affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                    field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"},
                    field_tiersName: { attr:"ti.name", value: null, action:"contains"}
                },
                dateRangeFilter: null
            }
        },
        watch:{
            searchQuery:{
                handler: function(query){
                    if (query != null && query.length) {
                        let searchQuery = query.split(" ").join('","');
                        this.agfilters.id.value = searchQuery;
                        this.agfilters.libelEquipement.value = searchQuery;
                        this.agfilters.libelComposant.value = searchQuery;
                        this.agfilters.commentaireClient.value  = searchQuery;
                        this.agfilters.userNomAffecte.value  = searchQuery;
                        this.agfilters.userPrenomAffecte.value  = searchQuery;
                        this.agfilters.tiersName.value  = searchQuery;
                        this.agfilters.contratName.value  = searchQuery;
                    }else {
                        this.agfilters.id.value = null;
                        this.agfilters.libelEquipement.value = null;
                        this.agfilters.libelComposant.value = null;
                        this.agfilters.commentaireClient.value  = null;
                        this.agfilters.userNomAffecte.value  = null;
                        this.agfilters.userPrenomAffecte.value  = null;
                        this.agfilters.tiersName.value  = null;
                        this.agfilters.contratName.value  = null;
                    }
                    this.FiltersMixins_saveSearchQuery(query);
                }
            }
        },
        methods:{
            openRapportPrinter: function(){
                this.$router.push({ name: '_maintenances_print' })
                /*var event = new CustomEvent("askGenerateReport", {
                  detail: {"idTableau":"vg-fichesav-table"}
                });
                window.dispatchEvent(event);*/
            },
            handleTabClick: function(e){
                if(e.name != this.focusedTab ){
                    this.focusedTab = e.name;
                    // HACK: le filtrage se fait en local ne pas refetch
                    this.agfilters = Object.assign(this.agfilters, e.filters);
                }
    		},
            handleFilterIntervalDate:function(e){
                this.dateRangeFilter = e;
                if(e.startDate && e.endDate){
                    this.agfilters["field_"+e.targetedColumn].value = [e.startDate,e.endDate];
                }else{
                    this.agfilters["field_dateOuvertureSAV"].value = null;
                    this.agfilters["field_dateFermetureSAV"].value = null;
                }
            },
            handleActionExport:function(e){
                let metadatas = new Metadatas();
                metadatas.setFilters(this.agfilters);
                switch (e.name) {
                    case "askForXLS":
                        this.MaintenanceMixins_getFile(metadatas,this.$t("liste-reserves"),"xlsx").then((datas)=>{});
                        break;
                    case "askForCSV":
                        this.MaintenanceMixins_getFile(metadatas,this.$t("liste-reserves"),"csv").then((datas)=>{});
                        break;
                    case "gestionColonnesEventId":
                        window.dispatchEvent(new CustomEvent("gestionColonnesEventId", {
                            "detail": {"idTableau":"vg-fichesav-table"}
                        }));
                        break;
                    default:
                }

            },
            goToReserve: function(maintenance){
                this.$router.push({ name: '_reserve', params: { id: maintenance.id, datas: maintenance } })
            },
            // MUST BE REFACTORIZED
            handleCloture: function(){
                let maintenances = this.getSelectedMaintenances;
                this.MaintenanceMixins_resolveMaintenances(maintenances).then((datas)=>{
                    let idMaintenances = datas.map((data)=>{
                        return data["id"];
                    });
                    let maintenances = this.getMaintenances.map((maintenance)=>{
                        if(idMaintenances.indexOf(maintenance["id"]) != -1){
                            maintenance["statut"] = "Resolue";
                            this.$store.dispatch("MaintenancesStore/deleteMaintenance", maintenance["id"]);
                        }

                        return maintenance;
                    });
                    this.$store.dispatch("MaintenancesStore/decrementCounter", {name:"En_cours",qty:this.selectedReserves.length});
                    this.$store.dispatch("MaintenancesStore/incrementCounter", {name:"Resolue",qty:this.selectedReserves.length});
                    this.$store.dispatch("MaintenancesStore/setMaintenances", maintenances);
                    this.$store.dispatch("MaintenancesStore/selectMaintenances", []);
                });
            },
            handleMiseEnAttente: function(){
                this.MaintenanceMixins_mettreEnAttenteMaintenances(this.getSelectedMaintenances).then(()=>{
                    let selectedMaintenances = this.getSelectedMaintenances;
                    let idMaintenances = selectedMaintenances.map((data)=>{
                        return data["id"];
                    });
                    let maintenances = this.getMaintenances.map((maintenance)=>{
                        if(idMaintenances.indexOf(maintenance["id"]) != -1){
                            maintenance["statut"] = "en_attente";
                        }
                        return maintenance;
                    });
                    this.$store.dispatch("MaintenancesStore/incrementCounter", {name:"en_attente",qty:this.selectedReserves.length});
                });
            },
            handlePriseEnCompte: function(){
                this.MaintenanceMixins_prendreEnCompteMaintenances(this.getSelectedMaintenances).then(()=>{
                    let selectedMaintenances = this.getSelectedMaintenances;
                    let idMaintenances = selectedMaintenances.map((data)=>{
                        return data["id"];
                    });
                    let maintenances = this.getMaintenances.map((maintenance)=>{
                        if(idMaintenances.indexOf(maintenance["id"]) != -1){
                            maintenance["statut"] = "prise_en_compte";
                        }
                        return maintenance;
                    });
                    this.$store.dispatch("MaintenancesStore/incrementCounter", {name:"prise_en_compte",qty:this.selectedReserves.length});
                });
            },
            // END MUST BE REFACTORIZED
            openDialogBoxConfirmationSuppression: function(){
                this.isModalConfirmDeleteOpened = true;
            },
            handleSupprimerMaintenance: function(){
                //console.log(this.selectedReserves)
                let maintenance = this.selectedReserves[0];

                this.MaintenanceMixins_delete(maintenance).then(()=>{
                    this.isModalConfirmDeleteOpened = false;
                    this.$store.dispatch("MaintenancesStore/selectMaintenances", []);
                    this.$store.dispatch("MaintenancesStore/decrementCounter", {name:"En_cours",qty:this.selectedReserves.length});
                    this.$store.dispatch("MaintenancesStore/decrementCounter", {name:"all",qty:this.selectedReserves.length});
                    // il faut decrement aussi sur les onglets qui concerne la maintenance qui est delete
                });
            },

        },
		created: function(){
            this.FiltersMixins_page_name = "reserves";
            this.searchQuery = this.FiltersMixins_getStoredSearchQuery();
		},
		mounted: function(){

		},
        computed:{
            ...mapGetters({
                selectedReserves: 'MaintenancesStore/getSelectedMaintenances',
                reserves: 'MaintenancesStore/getReserves',
                counters: 'MaintenancesStore/getMaintenancesCounters'
            }),
            activeFilters: function(){
                let filtersNames = ["field_dateOuvertureSAV", "field_dateFermetureSAV", "field_path", "field_tiersName"];
                let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
                let nFilters = 0;
                if(this.agfilters.field_dateOuvertureSAV.value || this.agfilters.field_dateFermetureSAV.value) nFilters += 1;
                return activeFilters.length + nFilters;
            },
            getHeaderColor: function(){
                return this.$vgutils.isMobile() ? '#999999' : 'white';
            },
            tabs: function(){
                let tabs = [
                    {label: this.$t("toutes-reserves-en-cours"), name:"toutes", counter: this.counters.all, filters:
                        {
                            field_statut: {attr:"fm.statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"equals"},
                            field_libelleCatgorie: {attr: "cat.libelleCatgorie", value: null, action: "equals"}
                        }
                    }
                ];
                let categories = Object.keys(this.counters);
                categories.shift(); // remove all
                categories.pop(); // remove levees
                categories.forEach((categorie)=>{
                    tabs.push(
                        {label: categorie, name:categorie, counter: this.counters[categorie], filters:
                            {
                                field_statut: {attr:"fm.statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"equals"},
                                field_libelleCatgorie: {attr: "cat.libelleCatgorie", value: categorie, action: "equals"}
                            }
                        }
                    );
                });
                tabs.push(
                    {label: this.$t("levees"), name:"levees", counter: this.counters.levees, filters:
                        {
                            field_statut: {attr:"fm.statut", value:"Resolue", action:"equals"},
                            field_libelleCatgorie: {attr: "cat.libelleCatgorie", value: null, action: "equals"}
                        }
                    }
                );
                return tabs;
    		},
            maintenanceFilters: function(){
                return Object.values(this.agfilters);
            },
            getMesAffectations:function(){
                if(this.$app.role=="ROLE_SOUS_TRAITANT" && this.$app.tiers_id){ 
                    return this.$store.getters["MaintenancesStore/getMaintenancesAffecteeByTiersId"](this.$app.tiers_id);
                }else{ 
                    return this.$store.getters["MaintenancesStore/getMaintenancesAffecteeByIdUser"](this.$app.idUser);
                }
            }
        },



    };
</script>
<style lang="scss">

</style>
