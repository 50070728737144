<template>
    <div>
        <vg-modal
            width="75vw"
            v-show="!isModalConfirmDeleteOpened"
            @close="closeThisForm">
            <template #header v-if="!tache.id">
                {{tache.type_tache?$t("modal-title-"+tache.type_tache):$t("tache-form-add")}} :<span style="font-weight: bold;margin-left:5px;"> {{ tache.libel_tache }}</span>
            </template>
            <template #header v-else>
                {{tache.type_tache?$t("modal-title-update-"+tache.type_tache):$t("tache-form-update")}} :<span style="font-weight: bold;margin-left:5px;"> {{ tache.libel_tache }}</span>
            </template>
            <template #body>
                <vg-input v-if="!hasTypeTache">
                    <vg-select v-model="tache.type_tache"
                        :options="typesTache"
                        :attributeLabel="'label'"
                        :attributeValue="'value'"
                        :clearable="false" />
                </vg-input>

                <vg-tabs :tabs="tabs"
                    type="pane"
                    :color="'gris'"
                    @tabClicked="handleTabClicked"/>
                <br>

                <div v-show="selectedTab=='params-generaux'">
                    <vg-input
                        :title="$t('categorie-'+tache.type_tache)"
                        isRequiredValue>
                        <vg-categorie-selector
                            v-model="tache.idCategorie_id"
                            :isGe="isVerificationLieu"
                            :attributeValue="'id'"
                            :clearable="false"
                            @change="handleCategorieChange" />
                    </vg-input>
                    <vg-input v-if="!isAnUpdateTacheForm"
                        :title="$t('type-tache-recurrente')"
                        isRequiredValue>
                        <vg-select
                            v-model="localTypeTache"
                            :options="localTypesTache"
                            :attributeLabel="'label'"
                            :attributeValue="'value'"
                            :clearable="false"
                            @input="onChangeTypeTache" />
                    </vg-input>

                    <vg-input :title="$t('periodicite')"
                        isRequiredValue>
                        <vg-periodicite-shortcuts v-model="tache.periodicite"
                            @input="onChangePeriodicite"/>
                    </vg-input>

                    <vg-input
                        v-model="tache.dateprochaineVerif"
                        @input="onChangeDateProchaineVerif"
                        inputType="date" 
                        isRequiredValue
                        :title="$t('echeance')" />

                    <!-- -->
                    <vg-tache-tags-datalist v-model="tache.tags" :label="$t('etiquette-tache-recurrente')" style="width:100%;"/>

                    <vg-input v-model="tache.libel_tache"
                        :title="$t('libel-tache')"
                        :placeholder="$t('libel-tache')"
                        isRequiredValue 
                        style="font-weight: bold;"/>

                    <vg-input v-model="tache.averageTime"
                        :title="$t('average-time')"
                        inputType="number"
                        :placeholder="$t('average-time')" />

                    <vg-input :title="$t(isAnUpdateTacheForm?'restriction-site-update':'restriction-site-create')"
                        :isRequiredValue="isAnUpdateTacheForm">
                        <vg-sites-selector v-model="restrictionSites"
                            multiple 
                            showAllOptions
                            attributeValue="id"
                            attributeLabel="libel_lieu"
                            :placeholder="$t('tous-les-sites')"
                            @change="onRestrictionSitesChange"
                            @after-feeded="initRestrictionSites" />
                    </vg-input>
                </div>
                <div v-show="selectedTab=='params-supplementaires'">
                    <vg-input
                        :inputType="'number'"
                        v-model="tache.orderOfApparition"
                        :placeholder="$t('ordre-d-apparition')"
                        :title="$t('ordre-d-apparition')" />

                    <vg-input v-if="isVerificationLieu"
                        :title="$t('display-equipements')">
                        <vg-switch style="margin-left:20px;"
                            :checked="parseInt(tache.isEquipementsDisplayed)"
                            @input="handleSwitchIsEquipementsDisplayed" />
                    </vg-input>

                    <vg-input
                        v-model="tache.createMaintenanceOnNonConformite"
                        :title="$t('generate-maintenance-on-error')"
                        :placeholder="$t('generate-maintenance-on-error')">
                        <vg-switch style="margin-left:20px;"
                            :checked="parseInt(tache.createMaintenanceOnNonConformite)"
                            @input="handleSwitchCreateMaintenanceOnNonConformite" />
                    </vg-input>

                    <vg-input
                        v-model="tache.isActive"
                        :title="$t('isActive')"
                        :placeholder="$t('isActive')">
                        <vg-switch style="margin-left:20px;"
                            :checked="parseInt(tache.isActive)"
                            @input="handleSwitchIsActive" />
                    </vg-input>

                    <vg-input
                        v-model="tache.isScanRequired"
                        :title="$t('isScanRequired')"
                        :placeholder="$t('isScanRequired')">
                        <vg-switch style="margin-left:20px;"
                            :checked="parseInt(tache.isScanRequired)"
                            @input="handleSwitchIsScanRequired" />
                    </vg-input>
                    <vg-input
                        v-model="tache.prisePhotoAfterVerification"
                        :title="$t('prise-photo-after-verification')"
                        :placeholder="$t('prise-photo-after-verification')">
                        <vg-switch style="margin-left:20px;"
                            :checked="tache.prisePhotoAfterVerification"
                            @input="handleSwitchPrisePhotoAfterVerification" />
                    </vg-input>
                    <vg-input v-if="tache.createMaintenanceOnNonConformite"
                        v-model="tache.affectationMaintenancesAfterVerification"
                        :title="$t('affectation-maintenances-after-verification')"
                        :placeholder="$t('affectation-maintenances-after-verification')">
                        <vg-switch style="margin-left:20px;"
                            :checked="tache.affectationMaintenancesAfterVerification"
                            @input="handleSwitchAffectationMaintenancesAfterVerification" />
                    </vg-input>
                    <br>
                    <vg-affectation-tache-users-form 
                        @user-assignation-change="handleUserAssignationChange"
                        :tache="value"
                        :users="affectesId"
                        :isRequiredValue="false"
                        hideTacheSelection
                        hideValidation />
                    <br>
                    <vg-collapse :title="$t('parametres-programmation-session')"
                        :collapseByDefault="false"
                        type="section" >
                        <template #content>
                            <vg-input :title="$t('has-programmation-session')"
                                inline>
                                <vg-checkbox :inputValue="hasProgrammationSession"
                                    :defaultChecked="hasProgrammationSession"
                                    @unchecked="hasProgrammationSession=false;"
                                    @checked="hasProgrammationSession=true;"/>
                            </vg-input>
                            <vg-input v-if="hasProgrammationSession" :title="$t('premiere-date')" inline isRequiredValue>
                                <vg-input v-model="programmationSession.premiereDate"
                                    inputType="date"/>
                                <vg-input v-model="programmationSession.heureDebut"
                                    inputType="time"/>
                            </vg-input>
                            <vg-input v-if="hasProgrammationSession" :title="$t('programmer-tous-les')" inline isRequiredValue>
                                <vg-input v-model="programmationSession.intervalleValue"
                                    inputType="number"
                                    style="width: 70px;"/>
                                <vg-select v-model="programmationSession.intervalleUnite"
                                    :attributeValue="'value'"
                                    :attributeLabel="'label'"
                                    :options="intervalleUnites" 
                                    :clearable="false"/>
                            </vg-input>
                            <vg-input v-if="hasProgrammationSession" :title="$t('exclure')" inline>
                                <vg-button :type="programmationSession.exclureSamedi?'info':'grey'" 
                                    @click="programmationSession.exclureSamedi=!programmationSession.exclureSamedi">
                                    {{ $t("exclure-samedi") }}
                                </vg-button>
                                <vg-button :type="programmationSession.exclureDimanche?'info':'grey'" 
                                    style="margin-left:10px;"
                                    @click="programmationSession.exclureDimanche=!programmationSession.exclureDimanche">
                                    {{ $t("exclure-dimanche") }}
                                </vg-button>
                                <vg-button :type="programmationSession.exclureJourFerie?'info':'grey'" 
                                    style="margin-left:10px;"
                                    disabled
                                    @click="programmationSession.exclureJourFerie=!programmationSession.exclureJourFerie">
                                    {{ $t("exclure-jour-ferie") }}
                                </vg-button>
                            </vg-input>
                            <vg-input v-if="hasProgrammationSession" :title="$t('fixer-jour')" inline>
                                <vg-button :type="programmationSession.isMonday?'info':'grey'"
                                    style="margin-left:10px;"
                                    @click="programmationSession.isMonday=!programmationSession.isMonday">
                                    {{ $t("lundi") }}
                                </vg-button>
                                <vg-button :type="programmationSession.isTuesday?'info':'grey'"
                                    style="margin-left:10px;"
                                    @click="programmationSession.isTuesday=!programmationSession.isTuesday">
                                    {{ $t("mardi") }}
                                </vg-button>
                                <vg-button :type="programmationSession.isWednesday?'info':'grey'"
                                    style="margin-left:10px;"
                                    @click="programmationSession.isWednesday=!programmationSession.isWednesday">
                                    {{ $t("mercredi") }}
                                </vg-button>
                                <vg-button :type="programmationSession.isThursday?'info':'grey'"
                                    style="margin-left:10px;"
                                    @click="programmationSession.isThursday=!programmationSession.isThursday">
                                    {{ $t("jeudi") }}
                                </vg-button>
                                <vg-button :type="programmationSession.isFriday?'info':'grey'"
                                    style="margin-left:10px;"
                                    @click="programmationSession.isFriday=!programmationSession.isFriday">
                                    {{ $t("vendredi") }}
                                </vg-button>
                                <vg-button :type="programmationSession.isSaturday?'info':'grey'"
                                    style="margin-left:10px;"
                                    @click="programmationSession.isSaturday=!programmationSession.isSaturday">
                                    {{ $t("samedi") }}
                                </vg-button>
                                <vg-button :type="programmationSession.isSunday?'info':'grey'"
                                    style="margin-left:10px;"
                                    @click="programmationSession.isSunday=!programmationSession.isSunday">
                                    {{ $t("dimanche") }}
                                </vg-button>
                            </vg-input>
                            <vg-input v-if="hasProgrammationSession" v-model="programmationSession.duree"
                                :title="$t('duree-moyenne-session')" 
                                inputType="number" 
                                inline isRequiredValue />
                            <vg-input v-if="hasProgrammationSession" :title="$t('affectes')"
                                inline>
                                <vg-users-selector v-model="programmationSession.affectes"
                                    multiple
                                    @input="onChangeUsersSelector"/>
                            </vg-input>
                        </template>
                    </vg-collapse>
                </div>
                <div v-show="selectedTab=='params-checkpoints'">
                    <vg-checkpoints-form v-model="tache" 
                        :key="'vg-tache-form-checkpoints-'+tache.id"
                        @update-checkpoints="onUpdateCheckpoints"/>
                </div>
                
            </template>
            <template #footer>
                <vg-button
                    type="default"
                    @click="closeThisForm"
                    >
                    {{$t("tache-form-cancel")}}
                </vg-button>

                <vg-button v-if="isAnUpdateTacheForm"
                    type="danger"
                    @click="isModalConfirmDeleteOpened = true"
                    >
                    {{$t("tache-form-delete")}}
                </vg-button>
                <small v-if="mustHaveARestrictionSite" 
                    style="color: red;">{{ $t("tache-form-restriction-warning") }}</small>
                <vg-button v-if="isAnUpdateTacheForm"
                    type="default-danger"
                    :disabled="isSaveBtnDisabled || isLoading || mustHaveARestrictionSite"
                    @click="handleUpdatetache">
                    {{$t("tache-form-update")}}
                </vg-button>

                <vg-button v-if="!isAnUpdateTacheForm"
                    :disabled="isSaveBtnDisabled || isLoading"
                    type="success"
                    @click="handleCreatetache">
                    {{$t("tache-form-save")}}
                </vg-button>
            </template>
        </vg-modal>
        <dialog-secured-action
          v-show="isModalConfirmDeleteOpened"
          :valid-response="'1'"
          @save="handleDeleteTache"
          @close="isModalConfirmDeleteOpened = false">
          <template v-slot:header-title>
            {{ $t("tache-form-delete") }} <b>{{ tache.libel_tache }}</b>.
          </template>
        </dialog-secured-action>
    </div>
</template>
<script>
import TachesMixins from "src/mixins/TachesMixins.js";
import ComposantMixins from "src/mixins/ComposantMixins.js";
import AffectationsMixins from "src/mixins/AffectationsMixins.js";
import VgModal from "src/components/Vg/VgModal.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgSwitch from 'src/components/Vg/VgSwitch.vue';
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgDatePicker from 'src/components/Vg/VgDatePicker.vue';
import VgGroup from "src/components/Vg/VgGroup.vue";
import VgTabs from "src/components/Vg/VgTabs.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgPeriodiciteShortcuts from "src/components/Vg/VgPeriodiciteShortcuts.vue";
import VgUsersSelector from "src/components/Vg/Selectors/VgUsersSelector.vue";

import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";
import VgTacheTagsDatalist from "src/components/Vg/Datalists/VgTacheTagsDatalist.vue";
import VgCheckpointsForm from "src/components/Vg/Forms/VgCheckpointsForm.vue";
import Metadatas from "src/services/Metadatas.js";
import VgAffectationTacheUsersForm from "src/components/Vg/Forms/VgAffectationTacheUsersForm.vue";
import VgSitesSelector from "src/components/Vg/Selectors/VgSitesSelector.vue";

import { mapGetters } from 'vuex';
    export default {
        name: 'vg-tache-form',
        mixins: [ TachesMixins, ComposantMixins, AffectationsMixins ],
        components:{
            VgModal,
            VgButton,
            VgInput,
            VgSelect,
            VgSwitch,
            VgCategorieSelector,
            VgCollapse,
            VgDatePicker,
            VgCheckpointsForm,
            VgGroup,
            VgTabs,
            DialogSecuredAction,
            VgCheckbox,
            VgPeriodiciteShortcuts,
            VgTacheTagsDatalist,
            VgUsersSelector,
            VgAffectationTacheUsersForm,
            VgSitesSelector
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title-Verification_Lieu": "Créer tâche récurrente check lieu",
            "modal-title-Relever_compteur": "Créer tâche récurrente relevé compteur",
            "modal-title-Relever_Grandeur_physique": "Créer tâche récurrente relevé grandeur physique",
            "modal-title-Verification_equipement": "Créer tâche récurrente équipement",
            "modal-title-update-Verification_Lieu": "Modifier tâche récurrente check lieu",
            "modal-title-update-Relever_compteur": "Modifier tâche récurrente relevé compteur",
            "modal-title-update-Relever_Grandeur_physique": "Modifier tâche récurrente relevé grandeur physique",
            "modal-title-update-Verification_equipement": "Modifier tâche récurrente équipement",
            "tache-form-cancel": "Annuler",
            "tache-form-save": "Enregistrer",
            "tache-form-update": "Modifier",
            "tache-form-delete": "Supprimer",
            "tache-form-add": "Créer tâche récurrente",
            "checkpoints": "Points de vérification",
            "periodicite": "Périodicité",
            "categorie-Verification_equipement": "Catégorie équipement",
            "categorie-Verification_Lieu": "Catégorie lieu",
            "categorie-Relever_compteur": "Catégorie compteur",
            "categorie-Relever_Grandeur_physique": "Catégorie équipement",
            "libel-tache": "Libellé tâche récurrente",
            "echeance": "Prochaine échéance",
            "commentaire-tache": "Description",
            "interne-externe": "Interne/externe",
            "average-time": "Durée estimée tâche récurrente par équipement/lieu (en min)",
            "norme": "Norme",
            "generate-maintenance-on-error": "Génèrer une fiche curative en cas de non conformité?",
            "display-equipements": "Afficher les équipements de la pièce?",
            "isActive": "Tâche récurrente active?",
            "isScanRequired": "Scan équipement requis?",
            "relever-grandeur-physique": "Relever",
            "index-compteur": "Index",
            "interne": "Interne",
            "externe": "Externe",
            "ordre-d-apparition": "Ordre d'apparition page progression",
            "prise-photo-after-verification": "Proposer prise photo après vérification?",
            "affectation-maintenances-after-verification": "Proposer affectation des fiches curatives créées après vérification?",
            "tache-non-definie": "Veuillez définir une catégorie et un libellé",
            "form-not-complete": "Veuillez saisir tous les champs requis",
            "entretien": "Entretien",
            "nettoyage": "Nettoyage",
            "ronde": "Ronde",
            "verification": "Vérification",
            "releve-compteur": "Relevé compteur",
            "type-tache-recurrente": "Type tâche récurrente",
            "etiquette-tache-recurrente": "Etiquette tâche récurrente",
            "parametres-generaux": "Paramètres généraux",
            "parametres-supplementaires": "Paramètres supplémentaires",
            "parametres-programmation-session": "Paramètres programmation de session (optionnel)",
            "points-de-verification": "Points de vérification",
            "libel-tache-type-entretien": "Entretien",
            "libel-tache-type-nettoyage": "Nettoyage",
            "libel-tache-type-ronde": "Ronde",
            "libel-tache-type-verification": "Vérification",
            "libel-tache-type-entretien-periodicite-1": "Entretien journalier",
            "libel-tache-type-nettoyage-periodicite-1": "Nettoyage journalier",
            "libel-tache-type-ronde-periodicite-1": "Ronde journalière",
            "libel-tache-type-verification-periodicite-1": "Vérification journalière",
            "libel-tache-type-releve-compteur-periodicite-1": "Relevé compteur journalier",
            "libel-tache-type-entretien-periodicite-7": "Entretien hebdomadaire",
            "libel-tache-type-nettoyage-periodicite-7": "Nettoyage hebdomadaire",
            "libel-tache-type-ronde-periodicite-7": "Ronde hebdomadaire",
            "libel-tache-type-verification-periodicite-7": "Vérification hebdomadaire",
            "libel-tache-type-releve-compteur-periodicite-7": "Relevé compteur hebdomadaire",
            "libel-tache-type-entretien-periodicite-30": "Entretien mensuel",
            "libel-tache-type-nettoyage-periodicite-30": "Nettoyage mensuel",
            "libel-tache-type-ronde-periodicite-30": "Ronde mensuelle",
            "libel-tache-type-verification-periodicite-30": "Vérification mensuelle",
            "libel-tache-type-releve-compteur-periodicite-30": "Relevé compteur mensuel",
            "libel-tache-type-entretien-periodicite-90": "Entretien trimestriel",
            "libel-tache-type-nettoyage-periodicite-90": "Nettoyage trimestriel",
            "libel-tache-type-ronde-periodicite-90": "Ronde trimestrielle",
            "libel-tache-type-verification-periodicite-90": "Vérification trimestrielle",
            "libel-tache-type-releve-compteur-periodicite-90": "Relevé compteur trimestriel",
            "libel-tache-type-entretien-periodicite-180": "Entretien bi-annuel",
            "libel-tache-type-nettoyage-periodicite-180": "Nettoyage bi-annuel",
            "libel-tache-type-ronde-periodicite-180": "Ronde bi-annuelle",
            "libel-tache-type-verification-periodicite-180": "Vérification bi-annuelle",
            "libel-tache-type-releve-compteur-periodicite-180": "Relevé compteur bi-annuel",
            "libel-tache-type-entretien-periodicite-365": "Entretien annuel",
            "libel-tache-type-nettoyage-periodicite-365": "Nettoyage annuel",
            "libel-tache-type-ronde-periodicite-365": "Ronde annuelle",
            "libel-tache-type-verification-periodicite-365": "Vérification annuelle",
            "libel-tache-type-releve-compteur-periodicite-365": "Relevé compteur annuel",
            "libel-tache-periodicite-1": "Journalière",
            "libel-tache-periodicite-7": "Hebdomadaire",
            "libel-tache-periodicite-30": "Mensuelle",
            "libel-tache-periodicite-90": "Trimestrielle",
            "libel-tache-periodicite-180": "Bi-annuelle",
            "libel-tache-periodicite-365": "Annuelle",
            "premiere-date": "Première date",
            "programmer-tous-les": "Programmer tous les",
            "exclure": "Exclure les jours",
            "exclure-samedi": "Samedi",
            "exclure-dimanche": "Dimanche",
            "exclure-jour-ferie": "Férié",
            "fixer-jour": "Fixer jour de la semaine",
            "duree-moyenne-session": "Durée moyenne session (en min)",
            "lundi": "L",
            "mardi": "M",
            "mercredi": "M",
            "jeudi": "J",
            "vendredi": "V",
            "samedi": "S",
            "dimanche": "D",
            "jours": "jours",
            "semaines": "semaines",
            "mois": "mois",
            "trimestres": "trimestres",
            "semestres": "semestres",
            "annees": "années",
            "has-programmation-session": "Programmer une session",
            "affectes": "Affectés",
            "tache-Verification_Lieu": "Tâche récurrente lieux",
            "tache-Verification_equipement": "Tâche récurrente équipements",
            "tache-Relever_Grandeur_physique": "Tâche récurrente grandeurs physique",
            "tache-Relever_compteur": "Tâche récurrente compteurs",
            "type-tache": "Type tâche",
            "gerer-affectation":"Gérer les affectations",
            "tab-params-generaux": "Paramètres généraux",
            "tab-params-supplementaires": "Paramètres supplémentaires",
            "tab-params-checkpoints": "Points de vérification",
            "checkpoints-requis": "Points de vérification requis",
            "restriction-site-create": "Attacher la tâche récurrente aux sites (par défaut tous les sites)",
            "restriction-site-update": "Attacher la tâche récurrente aux sites",
            "tous-les-sites": "Tous les sites",
            "tache-form-restriction-warning": "Veuillez attacher la tâche récurrente à au moins un site"
        },
        "en": {
            "modal-title-Verification_Lieu": "Create recurrent task check room",
            "modal-title-Relever_compteur": "Create recurrent task meter reading",
            "modal-title-Relever_Grandeur_physique": "Create recurrent task physical quantity reading",
            "modal-title-Verification_equipement": "Create recurrent task equipment",
            "modal-title-update-Verification_Lieu": "Update recurrent task check room",
            "modal-title-update-Relever_compteur": "Update recurrent task meter reading",
            "modal-title-update-Relever_Grandeur_physique": "Update recurrent task physical quantity reading",
            "modal-title-update-Verification_equipement": "Update recurrent task equipment",
            "tache-form-cancel": "Cancel",
            "tache-form-save": "Save",
            "tache-form-update": "Update",
            "tache-form-delete": "Delete",
            "tache-form-add": "Create recurrent task",
            "checkpoints": "Checkpoints",
            "periodicite": "Periodicity",
            "categorie-Verification_equipement": "Equipment category",
            "categorie-Verification_Lieu": "Room category",
            "categorie-Relever_compteur": "Meter category",
            "categorie-Relever_Grandeur_physique": "Equipment category",
            "libel-tache": "Recurrent task name",
            "echeance": "Next deadline",
            "commentaire-tache": "Description",
            "interne-externe": "Interne/Supplier",
            "average-time": "Estimated recurring task duration by equipment/location",
            "norme": "Standard",
            "generate-maintenance-on-error": "Create a curative sheet on a non compliant verification?",
            "display-equipements": "Show room equipments",
            "isActive": "Is Recurrent task active?",
            "isScanRequired": "QrCode reading required ?",
            "relever-grandeur-physique": "Reading",
            "index-compteur": "Reading counter",
            "interne": "Interne",
            "externe": "Supplier",
            "ordre-d-apparition": "Appearance order progress page",
            "prise-photo-after-verification": "Ask an optionnal photo after verification?",
            "affectation-maintenances-after-verification": "Ask an optionnal assignment of curative sheets created after verification?",
            "tache-non-definie": "Please define a task category and a task label",
            "form-not-complete": "Please enter all required fields",
            "entretien": "Maintenance",
            "nettoyage": "Cleaning",
            "ronde": "Round",
            "verification": "Check",
            "releve-compteur": "Meter reading",
            "type-tache-recurrente": "Recurrent task type",
            "etiquette-tache-recurrente": "Recurrent task tag",
            "parametres-generaux": "General settings",
            "parametres-supplementaires": "Additional settings",
            "parametres-programmation-session": "Session scheduling parameters (optional)",
            "points-de-verification": "Checkpoints",
            "libel-tache-type-entretien": "Maintenance",
            "libel-tache-type-nettoyage": "Cleaning",
            "libel-tache-type-ronde": "Round",
            "libel-tache-type-verification": "Check",
            "libel-tache-type-entretien-periodicite-1": "Daily maintenance",
            "libel-tache-type-nettoyage-periodicite-1": "Daily cleaning",
            "libel-tache-type-ronde-periodicite-1": "Daily round",
            "libel-tache-type-verification-periodicite-1": "Daily check",
            "libel-tache-type-entretien-periodicite-7": "Weekly maintenance",
            "libel-tache-type-nettoyage-periodicite-7": "Weekly cleaning",
            "libel-tache-type-ronde-periodicite-7": "Weekly round",
            "libel-tache-type-verification-periodicite-7": "Weekly check",
            "libel-tache-type-entretien-periodicite-30": "Monthly maintenance",
            "libel-tache-type-nettoyage-periodicite-30": "Monthly cleaning",
            "libel-tache-type-ronde-periodicite-30": "Monthly round",
            "libel-tache-type-verification-periodicite-30": "Monthly check",
            "libel-tache-type-entretien-periodicite-90": "Quarterly maintenance",
            "libel-tache-type-nettoyage-periodicite-90": "Quarterly leaning",
            "libel-tache-type-ronde-periodicite-90": "Quarterly round",
            "libel-tache-type-verification-periodicite-90": "Quarterly check",
            "libel-tache-type-entretien-periodicite-180": "Bi-annual maintenance",
            "libel-tache-type-nettoyage-periodicite-180": "Bi-annual cleaning",
            "libel-tache-type-ronde-periodicite-180": "Bi-annual round",
            "libel-tache-type-verification-periodicite-180": "Bi-annual check",
            "libel-tache-type-entretien-periodicite-365": "Annual maintenance",
            "libel-tache-type-nettoyage-periodicite-365": "Annual cleaning",
            "libel-tache-type-ronde-periodicite-365": "Annual round",
            "libel-tache-type-verification-periodicite-365": "Annual check",
            "libel-tache-periodicite-1": "Daily",
            "libel-tache-periodicite-7": "Weekly",
            "libel-tache-periodicite-30": "Monthly",
            "libel-tache-periodicite-90": "Quarterly",
            "libel-tache-periodicite-180": "Bi-annual",
            "libel-tache-periodicite-365": "Annual",
            "premiere-date": "First date",
            "programmer-tous-les": "Schedule all",
            "exclure": "Exclude days",
            "exclure-samedi": "Saturday",
            "exclure-dimanche": "Sunday",
            "exclure-jour-ferie": "Public holiday",
            "fixer-jour": "Set days of week",
            "duree-moyenne-session": "Average session duration (minutes)",
            "lundi": "M",
            "mardi": "T",
            "mercredi": "W",
            "jeudi": "T",
            "vendredi": "F",
            "samedi": "S",
            "dimanche": "S",
            "jours": "days",
            "semaines": "weeks",
            "mois": "months",
            "trimestres": "quarters",
            "semestres": "semesters",
            "annees": "years",
            "has-programmation-session": "Schedule a session",
            "affectes": "assigned",
            "tache-Verification_Lieu": "Recurrent room task",
            "tache-Verification_equipement": "Recurrent equipment task",
            "tache-Relever_Grandeur_physique": "Recurrent physical quantity task",
            "tache-Relever_compteur": "Recurrent meter task",
            "type-tache": "Task type",
            "gerer-affectation":"Manage assignments",
            "checkpoints-requis": "Required checkpoints",
            "restriction-site-create": "Attach the recurrent task to the sites (default all sites)",
            "restriction-site-update": "Attach the recurrent task to the sites",
            "tous-les-sites": "All sites",
            "tache-form-restriction-warning": "Please attach the recurrent task to at least one site"
        }
    }
},
        props: {
            /**
            * @model
            */
            value:{
                type: Object,
                default:function(){
                    return {};
                }
            },
            typeTache:{
                type: String,
                default:function(){
                    return "Verification_Lieu";
                }
            },
            hasTypeTache:{
                type: Boolean,
                default: true
            }
        },
        data: function() {
            return {
                selectedTab: "params-generaux",
                isLoading: false,
                isTacheAffectationFormOpened:false,
                tache:{
                    libel_tache:null,
                    idCategorie_id:null,
                    type_tache:this.hasTypeTache?this.typeTache:"Verification_equipement",
                    userId:this.$app.appID,
                    periodicite:7,
                    isEquipementsDisplayed: "1",
                    intex:"interne",
                    commentaire_tache:"",
                    dateprochaineVerif:null,
                    prisePhotoAfterVerification:false,
                    norme:null,
                    isActive:"1",
                    createMaintenanceOnNonConformite:"1",
                    isScanRequired:"1",
                    averageTime:5,
                    checkpoints:[],
                    tags: null,
                    assignation:[],
                    orderOfApparition:0
                },
                restrictionSites: null,
                hasProgrammationSession: false,
                programmationSession:{
                    premiereDate: moment().format("YYYY-MM-DD"),
                    heureDebut: "08:00",
                    intervalleValue: 1,
                    intervalleUnite: 7,
                    exclureSamedi: false,
                    exclureDimanche: false,
                    exclureJourFerie: false,
                    isMonday: false,
                    isTuesday: false,
                    isWednesday: false,
                    isThursday: false,
                    isFriday: false,
                    isSaturday: false,
                    isSunday: false,
                    duree: 60,
                    affectes: []
                },
                intervalleUnites: [
                    {label: this.$t("jours"), value: 1},
                    {label: this.$t("semaines"), value: 7},
                    {label: this.$t("mois"), value: 30},
                    {label: this.$t("trimestres"), value: 90},
                    {label: this.$t("semestres"), value: 180},
                    {label: this.$t("annees"), value: 365}
                ],
                displayCheckpointForm:true,
                isModalConfirmDeleteOpened:false,
                localTypeTache: null,
                localTypesTache: [
                    {label: this.$t("entretien"), value: "entretien"},
                    {label: this.$t("nettoyage"), value: "nettoyage"},
                    {label: this.$t("ronde"), value: "ronde"},
                    {label: this.$t("verification"), value: "verification"},
                    {label: this.$t("releve-compteur"), value: "releve-compteur"}
                ],
                updatedTacheSites: null
            };
        },
        computed:{
            ...mapGetters({
                  getSelectedTache: 'TachesStore/getSelectedItem',
                  categories: 'CategoriesStore/getCollection',
                  sites: 'LieuxStore/getSites'
            }),
            mustHaveARestrictionSite: function(){
                return this.isAnUpdateTacheForm && (!this.restrictionSites || this.restrictionSites.length==0)
            },
            isSaveBtnDisabled: function(){
                return !this.tache.libel_tache 
                    || !this.tache.idCategorie_id 
                    || !this.tache.type_tache 
                    || (!this.isAnUpdateTacheForm && !this.localTypeTache)
                    || this.tache.checkpoints.length==0 
                    || !this.tache.periodicite;
            },
            /**
            * @return {boolean}
            */
            isAnUpdateTacheForm:function(){
                return this.tache && this.tache.hasOwnProperty('id') && this.tache.id;
            },
            /**
            * @return {boolean}
            */
            isVerificationLieu:function(){
                return this.tache.type_tache === "Verification_Lieu";
            },
            /**
            * @return {boolean}
            */
            isReleverCompteur:function(){
                return this.tache.type_tache === "Relever_compteur";
            },
            /**
            * @return {boolean}
            */
            isReleverGrandeurPhysique:function(){
                return this.tache.type_tache === "Relever_Grandeur_physique";
            },
            isValidTache:function(){
                let libelTacheExists = this.tache.libel_tache != null && this.tache.libel_tache != "";
                let categorieIsSet = this.tache.idCategorie_id != null;
                return libelTacheExists && categorieIsSet;
            },
            getSessionDayOfWeek: function(){
                if(this.programmationSession.isMonday) return 1;
                else if(this.programmationSession.isTuesday) return 2;
                else if(this.programmationSession.isWednesday) return 3;
                else if(this.programmationSession.isThursday) return 4;
                else if(this.programmationSession.isFriday) return 5;
                else if(this.programmationSession.isSaturday) return 6;
                else if(this.programmationSession.isSunday) return 7;
                else return null;
            },
            typesTache: function(){
                return [
                    {label: this.$t("tache-Verification_Lieu"), value:"Verification_Lieu"}, 
                    {label: this.$t("tache-Relever_compteur"), value:"Relever_compteur"}, 
                    {label: this.$t("tache-Relever_Grandeur_physique"), value:"Relever_Grandeur_physique"}, 
                    {label: this.$t("tache-Verification_equipement"), value:"Verification_equipement"}
                ];
            },
            affectesId:function(){
                return this.value && this.value.assignation && this.value.assignation.length? this.value.assignation : [];
            },
            tabs: function() {
                return [
                    {
                        label: this.$t('tab-params-generaux'),
                        name: "params-generaux"
                    },
                    {
                        label: this.$t('tab-params-supplementaires'),
                        name: "params-supplementaires"
                    },
                    {
                        label: this.$t('tab-params-checkpoints'),
                        name: "params-checkpoints",
                        counter:this.tache && this.tache.checkpoints?this.tache.checkpoints.length:0,
                        isWarning: this.tache && (!this.tache.checkpoints || this.tache.checkpoints.length==0)?true:false,
                        messageWarning: this.$t("checkpoints-requis")
                    }
                ];
            },
            getUpdatedTacheSites: function(){
                let tacheSites = {};
                let associatedSitesIds = [];    // liste ids sites associés à la tache avant modif
                this.tache.tacheSites.forEach((tachesite)=>{
                    tacheSites[tachesite.site_id] = tachesite;
                    associatedSitesIds.push(tachesite.site_id);
                });
                this.restrictionSites.forEach((siteId)=>{
                    if(!tacheSites[siteId]){    // si site n'est pas inclu dans la restriction tache avant modif -> on l'ajoute
                        tacheSites[siteId] = {
                            tache_id: this.tache.id,
                            site_id: siteId
                        };
                    }else{ // sinon on ne fait rien
                        
                    }
                    let indexSiteId = associatedSitesIds.indexOf(siteId);
                    if(indexSiteId!=-1) associatedSitesIds.splice(indexSiteId, 1);
                });
                associatedSitesIds.forEach((siteId)=>{ // on archive toutes les autres restrictions site avant modif
                    tacheSites[siteId].isArchived = 1;
                });
                return Object.values(tacheSites);
            }
        },
        created: function() {
            this.setDateProchaineVerif(7);
            if(!this.isAnUpdateTacheForm){
                if(this.isReleverGrandeurPhysique){
                    this.tache.checkpoints.push(this.getCheckpointGrandeurPhysique());
                }
                else if(this.isReleverCompteur){
                    this.tache.checkpoints.push(this.getCheckpointIndexCompteur());
                }
                if(!this.hasProgrammationSession && this.value.affectation && this.value.affectation.affectes && this.value.affectation.affectes.length){
                    this.programmationSession.affectes = this.affectesId
                }
            }
            this.tache = Object.assign({}, this.tache, this.value);
            //this.initRestrictionSites();
            this.initProgrammationSession();
        },
        mounted: function(){
            if(!this.tache.id) this.setLibelTache();
        },
        methods: {
            initRestrictionSites: function(){
                if(this.tache.id && this.tache.tacheSites){
                    this.restrictionSites = this.tache.tacheSites.length!=0?[...this.tache.tacheSites.map((tachesite)=>tachesite.site_id)]:[];
                }
            },
            onRestrictionSitesChange: function(sitesIds){
                this.restrictionSites = [...sitesIds];
            },
            handleTabClicked: function(tab){
                this.selectedTab = tab.name;
            },
            handleUserAssignationChange(assignation){
                console.log(assignation);
                this.tache.assignation = assignation;
            },
            createAffectationUserTache: function(idUser){
                let affectes = [
                    {
                        affectation_id: this.tache.affectation.id,
                        user_id: idUser,
                        tache_id: this.tache.id,
                        userId: this.$app.appID
                    }
                ];
                this.AffectationsMixins_createAffectationsUsersTaches(affectes).then((affectes)=>{
                    
                });
            },
            deleteAffectationUserTache: function(affecte){
                this.AffectationsMixins_deleteAffectationsUsersTaches(affecte).then((datas)=>{
                    
                });
            },
            onChangeUsersSelector: function(users){
                if(this.tache.affectation && this.tache.affectation.hasOwnProperty("affectes") && this.tache.affectation.affectes){
                    let idUserAjoute = users.find((idUser)=>this.tache.affectation.affectes.findIndex((affecte)=>affecte.affectationusertache_user_id==idUser)==-1);
                    let indexAffectationUserManquant = this.tache.affectation.affectes.findIndex((affu)=>users.includes(affu.affectationusertache_user_id)==false);
                    if(idUserAjoute){   // rechercher si users contient un user de plus par rapport à tache.affectation.affectes
                        this.createAffectationUserTache(idUserAjoute);
                    }else if(indexAffectationUserManquant!=-1){   // rechercher si manque un users par rapport à tache.affectation.affectes
                        this.deleteAffectationUserTache(this.tache.affectation.affectes[indexAffectationUserManquant]);
                    }else{
                        // do nothing
                    }
                }else if(this.tache.affectation){
                    // POST affectationusertache
                    this.createAffectationUserTache(users[0]);
                }
            },
            onChangeDateProchaineVerif: function(date){
                console.log({date})
                if(date == "") this.setDateProchaineVerif(this.tache.periodicite);
                else this.tache.dateprochaineVerif = date;
            },
            initProgrammationSession: function(){
                if(this.tache.hasOwnProperty("affectation") && this.tache.affectation) {
                    this.programmationSession = {
                        id: this.tache.affectation.id,
                        premiereDate: moment(this.tache.affectation.start).format("YYYY-MM-DD"),
                        heureDebut: moment(this.tache.affectation.start).format("HH:mm"),
                        intervalleValue: this.tache.affectation.recurrence,
                        intervalleUnite: 1,
                        exclureSamedi: this.tache.affectation.excludeSaturday,
                        exclureDimanche: this.tache.affectation.excludeSunday,
                        exclureJourFerie: this.tache.affectation.excludePublicHoliday,
                        isMonday: this.tache.affectation.dayOfWeek==1,
                        isTuesday: this.tache.affectation.dayOfWeek==2,
                        isWednesday: this.tache.affectation.dayOfWeek==3,
                        isThursday: this.tache.affectation.dayOfWeek==4,
                        isFriday: this.tache.affectation.dayOfWeek==5,
                        isSaturday: this.tache.affectation.dayOfWeek==6,
                        isSunday: this.tache.affectation.dayOfWeek==7,
                        duree: this.tache.affectation.durationMinutes,
                        affectes: this.tache.affectation.hasOwnProperty("affectes") && this.tache.affectation.affectes ? this.tache.affectation.affectes.map((affecte)=>affecte.affectationusertache_user_id) : []
                    };
                    this.hasProgrammationSession = true;
                }else{
                    this.programmationSession = {
                        premiereDate: moment().format("YYYY-MM-DD"),
                        heureDebut: "08:00",
                        intervalleValue: 1,
                        intervalleUnite: 7,
                        exclureSamedi: false,
                        exclureDimanche: false,
                        exclureJourFerie: false,
                        isMonday: false,
                        isTuesday: false,
                        isWednesday: false,
                        isThursday: false,
                        isFriday: false,
                        isSaturday: false,
                        isSunday: false,
                        duree: 60,
                        affectes: this.affectesId
                    };
                    this.hasProgrammationSession = false;
                }
            },
            onUpdateCheckpoints: function(checkpoints){
                this.tache.checkpoints = [...checkpoints];
            },
            deleteAffectation: function(affectation){
                this.AffectationsMixins_deleteAffectation(affectation.id).then((datas)=>this.$emit("updated", this.tache));
            },
            handleUpdatetache:function(){
                if(!this.isValidTache) alert(this.$t('tache-non-definie'));
                let deleteAffectation = false;
                let affectationToDelete = null;
                if(this.hasProgrammationSession) this.tache.affectation = this.formatProgrammationSessionAvantSauvegarde();
                else if(!this.hasProgrammationSession && this.tache.affectation){ 
                    affectationToDelete = Object.assign({}, {}, this.tache.affectation);
                    delete this.tache.affectation;
                    deleteAffectation = true;
                }
                this.tachesMixins_updateTache(this.tache, this.getUpdatedTacheSites).then(()=>{
                    if(!deleteAffectation) this.$emit("updated", this.tache);
                    else this.deleteAffectation(affectationToDelete);
                });
            },
            /**
             * handleSwitchCreateMaintenanceOnNonConformite.
             * @param {boolean} value
             */
            handleSwitchCreateMaintenanceOnNonConformite:function(value){
                console.log(value);
                this.tache.createMaintenanceOnNonConformite = value;
            },
            /**
             * handleSwitchIsActive.
             * @param {boolean} value
             */
            handleSwitchIsEquipementsDisplayed:function(value){
                this.tache.isEquipementsDisplayed = value;
            },
            /**
             * handleSwitchIsActive.
             * @param {boolean} value
             */
            handleSwitchIsActive:function(value){
                console.log(value);
                this.tache.isActive = value;
            },
            /**
             * handleSwitchIsScanRequired.
             * @param {boolean} value
             */
            handleSwitchIsScanRequired:function(value){
                console.log(value);
                this.tache.isScanRequired = value;
            },
            /**
             * handleSwitchPrisePhotoAfterVerification.
             * @param {boolean} value
             */
             handleSwitchPrisePhotoAfterVerification:function(value){
                console.log(value);
                this.tache.prisePhotoAfterVerification = value;
            },
            /**
             * handleSwitchAffectationMaintenancesAfterVerification.
             * @param {boolean} value
             */
             handleSwitchAffectationMaintenancesAfterVerification:function(value){
                this.tache.affectationMaintenancesAfterVerification = value;
            },
            /**
             * handleCreatetache - description
             * @event created taches collection is dispatched
             * @event close
             */
            handleCreatetache:function(){
                this.isLoading = true;
                if(!this.tache.idCategorie_id || !this.tache.libel_tache){
                    alert(this.$t('form-not-complete'));
                    return;
                }
                if(this.hasProgrammationSession) this.tache.affectation = this.formatProgrammationSessionAvantSauvegarde();
                let restrictionSites = this.restrictionSites && this.restrictionSites.length!=0?this.restrictionSites:this.sites.map((site)=>site.id);
                this.tachesMixins_createTaches([this.tache], restrictionSites).then((taches)=>{
                    this.isLoading = false;
                    this.$emit("created",taches);
                    //this.closeThisForm();
                });

            },
            formatProgrammationSessionAvantSauvegarde: function(){
                let session = {
                    affectant_id: this.$app.idUser,
                    dateAffectation: moment().format("YYYY-MM-DD HH:mm"),
                    start: this.programmationSession.premiereDate+" "+this.programmationSession.heureDebut,
                    end: moment(this.programmationSession.premiereDate+" "+this.programmationSession.heureDebut).add(this.programmationSession.duree, "minutes").format("YYYY-MM-DD HH:mm"),
                    recurrence: this.programmationSession.intervalleValue*this.programmationSession.intervalleUnite,
                    dateFinRecurrence: null,
                    excludeSaturday: this.programmationSession.exclureSamedi,
                    excludeSunday: this.programmationSession.exclureDimanche,
                    excludePublicHoliday: this.programmationSession.exclureJourFerie,
                    dayOfWeek: this.getSessionDayOfWeek,
                    durationMinutes: this.programmationSession.duree,
                    affectes: this.programmationSession.affectes,
                    userId: this.$app.appID
                };
                if(this.programmationSession.hasOwnProperty("id") && this.programmationSession.id) session.id = this.programmationSession.id;
                return session;
            },
            handleDeleteTache:function(){
                this.tachesMixins_deleteTache(this.tache).then(()=>{
                    this.$emit("deleted",this.tache);
                    this.closeThisForm();
                });
            },
            setLibelTache: function(){
                let libelTache = "";
                let periodicitesConvertibles = [1, 7, 30, 90, 180, 365];
                let traductionReference = "libel-tache";
                if(this.localTypeTache) traductionReference += "-type-"+this.localTypeTache;
                if(this.tache.periodicite && periodicitesConvertibles.includes(this.tache.periodicite)) traductionReference += "-periodicite-"+this.tache.periodicite;
                if(traductionReference!="libel-tache") libelTache += this.$t(traductionReference);
                if(this.tache.idCategorie_id){
                    let categorie = this.categories.find((cat)=>cat.id==this.tache.idCategorie_id);
                    libelTache += " "+categorie.libelleCatgorie;
                }
                this.tache.libel_tache = libelTache;
            },
            onChangeTypeTache: function(){
                this.setLibelTache();
            },
            onChangePeriodicite: function(periodicite){
                this.setLibelTache();
                this.setDateProchaineVerif(periodicite);
            },
            setDateProchaineVerif: function(periodicite){
                this.tache.dateprochaineVerif = moment().add(periodicite, "days").format("YYYY-MM-DD");
            },
            handleAddCheckpoints:function(checkpoints){
                this.tache.checkpoints = checkpoints;
            },

            /**
             * HandleCategorieChange.
             * @method handleCategorieChange
             * @param  {Integer} categorieId description
             * @return {array}   An array where each object are composants
             */
            handleCategorieChange:function(categorieId){
                this.setLibelTache();
                if(this.isVerificationLieu){
                    let metadatasComposants = new Metadatas();
                    metadatasComposants.setFilters(
                        {
                            categorieId:{"attr":"cat.id","value":categorieId,"action":"equals"}
                        }
                    );
                    this.ComposantMixins_getComposants(metadatasComposants).then((composants)=>{
                        if(!this.isAnUpdateTacheForm){
                            this.tache.checkpoints = this.transformComposantsToCheckpoints(composants);
                        }

                    });
                }
            },
            /**
             * TransformComposantsToCheckpoints.
             * @method transformComposantsToCheckpoints
             * @param  {array} composants array of object
             * @return {array} array of checkpoints
             */
            transformComposantsToCheckpoints:function(composants){
                let checkpoints = [];
                composants.forEach((composant, i) => {
                    checkpoints.push({
                        question: composant.libelComposant,
                        type_reponse: "Oui/Non",
                        rubrique: null,
                        messageOnVerifError: null,
                        orderOfAppearance: 0,
                        userId: this.$app.appID,
                        minVal: null,
                        maxVal: null,
                        idTache_id:null,
                        composant_id:composant.id,
                        seuils:[],
                        consommable_id: null,
                        consommable: null
                    });
                });
                return checkpoints;
            },
            closeThisForm:function(){
                //this.$store.dispatch("TachesStore/setSelectedItem", null);
                this.$emit("close");
            },/**
            *
            * @return {object}
            */
            getCheckpointIndexCompteur:function(){
                return {
                    question: this.$t("relever-grandeur-physique"),
                    type_reponse: "Index",
                    rubrique: null,
                    messageOnVerifError: " ",
                    orderOfAppearance: 0,
                    userId: this.$app.appID,
                    minVal: null,
                    maxVal: null,
                    idTache_id:null,
                    composant_id:null,
                    seuils:[],
                    consommable_id: null,
                    consommable: null
                }
            },
            /**
            * @return {object}
            */
            getCheckpointGrandeurPhysique:function(){
                return {
                    question: this.$t("relever-grandeur-physique"),
                    type_reponse: "Nombre",
                    rubrique: null,
                    messageOnVerifError: " ",
                    orderOfAppearance: 0,
                    userId: this.$app.appID,
                    minVal: 0,
                    maxVal: 100,
                    idTache_id:null,
                    composant_id:null,
                    seuils:[],
                    consommable_id: null,
                    consommable: null
                }
            }
        }
    };
</script>
<style lang="scss" scoped>

</style>
<docs>

    ### vg-tache-form exemple:

    #### basic usage
    Comment here
    ```html static
          <vg-tache-form></vg-tache-form>
    ```

    ### Create form tache
    ```js
    new Vue({
      data(){
        return {}
      },
      template: `
        <div>
            <vg-tache-form>
            </vg-tache-form>

        </div>
      `
    })
    ```
    ### update form tache
    ```js
    new Vue({
      data(){
        return {
            tache:{id:1}
        }
      },
      template: `
        <div>
            <vg-tache-form v-model="tache">
            </vg-tache-form>

        </div>
      `
    })
    ```
</docs>
