<template>
    <div id="reader" width="600px"></div>
</template>

<script>
import {Html5Qrcode} from "html5-qrcode";

export default {
    name: "zxing-scanner",
    data() {
        return {
            qrCodeScanner: null,
        };
    },
    mounted() {
        // This method will trigger user permissions
        Html5Qrcode.getCameras().then(devices => {
        /**
         * devices would be an array of objects of type:
         * { id: "id", label: "label" }
         */
        if (devices && devices.length) {
            console.log("devices", devices);
            var cameraId = devices[devices.length-1].id;
            this.qrCodeScanner = new Html5Qrcode(/* element id */ "reader");
            this.qrCodeScanner.start(
            cameraId, 
            {
                fps: 10,    // Optional, frame per seconds for qr code scanning
                qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
            },
            this.onScanSuccess,
            this.onScanFailure)
            .catch((err) => {
            // Start failed, handle it.
            });
        }
        }).catch(err => {
        // handle err
        });

    },
    methods: {
        onScanSuccess(decodedText, decodedResult) {
            this.$emit("scanSuccess", decodedText);
        },
        onScanFailure(error) {
            
        }
    },
    beforeDestroy() {
        this.qrCodeScanner.stop().then((ignore) => {
        // QR Code scanning is stopped.
        }).catch((err) => {
        // Stop failed, handle it.
        });
    },
}
</script>