<template>
	<div style="height: 100%;">
		<tag-grid
			:idTableau="'consommable-mouvements-tableau'"
			:defaultHeight="defaultHeight"
			v-model="gridOptions"
			:columnDefs="columnDefs"
			:rowData="mouvements"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
			/>
        <vg-pagination v-if="paginate"
            :totalItems="getTotalItems"
            :offset="offset"
            :limit="limit"
            @pagination-change="handlePaginationChange" />
		<vg-bon-de-sortie-form v-if="showUpdateBonDeSortie"
            v-model="focusedBonDeSortie"
            @close="showUpdateBonDeSortie=false;"
            @update="showUpdateBonDeSortie=false;fetch();"/>
        <vg-bon-de-sortie-signature v-if="showSignatureBonDeSortie"
            v-model="focusedBonDeSortie"
            @close="showSignatureBonDeSortie=false;"
            @update="showSignatureBonDeSortie=false;"
            @save="showSignatureBonDeSortie=false;fetch();"/>
		<vg-file-viewer v-if="selectedFile" :file="selectedFile" @close="selectedFile=null"/>
	</div>
</template>
<script>
	import TagGrid from 'src/components/Grid/TagGrid.vue';
	import TagGridMixins from 'src/mixins/TagGridMixins.js';

	import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";

	import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
	import DatetimeCellRender from "src/components/Vg/TagGrid/DatetimeCellRender.vue";
	import FlagConsommableMouvementsCellRender from "src/components/Vg/TagGrid/FlagConsommableMouvementsCellRender.vue";
	import ConsommableMouvementsCellRender from "src/components/Vg/TagGrid/ConsommableMouvementsCellRender.vue";
	import BonDeSortieActionsCellRender from "src/components/Vg/TagGrid/BonDeSortieActionsCellRender.vue";
	import VgCostCellRender from "src/components/Vg/TagGrid/VgCostCellRender.vue";
	import QuantiteMouvementCellRender from "src/components/Vg/TagGrid/QuantiteMouvementCellRender.vue";
	import VgPagination from "src/components/Vg/VgPagination.vue";
	import VgFileViewer from 'src/components/Vg/Files/VgFileViewer.vue';
	import VgBonDeSortieForm from "src/components/Vg/Forms/VgBonDeSortieForm.vue";
	import VgBonDeSortieSignature from "src/components/Vg/BonsDeSortie/VgBonDeSortieSignature.vue";

	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-consommable-mouvements-table',
		components: {
			TagGrid,
			IconNameCellRender,
			DatetimeCellRender,
			FlagConsommableMouvementsCellRender,
			ConsommableMouvementsCellRender,
			VgPagination,
			VgBonDeSortieForm,
			VgBonDeSortieSignature,
			VgFileViewer
		},
		mixins: [ TagGridMixins, ConsommablesMixins ],
        props: {
            filters:{
				type: Object,
				default: function(){
					return {};
				}
			},
			defaultHeight:{
				type: String,
				default: '200px'
			},
			/**
			* paginate
			*/
			paginate: {
				type: Boolean,
				default: false
			},
			offset: {
				type: Number,
				default: 0
			},
			limit: {
				type: Number,
				default: 25
			},
        },
        data: function() {
            return {
				showLoadingOverlay: false,
				metadatas: new Metadatas(),
				gridOptions: {
					headerHeight: this.$vgutils.isMobile()?0:40,
	                rowHeight: this.$vgutils.isMobile()?100:40
				},
				columnDefs: [
					{
						headerName: this.$t("column-date"),
						field: 'dateMouvement',
						colId: 'dateMouvement',
						cellRenderer: (params)=> new DatetimeCellRender({propsData: {datetime: params.data.dateMouvement}}).$mount().$el,
						width: 120
					},
					{
						headerName: this.$t("column-site"),
						field: 'bonDeSortie.ficheDemandeConsommable.site.libel_lieu',
						colId: 'bonDeSortie.ficheDemandeConsommable.site.libel_lieu',
						cellRenderer: (params)=>{
							if(params.data.flag=="bon-de-commande-livraison" && params.data.bonDeCommande) return params.data.bonDeCommande.siteDemandeur?params.data.bonDeCommande.siteDemandeur.libel_lieu:params.data.bonDeCommande.demandeur;
							else if(params.data.bonDeSortie && params.data.bonDeSortie.ficheDemandeConsommable && params.data.bonDeSortie.ficheDemandeConsommable.site) return params.data.bonDeSortie.ficheDemandeConsommable.site.libel_lieu;
							else if(params.data.flag=="consommation-fm" && params.data.operation.ficheSav_lieu_path) return params.data.operation.ficheSav_lieu_path.split("/")[1];
							else return "";
						},
						width: 160
					},
					{
						headerName: this.$t("column-service"),
						field: 'bonDeSortie.ficheDemandeConsommable.serviceDemandeur',
						colId: 'bonDeSortie.ficheDemandeConsommable.serviceDemandeur',
						cellRenderer: (params)=>{
							if(params.data.flag=="bon-de-commande-livraison" && params.data.bonDeCommande) return params.data.bonDeCommande.serviceDemandeur;
							else if(params.data.bonDeSortie && params.data.bonDeSortie.ficheDemandeConsommable) return params.data.bonDeSortie.ficheDemandeConsommable.serviceDemandeur;
							else if(params.data.flag=="consommation-fm" && params.data.operation.ficheSav_lieu_service) return params.data.operation.ficheSav_lieu_service;
							else return "";
						},
						width: 160
					},
					{
						headerName: this.$t("column-auteur"),
						field: 'user',
						colId: 'user',
						width: 160,
						cellRenderer: (params)=>params.data.flag=="bon-de-sortie" && params.data.bonDeSortie && params.data.bonDeSortie.ficheDemandeConsommable && params.data.bonDeSortie.ficheDemandeConsommable.nomDemandeur? params.data.bonDeSortie.ficheDemandeConsommable.nomDemandeur:params.data.user
					},
					{
						headerName: this.$t("column-consommable"),
						field: 'consommable.name',
						colId: 'consommable.name',
						cellRenderer: (params)=> new IconNameCellRender({propsData: {
							dataColumn: params.data.consommable.name,
							isMainColumn: true,
							route: { router: this.$router, name: '_consommable_id', params: {id: params.data.consommable_id } }
						}}).$mount().$el,
						width: 120
					},
					{
						headerName: this.$t("column-mouvement"),
						field: 'flag',
						colId: 'flag',
						width: 350,
						cellRenderer: (params)=> new FlagConsommableMouvementsCellRender({propsData: {params: params}})
							.$on("go-to-maintenance", this.goToMaintenance)
							.$on("go-to-bon-de-commande", this.goToBonDeCommande)
							.$on("click-link", this.displayFile)
							.$mount().$el
					},
					{
						headerName: this.$t("column-quantite-stock-avant"),
						field: 'quantiteInitialeOrigin',
						colId: 'quantiteInitialeOrigin',
						cellRenderer: (params)=> new QuantiteMouvementCellRender({propsData: {params: params, isQuantiteStockAvant: true}}).$mount().$el,
						width: 120
					},
					{
						headerName: this.$t("column-quantite-stock-apres"),
						field: 'quantiteInitialeDestination',
						colId: 'quantiteInitialeDestination',
						cellRenderer: (params)=> new QuantiteMouvementCellRender({propsData: {params: params, isQuantiteStockApres: true}}).$mount().$el,
						width: 120
					},
					{
						headerName: this.$t("column-quantite-entree"),
						field: 'quantite',
						colId: 'quantite',
						cellRenderer: (params)=> new QuantiteMouvementCellRender({propsData: {params: params, isQuantiteEntree: true}}).$mount().$el,
						width: 120
					},
					{
						headerName: this.$t("column-quantite-sortie"),
						field: 'quantite',
						colId: 'quantite',
						cellRenderer: (params)=> new QuantiteMouvementCellRender({propsData: {params: params, isQuantiteSortie: true}}).$mount().$el,
						width: 120
					},
					{
						headerName: this.$t("column-cout-unitaire"),
						field: 'cout',
						colId: 'cout',
						width: 120,
						cellRenderer: (params) => params.data.cout?new VgCostCellRender({propsData: {value: params.data.cout}}).$mount().$el:""
					},
					{
						headerName: this.$t("column-commentaire"),
						field: 'commentaire',
						colId: 'commentaire',
						cellRenderer: (params)=>params.data.flag=="bon-de-sortie"?params.data.bonDeSortie.description:params.data.commentaire
					},
					{
						headerName: this.$t("column-actions"),
						cellRenderer: (params) => {
							if(params.data.flag=="bon-de-sortie"){
								return new BonDeSortieActionsCellRender({propsData: {data: params.data}})
									.$on("update-commentaire", this.onUpdateCommentaire)
									.$on("open-signer", this.onOpenSigner)
									.$mount().$el;
							}else return "";
						},
						width: 200
					}
				],
				showUpdateBonDeSortie: false,
				showSignatureBonDeSortie: false,
				focusedBonDeSortie: null,
				selectedFile: null
            };
        },
	    i18n:    { "locale":navigator.language,
			"messages": {
				"fr": {
					"overlay-loading-text": "Chargement des consommations...",
					"overlay-no-rows-text": "Aucune consommation correspondant au filtrage",
					"column-mouvement": "Mouvement",
					"column-consommable": "Consommable",
					"column-date": "Date",
					"column-cout-unitaire": "Coût",
					"column-auteur": "Auteur / Demandeur",
					"column-site": "Site",
					"column-service": "Service",
					"column-documents": "Documents",
					"column-commentaire": "Commentaire",
					"column-actions": "Actions",
					"column-quantite-stock-avant": "Qté stock avant mouvement",
					"column-quantite-stock-apres": "Qté stock après mouvement",
					"column-quantite-entree": "Qté entrée",
					"column-quantite-sortie": "Qté sortie"
				},
				"en": {
					"overlay-loading-text": "Loading of consumptions...",
					"overlay-no-rows-text": "No consumption matching filtering",
					"column-mouvement": "Movement",
					"column-date": "Date",
					"column-cout-unitaire": "Cost",
					"column-auteur": "Author / Applicant",
					"column-site": "Site",
					"column-service": "Service",
					"column-documents": "Documents",
					"column-commentaire": "Comment",
					"column-actions": "Actions",
					"column-consommable": "Consummables",
					"column-quantite-stock-avant": "Qty stock before movement",
					"column-quantite-stock-apres": "Qty stock after movement",
					"column-quantite-entree": "Qty entry",
					"column-quantite-sortie": "Qty output"
				},
			}
		},
		watch: {
			filters: {
				handler: function(newfilters){
					this.metadatas.setLimit(this.offset, this.limit);
					this.fetch();
				},
				deep: true
			},
		},
		created: function(){
			if(this.$vgutils.isMobile()){
	            this.columnDefs.map((columnDef)=>{
	                columnDef.hide = true;
	            });
	            this.columnDefs.unshift({
	                headerName: "",
	                field:"",
	                cellClass: ["vg-cell-mobile-card"],
	                cellRenderer: (params) => new ConsommableMouvementsCellRender({propsData: {params: params, isAutomaticOpenLink : false}})
						.$on("go-to-maintenance", this.goToMaintenance)
						.$on("go-to-bon-de-commande", this.goToBonDeCommande)
						.$on("go-to-fdc", this.goToFicheDemandeConsommable)
						.$on("click-link", this.displayFile)
						.$mount().$el
	            });
	        }
		},
		mounted: function(){
			if(this.paginate) this.metadatas.setLimit(this.offset, this.limit);
			this.fetch();
		},
		methods: {
			displayFile(filelink){
				fetch(filelink).then((response)=>{
					response.blob().then((blob) => {
						this.selectedFile = new File([blob], "bon-entre_sortie", { type: blob.type });
					});

				})
			},
			fetch: function(){
				this.showLoadingOverlay = true;
				let filters = Object.assign({}, {}, this.filters);
				if(this.consommable) filters.consommable_id = {attr: "cm.consommable_id", value: this.consommable.id, action: "equals"};
				this.metadatas.setFilters(filters);
				this.ConsommablesMixins_getConsommations(this.metadatas).then((datas)=>{
					console.log("CONSOMMABLES MOUVEMENTS", this.mouvements);
					this.showLoadingOverlay = false;
				});
			},
			handlePaginationChange: function(pagination){
				this.metadatas.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
				this.fetch();
			},
			goToMaintenance: function(idMaintenance){
				this.$router.push({ name: "_maintenance", params: { id: idMaintenance } });
			},
			goToBonDeCommande: function(idBonDeCommande){
				this.$router.push({ name: "_bon_de_commande", params: { id: idBonDeCommande } });
			},
			goToFicheDemandeConsommable: function(idFiche){
				this.$router.push({ name: "_fichedemandeconsommables_id", params: {id: idFiche}});
			},
			onUpdateCommentaire: function(row){
				console.log("bonDeSortie", row.bonDeSortie);
				this.focusedBonDeSortie = Object.assign({}, {}, row.bonDeSortie);
				this.showUpdateBonDeSortie = true;
			},
			onOpenSigner: function(row){
				this.focusedBonDeSortie = Object.assign({}, {}, row.bonDeSortie);
				this.showSignatureBonDeSortie = true;
			}
		},
		computed: {
			...mapGetters({
				mouvements: 'ConsommableMouvementsStore/getCollection',
				consommable: "ConsommablesStore/getSelectedItem",
				counters: 'ConsommableMouvementsStore/getCounters'
            }),
			getTotalItems: function(){
				return this.counters?this.counters.filtered:this.mouvements.length;
			}
		}
    };

</script>
<style scoped>

</style>