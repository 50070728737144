<template>
    <div class="vg-bon-de-commande-items-table">
        <table class="bc-items-table">
            <thead>
                <tr>
                    <th style="width:10%">{{$t("header-bc-item-ref")}}</th>
                    <th style="width:40%;">{{$t("header-bc-item-designation")}}</th>
                    <th style="width:5%;min-width:50px;">{{$t("header-bc-item-quantite")}}</th>
                    <th v-if="showLivraison" style="width:5%;min-width:50px;">{{$t("header-bc-item-quantite-livree")}}</th>
                    <th style="width:5%;min-width:50px;">{{$t("header-bc-item-unite")}}</th>
                    <th style="width:5%;min-width:100px;">{{$t("header-bc-item-pu-ht")}}</th>
                    <th style="width:5%;min-width:100px;">{{$t("header-bc-item-total-ligne-ht")}}</th>
                    <th style="width:5%;min-width:50px;">{{$t("header-bc-item-taux-tva")}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in bonDeCommandeItems" :key="index">
                    <td style="width:10%"><vg-input v-model="item.ref" disabled></vg-input></td>
                    <td style="width:40%;">
                        <div style="display:flex;justify-content: space-between;align-items: flex-start;gap:5px;">
                            <vg-input v-model="item.description" disabled></vg-input>
                            <vg-button type="grey" :disabled="!item.consommable_id" 
                                @click="onClickConsommable(item)">
                                {{ $t("voir-consommable") }}
                            </vg-button>
                        </div>
                    </td>
                    <td style="width:5%;min-width:50px;"><vg-input v-model="item.quantite" :inputType="'number'" disabled></vg-input></td>
                    <td v-if="showLivraison" style="width:5%;min-width:50px;">
                        <vg-input v-if="!isLivraison" v-model="item.quantiteLivree" :key="'bc-item-show-quantite-livree-'+index"
                            :inputType="'number'"
                            important
                            :disabled="true">
                        </vg-input>
                        <vg-input v-else v-model="bonDeCommandeItemsCopy[index].quantiteLivree" :key="'bc-item-quantite-livree-'+index"
                            :inputType="'number'"
                            important
                            isRequiredValue
                            :disabled="!isLivraison"
                            @input="onChangeQuantiteLivree($event, item)">
                        </vg-input>
                    </td>
                    <td style="width:5%;min-width:50px;">{{item.unite}}</td>
                    <td style="width:5%;min-width:100px;"><vg-input v-model="item.puHT" :inputType="'financial'" disabled></vg-input></td>
                    <td style="width:5%;min-width:100px;"><vg-input v-model="item.totalHT" :inputType="'financial'" disabled></vg-input></td>
                    <td style="width:5%;min-width:50px;">
                        <vg-tva-viewer v-model="item.tva"/>
                    </td>
                </tr>
            </tbody>
        </table>
        <span v-if="hasErrors" style="color:red;font-size:10px;">{{ $t("quantite-error") }}</span>
    </div>
</template>
<script>
import BonDeCommandeItemsMixins from "src/mixins/BonDeCommandeItemsMixins";

import VgInput from "src/components/Vg/VgInput.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgTvaViewer from "src/components/Vg/Tva/VgTvaViewer.vue";

import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-bon-de-commande-items-table',
    components:{
        VgInput,
        VgButton,
        VgTvaViewer
    },
    mixins:[BonDeCommandeItemsMixins],
    props: {
        id:{
            type: String,
            default: "bon-de-commande-items-tableau"
        },
        idBonDeCommande: {
            type: String | Number,
            required: true
        },
        /**
        * affiche colonne qté livrée ou pas
        */
        showLivraison: {
            type: Boolean,
            default: true
        },
        /**
        * mode livraison avec input qté livrée !disabled
        */
        isLivraison: {
            type: Boolean,
            default: false
        },
        /**
        * liste des columns à hide (column colId)
        */
        hiddenColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns pined left (column colId)
        */
        pinnedColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns non showable (column colId)
        */
        notShowableColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * affiche le tableau en mode impression
        * default false
        */
        showPrint : {
            type: Boolean,
            default: false
        },
        /**
        * recherche
        */
        searchQuery: {
            type: String,
            default: ""
        },
        /**
        * filtres externes appliqué sur le tableau au fetch
        */
        filters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * filtres externes appliqué sur le tableau en local
        */
        localFilters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * titre rapport impression
        */
        reportTitle: {
            type: String,
            default: null
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: false
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "header-bc-item-ref": "Réf.",
            "header-bc-item-designation": "Désignation",
            "header-bc-item-quantite": "Qté",
            "header-bc-item-quantite-livree": "Qté livrée",
            "header-bc-item-unite": "Unité",
            "header-bc-item-pu-ht": "PuHT",
            "header-bc-item-total-ligne-ht": "Total ligne HT",
            "header-bc-item-taux-tva": "Taux TVA",
            "voir-consommable": "Voir consommable",
            "quantite-error": "La quantité livrée est requise."
        },
        "en": {
            "header-bc-item-ref": "Ref.",
            "header-bc-item-designation": "Designation",
            "header-bc-item-quantite": "Qty",
            "header-bc-item-quantite-livree": "Delivered Qty",
            "header-bc-item-unite": "Unit",
            "header-bc-item-pu-ht": "PuHT",
            "header-bc-item-total-ligne-ht": "Total line HT",
            "header-bc-item-taux-tva": "TVA rate",
            "voir-consommable": "Show consommable",
            "quantite-error": "The delivered quantity is required."
        }
    }
},
    data: function() {
        return {
            metadatas: new Metadatas(),
            searchQuery: "",
            agfilters: {
                bonDeCommande_id: {attr: "bonDeCommande_id", value: this.idBonDeCommande, action: "equals"}
            },
            bonDeCommandeItemsCopy: null,
            hasErrors: false
        };
    },
    watch: {
        searchQuery: {
            handler: function(query){

            }
        },
        filters: {
            handler: function(newfilters){
                this.fetch();
            },
            deep: true
        },
        idBonDeCommande: {
            handler: function(newId){
                this.agfilters.bonDeCommande_id.value = newId;
            }
        },
        isLivraison: {
            handler: function(value){
                if(!value && this.bonDeCommandeItemsCopy) this.bonDeCommandeItemsCopy = Array.from(this.bonDeCommandeItems, (item) => Object.assign({}, {}, item));
            }
        }
    },
    created:function(){
        if(this.paginate) this.metadatas.setLimit(this.offset, this.limit);
        this.agfilters = Object.assign({}, this.agfilters, this.filters);
        this.fetch();
    },
    computed: {
        ...mapGetters({
            bonDeCommandeItems: 'BonDeCommandeItemsStore/getCollection',
            counters: 'BonsDeCommandeStore/getCounters',
            depotDefault: "StocksStore/getDepotDefault"
        })
    },
    methods:{
        goToZoomConsommable: function(idConsommable){
            this.$router.push({ name: '_consommable_id', params: { id: idConsommable }});
        },
        onClickConsommable: function(item){
            if(item.consommable_id) this.goToZoomConsommable(item.consommable_id);
        },
        verfyErrors: function(){
            this.hasErrors = this.bonDeCommandeItemsCopy.some((item)=>{
                return item.quantiteLivree===null || item.quantiteLivree < 0
            });
        },
        onChangeQuantiteLivree: function(event, item){
            this.verfyErrors();
            this.$emit("livraison-change", {hasErrors: this.hasErrors, items: this.bonDeCommandeItemsCopy.map((item)=>{
                return {
                    bonDeCommandeItem_id: item.id,
                    quantiteLivree: item.quantiteLivree,
                    consommable_id: item.consommable_id?item.consommable_id:null,
                    depot_id: this.depotDefault && this.depotDefault.id?this.depotDefault.id:null
                };
            })});
        },
        fetch: function(){
            this.metadatas.setFilters(this.agfilters);
            this.BonDeCommandeItemsMixins_get(this.metadatas).then((datas)=>{
                console.log("GET BC items", datas);
                this.bonDeCommandeItemsCopy = Array.from(datas.bonDeCommandeItems, (item) => Object.assign({}, {}, item));// [...datas.bonDeCommandeItems];
            });
        },
    },
    destroyed: function(){

    }
};
</script>
<style lang="scss" scoped>
.bc-items-table{

    thead, tbody tr{
        display: table;
        width: 100%;
        table-layout: fixed;
    }
    thead{
        color: #9e9da9;
        font-family: "Open Sans";
        font-size: 0.85em;
        height:50px;
        width: calc( 100% - 1em );
    }
    tbody{
        display: block;
        /*max-height: 150px;*/
        width: 100%;
        overflow: auto;
        td{
            padding-right:5px;
        }
    }
}
.item-unite{
    color: #9e9da9;
}
</style>